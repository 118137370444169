import Vue from 'vue';
import VueRouter from 'vue-router';
import PublicLayout from '../views/layouts/PublicLayout';
import DashboardLayout from '../views/layouts/DashboardLayout';
import Login from '../views/pages/Login.vue';
import Dashboard from '../views/pages/Dashboard';
import NotFound from '../views/pages/errors/NotFound';
import Profile from "../views/pages/Settings";
import Notifications from "../views/pages/Notifications";
import ResetPasswordContainer from "../views/pages/reset-password/ResetPasswordContainer";
import ResetPasswordRequest from "../views/pages/reset-password/ResetPasswordRequest";
import ResetPasswordCheck from "../views/pages/reset-password/ResetPasswordCheck";
import Invite from "../views/pages/Invite";
import RealTime from "../views/pages/RealTime";
import Campaigns from "../views/pages/Campaigns";
import Report from "../views/pages/Report";
import Pagamenti from "../views/pages/Pagamenti";
import Development from "../views/pages/Development";
import DevelopmentPlatform from "../views/pages/DevelopmentPlatform";
import CampaignAccept from "@/views/pages/CampaignAccept.vue";
import CheckAccessToken from "@/views/pages/CheckAccessToken.vue";

Vue.use(VueRouter);
const guardAuthService = async (to, from, next) => {
    try {
        let token = localStorage.getItem('token');

        const tokenInUrl = to.query.token;
        const publisherInUrl = to.query.publisher;

        if (tokenInUrl) {
            next({
                name: 'token.check',
                query: {
                    token: tokenInUrl,
                    publisher: publisherInUrl,
                    redirect: to.fullPath,
                },
            });
        } else if (token && token !== '') {
            next();
        } else {
            next({
                name: 'login.page',
                query: {redirectFrom: to.fullPath},
            });
        }
    } catch (e) {
        next({
            name: 'login.page',
            query: {redirectFrom: to.fullPath},
        });
    }
};
const routes = [
    {
        path: '/notfound',
        name: 'notfound.page',
        component: NotFound,
    },
    {
        path: '/app/campaigns/:id/accept',
        name: 'campaign.accept.page',
        component: CampaignAccept,
    },
    {
        path: '/check-token',
        name: 'token.check',
        component: CheckAccessToken,
    },
    {
        path: '/app',
        component: DashboardLayout,
        beforeEnter(to, from, next) {
            guardAuthService(to, from, next);
        },
        children: [
            {
                path: '',
                name: 'dashboard.page',
                component: Dashboard,
            },
            {
                path: 'realtime',
                name: 'realtime.page',
                component: RealTime,
            },
            {
                path: 'profile',
                name: 'profile.page',
                component: Profile,
            },
            {
                path: 'notifications',
                name: 'notifications.page',
                component: Notifications,
            },
            {
                path: 'campaigns',
                name: 'campaigns.page',
                component: Campaigns,
                props: true,
            },
            {
                path: 'report',
                name: 'report.page',
                component: Report,
            },
            {
                path: 'payments',
                name: 'payments.page',
                component: Pagamenti,
            },
            {
                path: 'development',
                name: 'development.page',
                component: Development,
            },
            {
                path: 'development/:id',
                name: 'development.detail.page',
                component: DevelopmentPlatform,
            },
            {
                path: '*',
                redirect: '/notfound',
            },
        ],
    },
    {
        path: '/',
        component: PublicLayout,
        children: [
            {
                path: 'login',
                name: 'login.page',
                component: Login,
            },
            {
                path: 'password',
                component: ResetPasswordContainer,
                children: [
                    {
                        path: '',
                        name: 'password.request.reset.page',
                        component: ResetPasswordRequest,
                    },
                    {
                        path: 'reset',
                        name: 'password.reset.page',
                        component: ResetPasswordCheck,
                    },
                    {
                        path: '*',
                        redirect: '/password',
                    },

                ],
            },
            {
                path: 'invite',
                name: 'invite.page',
                component: Invite,
            },
            {
                path: '*',
                redirect: '/app',
            },
        ],
    },
    {
        path: '*',
        redirect: '/app',
    },
];
const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior() {
        return {x: 0, y: 0};
    },
});
export default router;

<template>
	<main id="dashboard" class="dashboard main-content">
		<div class="welcome">
			<div class="welcome--box">
				<h1>
					{{ $t("dashboard.title") }}
				</h1>
				<h2>
					{{ $t("dashboard.subtitle") }}
				</h2>
			</div>
			<select-time-period></select-time-period>
		</div>
		<div class="first-row row">
			<div class="panel col third">
				<h3 class="color_orange">
					Buongiorno
					<span class="name">{{ username }}</span>
				</h3>
				<p>
					<br />
					{{ $t("dashboard.welcome") }}
				</p>
			</div>
			<div class="col third grid-like">
				<div class="panel full">
					<h4>{{ $t("dashboard.installations.title") }}</h4>
					<div class="panel_number">
						<p v-if="!loading_counter">
							<span class="panel_number__number_big color_black">
								{{ (installations.ios + installations.android) | number }}
							</span>
							SDK
						</p>
						<loading-spinner
							:small="true"
							v-if="loading_counter"
						></loading-spinner>
					</div>
				</div>
				<div class="panel half">
					<h4>{{ $t("dashboard.installations.ios") }}</h4>
					<div class="panel_number">
						<p v-if="!loading_counter">
							<span class="panel_number__number color_orange">{{
								installations.ios | number
							}}</span>
							SDK
						</p>
						<loading-spinner
							:small="true"
							v-if="loading_counter"
						></loading-spinner>
					</div>
				</div>
				<div class="panel half">
					<h4>{{ $t("dashboard.installations.android") }}</h4>
					<div class="panel_number">
						<p v-if="!loading_counter">
							<span class="panel_number__number color_blue">{{
								installations.android | number
							}}</span>
							SDK
						</p>
						<loading-spinner
							:small="true"
							v-if="loading_counter"
						></loading-spinner>
					</div>
				</div>
			</div>
			<div class="panel col third">
				<h4>{{ $t("dashboard.installations.graph") }}</h4>
				<p v-if="!loading_counter">
					<installations-chart
						:width="300"
						:height="200"
						:ios="installations.ios"
						:android="installations.android"
					></installations-chart>
				</p>
				<loading-spinner v-if="loading_counter"></loading-spinner>
			</div>
		</div>
		<div class="row">
			<div class="panel col full panel__top_margin">
				<h4>{{ $t("dashboard.returning.title") }}</h4>
				<h5 style="font-size: 12px; margin-bottom: 10px">
					{{ $t("dashboard.returning.sub_title") }}
				</h5>
				<div class="row panel__top_margin" v-if="err_returning == ''">
					<div class="three-quarters-histogram" v-if="!loading_returning">
						<dashboard-histogram-chart
							:height="150"
							:data="returning.histogram_data"
						></dashboard-histogram-chart>
					</div>
					<div class="one-quarters-histogram" v-if="!loading_returning">
						<div v-if="!loading_returning && returning.stats.daily">
							<h4>{{ $t("common.today") }}</h4>
							<div class="panel_number panel_number__with_margin_bottom">
								<div class="row">
									<div class="col panel__right_margin histogram_label">
										<span class="panel_number__number_big color_black">{{
											returning.stats.daily.value | number
										}}</span>
									</div>

									<div class="col">
										<span class="full histogram_counter_label_small">{{
											$t("common.users")
										}}</span>
										<span
											class="perc"
											v-bind:class="{
												color_green: returning.stats.daily.increase > 0,
												color_red: returning.stats.daily.increase < 0,
											}"
											v-if="returning.stats.daily.increase"
										>
											<span v-if="returning.stats.daily.increase > 0">+</span
											>{{ returning.stats.daily.increase | percentage }}
										</span>
									</div>
								</div>
							</div>
						</div>
						<div v-if="!loading_returning && returning.stats.monthly">
							<h4>{{ $t("common.last_30_days") }}</h4>
							<div class="panel_number panel_number__with_margin_bottom">
								<div class="row">
									<div class="col panel__right_margin histogram_label">
										<span class="panel_number__number_big color_black">{{
											returning.stats.monthly.value | number
										}}</span>
									</div>

									<div class="col">
										<span class="full histogram_counter_label_small">{{
											$t("common.users")
										}}</span>
										<span
											class="perc"
											v-bind:class="{
												color_green: returning.stats.monthly.increase > 0,
												color_red: returning.stats.monthly.increase < 0,
											}"
											v-if="returning.stats.monthly.increase"
										>
											<span v-if="returning.stats.monthly.increase > 0">+</span
											>{{ returning.stats.monthly.increase | percentage }}
										</span>
									</div>
								</div>
							</div>
						</div>
						<div v-if="!loading_returning && returning.stats.range">
							<h4>{{ $t("dashboard.returning.selected") }}</h4>
							<div class="panel_number panel_number__with_margin_bottom">
								<div class="row">
									<div class="col panel__right_margin histogram_label">
										<span class="panel_number__number_big color_black">{{
											returning.stats.range.value | number
										}}</span>
									</div>

									<div class="col">
										<span class="full histogram_counter_label_small">{{
											$t("common.users")
										}}</span>
										<span
											class="perc"
											v-bind:class="{
												color_green: returning.stats.range.increase > 0,
												color_red: returning.stats.range.increase < 0,
											}"
											v-if="returning.stats.range.increase"
										>
											<span v-if="returning.stats.range.increase > 0">+</span
											>{{ returning.stats.range.increase | percentage }}
										</span>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="full" v-if="loading_returning">
						<loading-spinner></loading-spinner>
					</div>
				</div>
				<div class="row panel__top_margin" v-if="err_returning !== ''">
					<div class="full">
						{{ $t(`dashboard.returning.errors.${err_returning}`) }}
					</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="panel col full panel__top_margin">
				<h4>{{ $t("dashboard.growth.title") }}</h4>
				<h5 style="font-size: 12px; margin-bottom: 10px">
					{{ $t("dashboard.growth.sub_title") }}
				</h5>
				<div class="row panel__top_margin" v-if="err_growth == ''">
					<div class="three-quarters-histogram" v-if="!loading_growth">
						<dashboard-histogram-chart
							:height="150"
							:data="growth.histogram_data"
						></dashboard-histogram-chart>
					</div>
					<div class="one-quarters-histogram">
						<div v-if="!loading_growth && growth.stats.daily">
							<h4>{{ $t("common.today") }}</h4>
							<div class="panel_number panel_number__with_margin_bottom">
								<div class="row">
									<div class="col panel__right_margin histogram_label">
										<span class="panel_number__number_big color_black">{{
											growth.stats.daily.value | number
										}}</span>
									</div>

									<div class="col">
										<span class="full histogram_counter_label_small">{{
											$t("common.users")
										}}</span>
										<span
											class="perc"
											v-bind:class="{
												color_green: growth.stats.daily.increase > 0,
												color_red: growth.stats.daily.increase < 0,
											}"
											v-if="growth.stats.daily.increase"
										>
											<span v-if="growth.stats.daily.increase > 0">+</span
											>{{ growth.stats.daily.increase | percentage }}
										</span>
									</div>
								</div>
							</div>
						</div>

						<div v-if="!loading_growth && growth.stats.monthly">
							<h4>{{ $t("common.last_30_days") }}</h4>
							<div class="panel_number panel_number__with_margin_bottom">
								<div class="row">
									<div class="col panel__right_margin histogram_label">
										<span class="panel_number__number_big color_black">{{
											growth.stats.monthly.value | number
										}}</span>
									</div>

									<div class="col">
										<span class="full histogram_counter_label_small">{{
											$t("common.users")
										}}</span>
										<span
											class="perc"
											v-bind:class="{
												color_green: growth.stats.monthly.increase > 0,
												color_red: growth.stats.monthly.increase < 0,
											}"
											v-if="growth.stats.monthly.increase"
										>
											<span v-if="growth.stats.monthly.increase > 0">+</span
											>{{ growth.stats.monthly.increase | percentage }}
										</span>
									</div>
								</div>
							</div>
						</div>

						<div v-if="!loading_growth && growth.stats.range">
							<h4>{{ $t("dashboard.growth.selected") }}</h4>
							<div class="panel_number panel_number__with_margin_bottom">
								<div class="row">
									<div class="col panel__right_margin histogram_label">
										<span class="panel_number__number_big color_black">{{
											growth.stats.range.value | number
										}}</span>
									</div>

									<div class="col">
										<span class="full histogram_counter_label_small">{{
											$t("common.users")
										}}</span>
										<span
											class="perc"
											v-bind:class="{
												color_green: growth.stats.range.increase > 0,
												color_red: growth.stats.range.increase < 0,
											}"
											v-if="growth.stats.range.increase"
										>
											<span v-if="growth.stats.range.increase > 0">+</span
											>{{ growth.stats.range.increase | percentage }}
										</span>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="full" v-if="loading_growth">
						<loading-spinner></loading-spinner>
					</div>
				</div>
				<div class="row panel__top_margin" v-if="err_growth !== ''">
					<div class="full">
						{{ $t(`dashboard.returning.errors.${err_growth}`) }}
					</div>
				</div>
			</div>
		</div>
	</main>
</template>

<script>
import SelectTimePeriod from "../components/common/filters/SelectTimePeriod";
import InstallationsChart from "../components/common/graphs/InstallationsChart";
import LoadingSpinner from "../components/common/Loading";
import DashboardHistogramChart from "../components/common/graphs/DashboardHistogramChart";

export default {
	name: "DashboardPage",
	components: {
		DashboardHistogramChart,
		LoadingSpinner,
		InstallationsChart,
		SelectTimePeriod,
	},
	data() {
		return {
			prevRoute: null,
			loading_counter: false,
			loading_growth: false,
			err_growth: "",
			loading_returning: false,
			err_returning: "",
			username: "",
			installations: {
				ios: 0,
				android: 0,
			},
			growth: {
				stats: {},
				histogram_data: [],
			},
			returning: {
				stats: {},
				histogram_data: [],
			},
		};
	},
	methods: {
		updateData() {
			this.loading_counter = true;
			this.loading_growth = true;
			this.loading_returning = true;
			this.err_growth = "";
			this.err_returning = "";

			// update profile data
			this.username = `${localStorage.getItem("user_first_name") || ""} ${
				localStorage.getItem("user_last_name") || ""
			}`;

			// definizione delle app
			let app_ids = localStorage.getItem("selected_apps");
			try {
				app_ids = JSON.parse(app_ids);
			} catch (e) {
				app_ids = [];
			}

			let from = localStorage.getItem("selected_time_period_from");
			let to = localStorage.getItem("selected_time_period_to");

			// update counter
			this.service.statistics
				.installations(app_ids, from, to)
				.then((result) => {
					this.installations.android = 0;
					this.installations.ios = 0;
					result.map((r) => {
						if (r && r.term) {
							if (r.term == "android") {
								this.installations.android = +r.count;
							}
							if (r.term == "ios") {
								this.installations.ios = +r.count;
							}
						}
					});
				})
				.finally(() => {
					this.loading_counter = false;
				});

			// growth
			this.service.statistics.growth
				.histogram(app_ids, from, to)
				.then((histogram_data) => {
					this.service.statistics.growth
						.stats(app_ids, from, to)
						.then((stats_data) => {
							this.growth.histogram_data = histogram_data;
							this.growth.stats = stats_data;
						})
						.finally(() => {
							this.loading_growth = false;
						});
				})
				.catch((err) => {
					this.loading_returning = false;
					this.err_growth = err.message;
				})
				.finally(() => {
					this.loading_growth = false;
				});

			// returning
			this.service.statistics.returning
				.histogram(app_ids, from, to)
				.then((histogram_data) => {
					this.service.statistics.returning
						.stats(app_ids, from, to)
						.then((stats_data) => {
							this.returning.histogram_data = histogram_data;
							this.returning.stats = stats_data;
						})
						.finally(() => {
							this.loading_returning = false;
						});
				})
				.catch((err) => {
					this.loading_returning = false;
					this.err_returning = err.message;
				})
				.finally(() => {
					this.loading_returning = false;
				});
		},
		init() {
			this.$root.$on("filters_updated", () => {
				if (this.$router.currentRoute.name == "dashboard.page") {
					console.log("filters_updated");
					this.updateData();
				}
			});
		},
	},
	mounted() {
		this.init();
		this.updateData();
	},
};
</script>


<style lang="scss">
@import "~@/assets/scss/pages/_dashboard";

.color_orange {
	// display: block;
	// width: 100%;

	.name {
		display: block;
		width: 100%;
		font-weight: 300;
		font-size: 1.3em;
		margin-top: calc(var(--gutter) / 2);
	}
}

.histogram_label {
	line-height: 50px;

	span {
		font-weight: 300;
	}
}

.histogram_counter_label_small {
	font-size: 12px;
}

.perc {
	font-size: 15px;
	margin-top: -7px;
}
</style>

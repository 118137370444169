<script>

export default {
  name: 'CampaignAccept',
  mounted() {
    const token = this.$route.query.token;

    const campaignId = this.$route.params.id;
    const publisherId = this.$route.query.publisher;
    const apiKeys = this.$route.query.apps.split(',');

    this.service.tokenLogin(token, publisherId)
        .then(() => {
          this.service.campaigns.update(apiKeys, campaignId, this.service.campaigns.status.ACCEPTED)
              .then(() => {
                this.$router.push({
                  name: 'campaigns.page',
                  params: {
                    openOptinAcceptedModal: true,
                    emailAcceptedCampaignId: campaignId,
                    emailApplicationIds: apiKeys,
                  },
                });
              })
              .catch(error => {
                console.log(error);
                this.$router.push({name: 'dashboard.page'});
              });
        })
        .catch(error => {
          console.log(error);
          this.$router.push({name: 'login.page'});
        });
  },
};
</script>

<template>
  <main/>
</template>
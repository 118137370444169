<template>
    <main id="reset-password" class="login">
        <request-reset-password-form></request-reset-password-form>
    </main>
</template>

<script>
    import RequestResetPasswordForm from "../../components/reset-password/RequestResetPasswordForm";
    export default {
        name: 'ResetPasswordRequestPage',
        components: {RequestResetPasswordForm}
    }
</script>

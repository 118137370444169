<template>
	<div>
		<template v-if="!loading">
			<p>
				<strong>{{$t('development.test.status')}} </strong>

				<template v-if="status">
					: <span class="test_success">{{$t('development.test.success')}}</span>
				</template>
				<template v-if="!status && counter == maxCalls">
					: <span class="test_error">{{$t('development.test.error')}}</span>
				</template>

				<button class="btn_small panel__left_margin" v-if="!isStart && status == false" @click="isStart = true">
					{{$t('development.test.start')}}
				</button>
			</p>
		</template>
		<template v-else>
			<p class="test_wait">
				{{$t('development.test.wait')}}
			</p>
		</template>
	</div>
</template>
<script>

    export default {
        name: 'TestApp',
        data() {
            return {
                execute: false,
                isStart: false,
                counter: 0,
                maxCalls: 10,
                status: '',
                loading: false
            }
        },
        props: {
            appId: {
                type: String,
                required: true
            },
            platform: {
                type: Object,
                required: true
            },
            start: {
                type: Boolean,
                required: true
            }
        },
        mounted() {
            this.isStart = this.start;
        },
        watch: {
            'start': {
                handler(val) {
                    this.isStart = val;
                },
                deep: false
            },
            'isStart': {
                handler(val) {
                    if (val) {
                        this.counter = 0;
                        this.verify();
                    }
                },
                deep: false
            },
            'platform': {
                handler() {
                    this.isStart = false;
                    this.loading = false;
                    this.counter = 0;
                    this.status = false;
                },
                deep: true
            }
        },
        methods: {
            verify() {
                this.loading = true;
                this.status = false;
                this.counter = this.counter + 1;
                this.service.applications.platforms.verify(this.appId, this.platform.platform, this.platform.identifier)
                    .then((result) => {
                        if (result) {
							this.loading = false;
							this.isStart = false;
							this.counter = false;
							this.status = result;
                        } else {
                            if (this.isStart && this.counter < this.maxCalls) {
                                // eseguo ogni 500ms
                                new Promise((resolve) => setTimeout(resolve, 1000))
                                    .then(() => {
                                        this.verify();
                                    });
                            } else {
                                this.loading = false;
                                this.isStart = false;
                            }
                        }

                    })
                    .catch(() => {
                        this.loading = false;
                        this.isStart = false;
                    })
            }
        }
    }
</script>

<style lang="scss" scoped>
	.test_success {
		color: $green
	}
	.test_error {
		color: $red;
	}
	.test_wait {
		color: $blue;
	}
</style>

<template>
	<div class="select-campaigns">
		<span class="trigger pointer" @click="openSelection = !openSelection" :class="{ 'open': openSelection}">
			<span class="trigger--icon">
				<svg viewBox="0 0 40 40">
					<use xlink:href="#icons--reports" width="100%" height="100%"></use>
				</svg>
			</span>
			<span class="trigger--label">
				<span v-if="selectedCampaigns.length <= 0">{{$t('filters.allcampaigns')}}</span>
				<span v-if="selectedCampaigns.length > 0">{{ selectedCampaigns.length }} {{$t('filters.campaigns')}}</span>
				<span class="arrow"></span>
			</span>
		</span>

		<form class="form" :class="{ 'open': openSelection }" autocomplete="off" @submit.prevent="">
			<label @click="selectAll" for="all" class="all filter pointer" :class="{'current': selectedCampaigns.length <= 0 }">
				<span class="label">
					{{$t('filters.select_campaign.all_campaigns')}}
				</span>
				<span class="checkbox-fake"></span>
				<input type="checkbox" id="all" value="all" v-model="all" @change="selectAll"/>
			</label>
			<div class="campaigns-list">
				<label :for="`campaign_${campaign.id}`" v-for="campaign in campaigns" v-bind:key="campaign.id" class="filter pointer"
					   :class="{ 'current': selectedCampaigns.includes(campaign.id) }">
					<span class="label">
						{{ campaign.name }}
					</span>
					<span class="checkbox-fake"></span>
					<input type="checkbox" :id="`campaign_${campaign.id}`" :value="campaign.id" v-model="selectedCampaigns"
						   @change="updateSelectedCampaign()"/>
				</label>
			</div>
		</form>
	</div>
</template>

<script>
    export default {
        name: "SelectCampaigns",
        data() {
            return {
                campaigns: [],
                campaignsIds: [],
                all: false,
                loading: false,
                openSelection: false,
                selectedCampaigns: []
            }
        },
        mounted() {
            this.init();
            this.updateData();
        },
        methods: {
            selectAll() {
                this.all = true;
                this.selectedCampaigns = [];
                let tmpCampaigns = [];
                let tmpNames = [];
                this.campaigns.map(a => {
                    tmpCampaigns.push(a.id);
                    tmpNames.push(a.name);
                })
                localStorage.setItem('selected_campaigns', JSON.stringify(tmpCampaigns));
                localStorage.setItem('selected_campaigns_name', JSON.stringify(tmpNames));
                this.$root.$emit('filters_updated');
            },
			updateData() {
                // prendo le date e le applicazioni
                let app_ids = localStorage.getItem('selected_apps');
                try {
                    app_ids = JSON.parse(app_ids);
                } catch(e) {
                    app_ids = [];
                }

                let from = localStorage.getItem('selected_time_period_from');
                let to = localStorage.getItem('selected_time_period_to');

                this.loading = true;
                // prendo i dati dalla localstorage
                let lsData = localStorage.getItem('selected_campaigns');
                try {
                    lsData = JSON.parse(lsData);
                    if(lsData == null) {
                        lsData = [];
                    }
                } catch (e) {
                    lsData = [];
                }
                this.service.reports.campaigns_list(app_ids, from, to)
                    .then(campaigns => {
                        campaigns.sort((a,b) => {
                            if (a.name > b.name) return 1;
                            if (b.name > a.name) return -1;
                            return 0;
                        })
						if(campaigns && campaigns.length) {
                            this.campaigns = [];
                            let tmpIds = [];
                            campaigns.map(c => {
                                if(!tmpIds.includes(c.id)) {
                                    tmpIds.push(c.id);
                                    this.campaigns.push(c);
								}
							})
						}

                        if (campaigns && campaigns.length == lsData.length) {
                            this.selectAll();
                        } else {
                            lsData.map(l => {
                                this.selectedCampaigns.push(l);
                            })
                        }
                    })
                    .catch()
                    .finally(() => this.loading = false);
			},
            init() {
                this.$root.$on('update_campaign_filter', () => {
                    if(this.$router.currentRoute.name == 'report.page') {
                        this.updateData();
                    }
                });
            },
            updateSelectedCampaign() {
                this.all = false;
                if (this.selectedCampaigns.length == 0) {
                    this.selectAll();
                } else {
                    let lsData = localStorage.getItem('selected_campaigns');
                    try {
                        lsData = JSON.parse(lsData);
                    } catch (e) {
                        lsData = [];
                    }
                    if (this.selectedCampaigns.length != lsData.length) {
                        let tmpNamesApps = [];
                        this.campaigns.map(a => {
                            if(this.selectedCampaigns.includes(a.id)) {
                                tmpNamesApps.push(a.name);
                            }
                        })
                        localStorage.setItem('selected_campaigns_name', JSON.stringify(tmpNamesApps));
                        localStorage.setItem('selected_campaigns', JSON.stringify(this.selectedCampaigns));
                        this.$root.$emit('filters_updated');
                    }
                }
            }
        }
    }
</script>

<style lang="scss">
	@import '~@/assets/scss/components/_selected-campaigns';
</style>

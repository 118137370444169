<template>
	<div class="row">
		<div class="col full" v-if="platforms && platforms.length">
			<span v-for="(p, i) in platforms" :key="i" class="icon" style="margin-right: 0.5em">
				<svg viewBox="0 0 50 50">
					<use :href="`#icons--${p}`" width="100%" height="100%"></use>
                </svg>
			</span>
		</div>
	</div>
</template>
<script>
    export default {
        name: 'AppPlatformIcons',
        props: {
            app: {
                type: Object,
				required: true
			}
		},
		data() {
            return {
                platforms: []
			}
		},
		mounted() {
            this.init();
        },
		methods: {
            init() {
                let platforms = [];
                if(this.app && this.app.platforms && this.app.platforms.length) {
                    this.app.platforms.map(a => {
                        platforms.push(a.platform);
					});
                    let p = platforms.filter(function(item, pos) {
                        return platforms.indexOf(item) == pos;
                    })
					this.platforms = p;
				}
			}
		}
    }
</script>

<style lang="scss" scoped>
	.icon {
		width: 20px;
		height: 20px;
		margin-top: 40px;
	}

</style>

<script>
    import {Pie} from 'vue-chartjs'
    export default {
        name: "InstallationsChart",
        extends: Pie,
        props: {
            ios: {
                required: true
			},
			android: {
                required: true
			}
		},
		data() {
            return {}
		},
        mounted() {
			this.updateData();
        },
		methods: {
            updateData() {
                let data = [this.ios, this.android];
                this.renderChart({
                    datasets: [{
                        data: data,
                        backgroundColor: ['#ed7d31', '#0098a1']
					}],
                    labels: ["iOS", "Android"]
				})
			}
		},
        watch: {
			ios: {
			    handler() {
			        this.updateData();
				}
			},
			android: {
                handler() {
                    this.updateData();
                }
            }
        }
    }
</script>
<style>
</style>
<template>
	<main id="dashboard" class="dashboard main-content">
		<div class="welcome">
			<div class="welcome--box">
				<h1>
					{{ $t('realtime.title') }}
				</h1>
				<h2>
					{{ $t('realtime.subtitle') }}
				</h2>
			</div>
		</div>
		<div class="first-row row">
			<div class="panel col full">
				<real-time-graph></real-time-graph>
			</div>
		</div>
	</main>
</template>

<script>
    import RealTimeGraph from "../components/common/graphs/RealTimeGraph";
    export default {
        name: 'RealTimePage',
        components: {RealTimeGraph},
        data() {
            return {
            }
        },
        methods: {
            updateData() {

			}
        },
        mounted() {
            this.updateData();
        }
    }
</script>

<style lang="scss">
	@import '~@/assets/scss/pages/_dashboard';
	@import '~@/assets/scss/pages/_realtime';
</style>

<template>
	<div class="select-apps">
		<span
			class="trigger"
			@click="openSelection = !openSelection"
			:class="{ open: openSelection }"
		>
			<span v-if="selectedApps.length <= 0">{{ $t("filters.allapps") }}</span>
			<span v-if="selectedApps.length > 0"
				>{{ selectedApps.length }} {{ $t("filters.apps") }}</span
			>
		</span>
		<form
			class="form"
			:class="{ open: openSelection }"
			autocomplete="off"
			@submit.prevent=""
		>
			<label
				for="all"
				class="all filter pointer"
				:class="{ current: selectedApps.length <= 0 }"
			>
				<span class="label">
					{{ $t("filters.select_apps.all_applications") }}
				</span>
				<span class="checkbox-fake"></span>
				<input
					type="checkbox"
					id="all"
					value="all"
					v-model="all"
					@change="selectAll"
				/>
			</label>
			<div class="app-list">
				<label
					v-if="selectedApps && selectedApps.length"
					class="app-list-title"
				>
					{{ $t("filters.select_apps.app_selected") }}
				</label>
				<label
					:for="`appid_${app}`"
					v-for="(app, index) in selectedApps"
					v-bind:key="`${app.id}_${index}`"
					class="filter pointer current"
				>
					<span class="label">
						{{ getAppById(app).name || null }}
					</span>
					<span class="checkbox-fake"></span>
					<input
						type="checkbox"
						:id="`appid_${app}`"
						:value="app"
						v-model="selectedApps"
						@change="updateSelectedApp()"
					/>
				</label>
				<label v-if="apps && selectedApps.length" class="app-list-title">
					{{ $t("filters.select_apps.app") }}
				</label>

				<label
					:for="`appid_${app.id}`"
					v-for="(app, index) in filteredApps()"
					v-bind:key="`${app.id}_${index}`"
					class="filter pointer"
					:class="{ current: selectedApps.includes(app.id) }"
				>
					<span class="label">
						{{ app.name }}
					</span>
					<span class="checkbox-fake"></span>
					<input
						type="checkbox"
						:id="`appid_${app.id}`"
						:value="app.id"
						v-model="selectedApps"
						@change="updateSelectedApp()"
					/>
				</label>
			</div>
		</form>
	</div>
</template>

<script>
export default {
	name: "SelectApps",
	data() {
		return {
			apps: [],
			appsIds: [],
			all: false,
			loading: false,
			openSelection: false,
			selectedApps: [],
		};
	},
	mounted() {
		this.init();
	},
	methods: {
		selectAll() {
			this.all = true;
			this.selectedApps = [];
			let tmpApps = [];
			let tmpNamesApps = [];
			this.apps.map((a) => {
				tmpApps.push(a.id);
				tmpNamesApps.push(a.name);
			});
			localStorage.setItem("selected_apps", JSON.stringify(tmpApps));
			localStorage.setItem("selected_apps_name", JSON.stringify(tmpNamesApps));
			this.$root.$emit("filters_updated");
			this.$root.$emit("update_campaign_filter");
		},
		init() {
			this.loading = true;
			// prendo i dati dalla localstorage
			let lsData = localStorage.getItem("selected_apps");
			try {
				lsData = JSON.parse(lsData);
			} catch (e) {
				lsData = [];
			}
			this.service.applications
				.list()
				.then((apps) => {
					// metto le app in ordine alfabetico
					apps.sort((a, b) => {
						if (a.name > b.name) return 1;
						if (b.name > a.name) return -1;
						return 0;
					});
					this.apps = apps;
					if (apps.length == lsData.length) {
						// this.selectAll();
					} else {
						lsData.map((l) => {
							this.selectedApps.push(l);
						});
					}
				})
				.catch()
				.finally(() => (this.loading = false));
		},
		updateSelectedApp() {
			this.all = false;
			if (this.selectedApps.length == 0) {
				this.selectAll();
			} else {
				let lsData = localStorage.getItem("selected_apps");
				try {
					lsData = JSON.parse(lsData);
				} catch (e) {
					lsData = [];
				}
				if (this.selectedApps.length != lsData.length) {
					localStorage.setItem(
						"selected_apps",
						JSON.stringify(this.selectedApps)
					);
					let tmpNamesApps = [];
					this.apps.map((a) => {
						if (this.selectedApps.includes(a.id)) {
							tmpNamesApps.push(a.name);
						}
					});
					localStorage.setItem(
						"selected_apps_name",
						JSON.stringify(tmpNamesApps)
					);
					this.$root.$emit("filters_updated");
					this.$root.$emit("update_campaign_filter");
				}
			}
		},
		filteredApps() {
			let apps = this.apps.filter((a) => {
				return !this.selectedApps.includes(a.id);
			});
			return apps;
		},
		getAppById(appId) {
			let af = this.apps.filter((a) => {
				return a.id == appId;
			});
			if (af && af.length) {
				return af[0];
			} else {
				return null;
			}
		},
	},
};
</script>

<style lang="scss">
@import "~@/assets/scss/components/_selected-apps";

.app-list-title {
	font-size: 0.7em;
	font-weight: 600;
	padding-left: 0.9em;
}
</style>

<template>
    <main id="settings" class="settings main-content">
        <h1> {{ $t('settings.title') }} </h1>
        <h2> {{ $t('settings.subtitle') }} </h2>
        <div class="first-row row" v-if="role">
            <div class="col half" style="    height: 100%;  ">
                <div class="panel full panel__right_margin panel__bottom_margin">
                    <profile-form></profile-form>
                </div>
                <div class="panel full panel__right_margin">
                    <notification-form></notification-form>
                </div>
            </div>
            <div class="col half" style="    height: 100%;  ">
                <div class="panel panel__bottom_margin" v-if="role == 'OWNER'">
                    <h3>{{$t('settings.update_publisher.title')}}</h3>
                    <publisher-info-form @success="publisher_updated = true"></publisher-info-form>
                    <alert-modal :title="$t('settings.update_publisher.title')" :description="$t('settings.update_publisher.description')" v-if="publisher_updated" @close="publisher_updated = false"></alert-modal>
                </div>
                <div class="panel width100" v-if="role == 'OWNER'">
                    <h3>{{$t('settings.add_new_account')}}</h3>
                    <send-invite @success="$root.$emit('update-list-invites')"></send-invite>
                    <list-invites></list-invites>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
import SendInvite from "../components/SendInvite";
import ListInvites from "../components/ListInvites";
import PublisherInfoForm from "../components/PublisherInfoForm";
import AlertModal from "../components/common/modals/AlertModal";
import ProfileForm from "../components/ProfileForm";
import NotificationForm from "../components/NotificationForm";
export default {
    name: 'SettingsPage',
    components: {NotificationForm, ProfileForm, AlertModal, PublisherInfoForm, ListInvites, SendInvite},
    mounted() {
        this.init();
    },
    data() {
        return {
            role: null,
            publisher_updated: false
        }
    },
    methods: {
        init() {
            this.service.refresh_token()
            .then((result) => {
                this.role = result.role;
            });
        }
    }
}
</script>
<style lang="scss">
@import '~@/assets/scss/pages/_settings';
</style>

<template>
    <form class="form" autocomplete="off" @submit.prevent="submitForm">
        <div v-if="error" class="error error-panel panel">
            {{ error }}
        </div>
        <div v-if="sended" class="panel">
            {{ $t('form.invites.sended') }}{{ sended }}
        </div>
        <div class="fieldset icon">
            <input type="text" name="first_name" :placeholder="$t('form.invites.first_name')" class="input" autocomplete="off" v-model.trim="$v.form.first_name.$model"/>
        </div>
        <div class="error">
                <span v-if="submitted && !$v.form.first_name.required">
                    {{ $t('form.invites.errors.first_name_required') }}
                </span>
        </div>
        <div class="fieldset icon">
            <input type="text" name="last_name" :placeholder="$t('form.invites.last_name')" class="input" autocomplete="off" v-model.trim="$v.form.last_name.$model"/>
        </div>
        <div class="error">
                <span v-if="submitted && !$v.form.last_name.required">
                    {{ $t('form.invites.errors.last_name_required') }}
                </span>
        </div>
        <div class="fieldset icon">
            <input type="text" name="email" :placeholder="$t('form.invites.email')" class="input" autocomplete="off" v-model.trim="$v.form.email.$model"/>
        </div>
        <div class="error">
                <span v-if="submitted && !$v.form.email.required">
                    {{ $t('form.invites.errors.email_required') }}
                </span> <span v-if="submitted && !$v.form.email.email">
                    {{ $t('form.invites.errors.email') }}
                </span>
        </div>
        <div class="fieldset icon">
            <select class="input select" name="role" v-model.trim="$v.form.role.$model">
                <option v-for="index in 3" :value="index" v-bind:key="index">{{ $t(`roles_names[${index}]`) }}</option>
            </select>
        </div>
        <div class="error">
                <span v-if="submitted && !$v.form.role.required">
                    {{ $t('form.invites.errors.role_required') }}
                </span>
        </div>
        <button type="submit" :disabled="loading" class="btn big full">
            <span v-if="loading">{{ $t('common.loading') }}</span> <span v-if="!loading">{{ $t('common.add') }}</span>
        </button>
    </form>
</template>
<script>
    import {required, email} from 'vuelidate/lib/validators'

    export default {
        name: 'SendInvite',
        data() {
            return {
                submitted: false,
                error: '',
                sended: '',
                loading: false,
                button_text: this.$t('common.save'),
                form: {
                    first_name: '',
                    last_name: '',
                    email: '',
                    role: 1,
                    locale: this.$i18n.locale
                }
            };
        },
        validations: {
            form: {
                first_name: {
                    required
                },
                last_name: {
                    required
                },
                email: {
                    required,
                    email
                },
                role: {
                    required
                },
                locale: {
                    required
                }
            }
        },
        methods: {
            submitForm() {
                this.$v.$touch();
                this.submitted = true;
                this.error = '';
                this.sended = '';
                this.loading = true;
                if (!this.$v.$invalid) {
                    // api
                    this.service.invites.send(this.form)
                        .then(() => {
                            this.$emit('success');
                            this.sended = `${this.form.first_name} ${this.form.last_name}`;
                            this.submitted = false;
                            this.form = {
                                first_name: '',
                                last_name: '',
                                email: '',
                                role: 1,
                                locale: this.$i18n.locale
                            }
                        })
                        .catch((err) => {
                            this.error = this.$t(`form.invites.errors.${err.message}`);
                        })
                        .finally(() => {
                            this.loading = false
                        })
                } else {
                    this.loading = false;
                }
            }
        }
    }
</script>
<style lang="scss"></style>

<template>
	<main id="dashboard" class="dashboard main-content">
		<div class="welcome">
			<div class="welcome--box">
				<h1>
					{{ $t('notifications.title') }}
				</h1>
				<h2>
					{{ $t('notifications.subtitle') }}
				</h2>
			</div>
			<select-time-period></select-time-period>
		</div>
		<div class="first-row  row">
			<div class="panel col full panel__top_margin">
				<h4>{{ $t('notifications.events.title') }}</h4>
				<div class="row panel__top_margin" v-if="err_events == ''">
					<div class="three-quarters-histogram" v-if="!loading_events">
						<events-histogram-chart
								:height="150"
								:inareas="events.histogram_data.inareas"
								:inplaces="events.histogram_data.inplaces"
								:nearplaces="events.histogram_data.nearplaces"></events-histogram-chart>
					</div>
					<div class="one-quarters-histogram" style="padding-left: 1em" v-if="!loading_events">
						<div v-if="!loading_events">
							<div class="panel_number panel_number__with_margin_bottom">
								<div class="row">
									<div class="col panel__right_margin histogram_label">
										<span class="panel_number__number_small color_black">{{events.stats.nearplaces | number}}</span>
									</div>

									<div class="col">
										<span class="full histogram_counter_label_small">{{$t('notifications.events.title')}}</span>
										<span class="text__inareas">{{ $t('notifications.events.inareas') }}</span>
									</div>
								</div>
							</div>
						</div>
						<div v-if="!loading_notifications">
							<div class="panel_number panel_number__with_margin_bottom">
								<div class="row">
									<div class="col panel__right_margin histogram_label">
										<span class="panel_number__number_small color_black">{{events.stats.inplaces | number}}</span>
									</div>

									<div class="col">
										<span class="full histogram_counter_label_small">{{$t('notifications.events.title')}}</span>
										<span class="text__inplaces">{{ $t('notifications.events.inplaces') }}</span>
									</div>
								</div>
							</div>
						</div>
						<div v-if="!loading_notifications">
								<div class="panel_number panel_number__with_margin_bottom">
								<div class="row">
									<div class="col panel__right_margin histogram_label">
										<span class="panel_number__number_small color_black">{{events.stats.inareas | number}}</span>
									</div>

									<div class="col">
										<span class="full histogram_counter_label_small">{{$t('notifications.events.title')}}</span>
										<span class="text__nearplaces">{{ $t('notifications.events.nearplaces') }}</span>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="full" v-if="loading_notifications">
						<loading-spinner></loading-spinner>
					</div>
				</div>
				<div class="row panel__top_margin" v-if="err_notifications !== ''">
					<div class="full">
						{{ $t(`notifications.returning.errors.${err_notifications}`) }}
					</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="panel col full panel__top_margin">
				<h4>{{ $t('notifications.notifications.title') }}</h4>
				<div class="row panel__top_margin" v-if="err_notifications == ''">
					<div class="three-quarters-histogram" v-if="!loading_notifications">
						<notifications-histogram-chart :height="150"
													   :coverage="coverage"
													   :data="notifications.histogram_data"></notifications-histogram-chart>
					</div>
					<div class="one-quarters-histogram"  style="padding-left: 1em" v-if="!loading_notifications">
						<div v-if="!loading_notifications && notifications.stats.daily">
							<h4>{{ $t('common.today') }}</h4>
							<div class="panel_number panel_number__with_margin_bottom">
								<div class="row">
									<div class="col panel__right_margin histogram_label">
										<span class="panel_number__number_big color_black">{{notifications.stats.daily.value | number}}</span>
									</div>

									<div class="col">
										<span class="full histogram_counter_label_small">{{$t('common.users')}}</span>
										<span class="perc"
											  v-bind:class="{ 'color_green': notifications.stats.daily.increase > 0, 'color_red': notifications.stats.daily.increase < 0}"
											  v-if="notifications.stats.daily.increase">
												<span v-if="notifications.stats.daily.increase > 0">+</span>{{notifications.stats.daily.increase | percentage}}
											</span>
									</div>
								</div>
							</div>
						</div>
						<div v-if="!loading_notifications && notifications.stats.weekly">
							<h4>{{ $t('common.last_week') }}</h4>
							<div class="panel_number panel_number__with_margin_bottom">
								<div class="row">
									<div class="col panel__right_margin histogram_label">
										<span class="panel_number__number_big color_black">{{notifications.stats.weekly.value | number}}</span>
									</div>

									<div class="col">
										<span class="full histogram_counter_label_small">{{$t('common.users')}}</span>
										<span class="perc"
											  v-bind:class="{ 'color_green': notifications.stats.weekly.increase > 0, 'color_red': notifications.stats.weekly.increase < 0}"
											  v-if="notifications.stats.weekly.increase">
												<span v-if="notifications.stats.weekly.increase > 0">+</span>{{notifications.stats.weekly.increase | percentage}}
											</span>
									</div>
								</div>
							</div>
						</div>
						<div v-if="!loading_notifications && notifications.stats.range">
							<h4>{{ $t('notifications.notifications.selected') }}</h4>
							<div class="panel_number panel_number__with_margin_bottom">
								<div class="row">
									<div class="col panel__right_margin histogram_label">
										<span class="panel_number__number_big color_black">{{notifications.stats.range.value | number}}</span>
									</div>

									<div class="col">
										<span class="full histogram_counter_label_small">{{$t('common.users')}}</span>
										<span class="perc"
											  v-bind:class="{ 'color_green': notifications.stats.range.increase > 0, 'color_red': notifications.stats.range.increase < 0}"
											  v-if="notifications.stats.range.increase">
												<span v-if="notifications.stats.range.increase > 0">+</span>{{notifications.stats.range.increase | percentage}}
											</span>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="full" v-if="loading_notifications">
						<loading-spinner></loading-spinner>
					</div>
				</div>
				<div class="row panel__top_margin" v-if="err_notifications !== ''">
					<div class="full">
						{{ $t(`notifications.returning.errors.${err_notifications}`) }}
					</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="panel col full panel__top_margin">
				<h4>{{ $t('notifications.impressions.title') }}</h4>
				<div class="row panel__top_margin"  v-if="err_impressions == ''">
					<div class="three-quarters-histogram" v-if="!loading_impressions">
						<impressions-histogram-chart :height="150"
												 	:coverage="coverage"
												   :data="impressions.histogram_data"></impressions-histogram-chart>
					</div>
					<div class="one-quarters-histogram"  style="padding-left: 1em">
						<div v-if="!loading_impressions && impressions.stats.daily">
							<h4>{{ $t('common.today') }}</h4>
							<div class="panel_number panel_number__with_margin_bottom">
								<div class="row">
									<div class="col panel__right_margin histogram_label">
										<span class="panel_number__number_big color_black">{{impressions.stats.daily.value | number}}</span>
									</div>

									<div class="col">
										<span class="full histogram_counter_label_small">{{$t('common.users')}}</span>
										<span class="perc"
											  v-bind:class="{ 'color_green': impressions.stats.daily.increase > 0, 'color_red': impressions.stats.daily.increase < 0}"
											  v-if="impressions.stats.daily.increase">
												<span v-if="impressions.stats.daily.increase > 0">+</span>{{impressions.stats.daily.increase | percentage}}
											</span>
									</div>
								</div>
							</div>
						</div>

						<div v-if="!loading_impressions && impressions.stats.weekly">
							<h4>{{ $t('common.last_week') }}</h4>
							<div class="panel_number panel_number__with_margin_bottom">
								<div class="row">
									<div class="col panel__right_margin histogram_label">
										<span class="panel_number__number_big color_black">{{impressions.stats.weekly.value | number }}</span>
									</div>

									<div class="col">
										<span class="full histogram_counter_label_small">{{$t('common.users')}}</span>
										<span class="perc"
											  v-bind:class="{ 'color_green': impressions.stats.weekly.increase > 0, 'color_red': impressions.stats.weekly.increase < 0}"
											  v-if="impressions.stats.weekly.increase">
												<span v-if="impressions.stats.weekly.increase > 0">+</span>{{impressions.stats.weekly.increase | percentage}}
											</span>
									</div>
								</div>
							</div>
						</div>


						<div v-if="!loading_impressions && impressions.stats.range">
							<h4>{{ $t('notifications.impressions.selected') }}</h4>
							<div class="panel_number panel_number__with_margin_bottom">
								<div class="row">
									<div class="col panel__right_margin histogram_label">
										<span class="panel_number__number_big color_black">{{impressions.stats.range.value | number}}</span>
									</div>

									<div class="col">
										<span class="full histogram_counter_label_small">{{$t('common.users')}}</span>
										<span class="perc"
											  v-bind:class="{ 'color_green': impressions.stats.range.increase > 0, 'color_red': impressions.stats.range.increase < 0}"
											  v-if="impressions.stats.range.increase">
												<span v-if="impressions.stats.range.increase > 0">+</span>{{impressions.stats.range.increase | percentage}}
											</span>
									</div>
								</div>
							</div>
						</div>

					</div>
					<div class="full" v-if="loading_impressions">
						<loading-spinner></loading-spinner>
					</div>
				</div>
				<div class="row panel__top_margin" v-if="err_impressions !== ''">
					<div class="full">
						{{ $t(`dashboard.returning.errors.${err_impressions}`) }}
					</div>
				</div>
			</div>
		</div>
	</main>
</template>

<script>
    import SelectTimePeriod from "../components/common/filters/SelectTimePeriod";
    import LoadingSpinner from "../components/common/Loading";
    import NotificationsHistogramChart from "../components/common/graphs/NotificationsHistogramChart";
    import ImpressionsHistogramChart from "../components/common/graphs/ImpressionsHistogramChart";
    import EventsHistogramChart from "../components/common/graphs/EventsHistogramChart";

    export default {
        name: 'NotificationsPage',
        components: {
            EventsHistogramChart,
            NotificationsHistogramChart, ImpressionsHistogramChart, LoadingSpinner, SelectTimePeriod},
        data() {
            return {
                loading_events: false,
                err_events: '',
                loading_impressions: false,
                err_impressions: '',
                loading_notifications: false,
                err_notifications: '',
                username: '',
                coverage: [],
                impressions: {
                    stats: {},
                    histogram_data: []
                },
                notifications: {
                    stats: {},
                    histogram_data: []
                },
                events: {
                    stats: {
                        nearplaces: 0,
                        inplaces: 0,
                        inareas: 0
					},
                    histogram_data: {
                        nearplaces: [],
                        inplaces: [],
                        inareas: []
                    }
                }
            }
        },
        methods: {
            updateData() {
                this.loading_impressions = true;
                this.loading_notifications = true;
                this.err_impressions = '';
                this.err_notifications = '';

                // definizione delle app
                let app_ids = localStorage.getItem('selected_apps');
                try {
                    app_ids = JSON.parse(app_ids);
                } catch(e) {
                    app_ids = [];
                }

                let from = localStorage.getItem('selected_time_period_from');
                let to = localStorage.getItem('selected_time_period_to');

                // events
                this.service.statistics.events.histogram(app_ids, from, to)
                    .then((histogram_data) => {
                        this.events.histogram_data = histogram_data;

                        Object.keys(histogram_data).map(k => {
                            if(["inareas", "inplaces", "nearplaces"].includes(k)) {
                                this.events.stats[k] = 0;
                                histogram_data[k].map(i => {
                                    this.events.stats[k] += +i.count;
								})
							}
						});

                    })
                    .catch((err) => {
                        this.loading_events = false;
                        this.err_events = err.message;
                    })
                    .finally(() => {
                        this.loading_events = false;
                    })

                // impressions
                this.service.statistics.coverage(app_ids, from, to)
                    .then((coverage_data) => {

                        this.coverage = coverage_data;

                        this.service.statistics.impressions.histogram(app_ids, from, to)
                            .then((histogram_data) => {
                                this.service.statistics.impressions.stats(app_ids, from, to)
                                    .then((stats_data) => {
                                        this.impressions.histogram_data = histogram_data;
                                        this.impressions.stats = stats_data;
                                    })
                                    .finally(() => {
                                        this.loading_impressions = false;
                                    })
                            })
                            .catch((err) => {
                                this.loading_notifications = false;
                                this.err_impressions = err.message;
                            })
                            .finally(() => {
                                this.loading_impressions = false;
                            })

                        // notifications
                        this.service.statistics.notifications.histogram(app_ids, from, to)
                            .then((histogram_data) => {
                                this.service.statistics.notifications.stats(app_ids, from, to)
                                    .then((stats_data) => {
                                        this.notifications.histogram_data = histogram_data;
                                        this.notifications.stats = stats_data;
                                    })
                                    .finally(() => {
                                        this.loading_notifications = false;
                                    })
                            })
                            .catch((err) => {
                                this.loading_notifications = false;
                                this.err_notifications = err.message;
                            })
                            .finally(() => {
                                this.loading_notifications = false;
                            })

					});



            },
			init() {
                this.$root.$on('filters_updated', () => {
                    if(this.$router.currentRoute.name == 'notifications.page') {
                        this.updateData();
                    }
                });
			}
        },
        mounted() {
            this.init();
            this.updateData();
        }
    }
</script>


<style lang="scss">
	@import '~@/assets/scss/pages/_dashboard';

	.color_orange {
		// display: block;
		// width: 100%;

		.name {
			display: block;
			width: 100%;
			font-weight: 300;
			font-size: 1.3em;
			margin-top: calc(var(--gutter) / 2);
		}
	}

	.histogram_label {
		line-height: 50px;

		span {
			font-weight: 300;
		}
	}

	.histogram_counter_label_small {
		font-size: 12px;
	}

	.perc {
		font-size: 15px;
		margin-top: -7px;
	}

	.text__inareas,
	.text__inplaces,
	.text__nearplaces {
		font-size: 15px;
		margin-top: -7px;
	}

	.text__inareas {
		color: $orange
	}

	.text__inplaces {
		color: $red
	}

	.text__nearplaces {
		color: $blue
	}
</style>

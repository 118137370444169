<template>
	<main id="reset-password" class="panel form">
		<!--<div class="login-form panel form">-->
		<form-logo :subtitle="$t('form.change_password.title')"></form-logo>
		<div class="panel form error" v-if="loading || !token_valid">
			<span v-if="loading">{{$t('common.loading')}}</span> <span v-if="!loading && !token_valid">
                {{$t('form.change_password.errors.token_not_valid')}}
                <br><br><br><br>
                <router-link :to="{ name: 'password.request.reset.page' }">
                    {{ $t('common.back') }}
                </router-link>
            </span>
		</div>
		<div class="panel form error" v-if="!loading && complete">
			{{$t('form.change_password.password_changed')}}<br><br><br><br>
			<router-link :to="{ name: 'login.page' }">
				{{ $t('common.login') }}
			</router-link>
		</div>
		<change-password-form v-if="!loading && token_valid && !complete"
							  @success="changePassword($event)"></change-password-form>
	</main>
</template>
<script>
    import ChangePasswordForm from "../../components/reset-password/ChangePasswordForm";
    import FormLogo from "../../components/common/FormLogo";

    export default {
        name: 'ResetPasswordCheckPage',
        components: {
            FormLogo,
            ChangePasswordForm
        },
        data() {
            return {
                loading: true,
                token_valid: false,
                complete: false
            }
        },
        mounted() {
            this.init();
        },
        methods: {
            init() {
                this.loading = true;
                this.token_valid = false;
                let token = this.$route.query.token || '';
                this.service.check_token(token)
                    .then(() => {
                        this.token_valid = true;
                    })
                    .catch(() => {
                        this.token_valid = false;
                    })
                    .finally(() => {
                        this.loading = false;
                    })
            },
            changePassword(data) {
                this.loading = true;
                this.error = false;
                this.service.change_password_by_token(
                    this.$route.query.token, data.password, data.repeat)
                    .then(() => {
                        this.complete = true;
                    })
                    .catch(() => {
                        this.token_valid = false;
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            }
        }
    }
</script>

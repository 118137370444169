<template>
    <div id="app">
        <Icons />
        <router-view/>
    </div>
</template>
<script>
    import Icons from '@components/Icons.vue';
    export default {
        components: {
            Icons
        }
    }
</script>
<style lang="scss">
@import '~@/assets/scss/main';
</style>

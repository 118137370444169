<template>
    <main id="invite" class="main-content">
        <div class="panel form error" v-if="loading || !token_valid">
            <span v-if="loading">{{$t('common.loading')}}</span> <span v-if="!loading && !token_valid">
                {{$t(`form.invites.errors.${error}`)}}
            </span>
        </div>
        <div class="panel form" v-if="!loading && token_valid">
            <div v-if="step == 1">
                <div class="header-form">
                    <h1>{{ $t('common.welcome') }}</h1>
                    <p>{{ $t('form.invites.change_password_description') }}</p>
                </div>
                <change-password-form @success="changePassword($event)"></change-password-form>
            </div>
            <div v-if="step == 2">
                <div class="header-form">
                    <h1>{{ $t('form.invites.agency_title') }}</h1>
                    <p>{{ $t('form.invites.agency_description') }}</p>
                </div>
                <publisher-info-form :submit_text="$t('common.next')" @success="step = 3"></publisher-info-form>
            </div>
            <div v-if="step == 3">
                <div class="header-form">
                    <h1>{{ $t('form.invites.account_title') }}</h1>
                    <p>{{ $t('form.invites.account_description') }}</p>
                </div>
                <send-invite @success="$root.$emit('update-list-invites')"></send-invite>
                <list-invites></list-invites>
                <router-link class="btn big full" :to="{ name: 'dashboard.page' }">
                    {{ $t('common.finish') }}
                </router-link>

            </div>
        </div>
    </main>
</template>
<script>
    import ChangePasswordForm from "../components/reset-password/ChangePasswordForm";
    import PublisherInfoForm from "../components/PublisherInfoForm";
    import SendInvite from "../components/SendInvite";
    import ListInvites from "../components/ListInvites";

    export default {
        name: 'InvitePage',
        components: {
            ListInvites,
            SendInvite,
            PublisherInfoForm,
            ChangePasswordForm},
        data() {
            return {
                loading: true,
                token_valid: false,
                error: '',
                step: 1,
                user: null
            }
        },
        mounted() {
            this.init();
        },
        methods: {
            init() {
                localStorage.removeItem('token');
                this.loading = true;
                this.error = '';
                this.token_valid = false;
                let token = this.$route.query.token || '';
                this.service.invites.check_token(token)
                    .then(() => {
                        this.token_valid = true;
                    })
                    .catch(() => {
                        this.token_valid = false;
                        this.error = 'token_not_valid';
                    })
                    .finally(() => {
                        this.loading = false;
                    })
            },
            changePassword(data) {
                //this.loading = true;
                this.error = '';
                this.service.invites.set_password(
                    this.$route.query.token,
                    data.password,
                    data.repeat,
                    this.$i18n.locale)
                    .then((data) => {
                        if(data && data.user) {
                            this.user = data.user;
                            localStorage.setItem('publisher', data.publishers[0].publisher.id)
                            localStorage.setItem('token', data.jwt);
                        }
                        if(data.publishers[0].role == "OWNER") {
                            this.step = 2;
                        } else {
                            this.$router.push("/app").catch(() => {});
                        }
                    })
                    .catch((err) => {
                        this.token_valid = false;
                        this.error = err.message;
                    })
                    .finally(() => {
                        this.loading = false;
                    })

            },
            end() {
                this.$router.push("/app").catch(() => {});
            }
        }
    }
</script>

<template>
    <header class="dashboard--head">
        <router-link to="/" class="logo">
            <svg viewBox="0 0 121 37">
                <use xlink:href="#logo" width="100%" height="100%"></use>
            </svg>
        </router-link>
		<!--<div class="version_base">
			202206091441
		</div>-->
        <div class="head--content">
            <select-apps v-if="show_apps"></select-apps>
            <span></span>
            <ProfileUtility />
        </div>
    </header>
</template>

<script>
import ProfileUtility from '../../components/common/ProfileUtility.vue';
import SelectApps from "../../components/common/filters/SelectApps";

export default {
    name: 'Head',
    components: {
        SelectApps,
        ProfileUtility
    },
    mounted() {
        this.init();
    },
    data() {
        return {
            show_apps: false
        }
    },
    methods: {
        init() {
            switch(this.$route.name) {
                case 'dashboard.page':
                case 'realtime.page':
                case 'notifications.page':
                case 'campaigns.page':
                case 'report.page':
                    this.show_apps = true;
                    break;
                default:
                    this.show_apps = false
            }
        }
    },
    watch:{
        $route (){
            this.init();
        }
    }
}
</script>

<style lang="scss">
.version_base {
	position: absolute;
	top: 0;
	right: 0;
	font-size: 6px;
	line-height: 10px;
}
</style>

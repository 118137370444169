import Vue from 'vue'
import App from './App.vue'
import router from './router'
import i18n from './i18n'
import {Vuelidate} from 'vuelidate'
import ApiPlugin from './plugins/api.plugin';
import ServicePlugin from './plugins/service.plugin';
import VCalendar from 'v-calendar';
import moment from 'moment';
import * as VueGoogleMaps from 'vue2-google-maps'

Vue.config.productionTip = false;
Vue.use(Vuelidate);
Vue.use(ApiPlugin);
Vue.use(ServicePlugin, router);
Vue.use(VCalendar, {
    componentPrefix: 'vc'
});

const GOOGLE_MAPS_KEY = (process.env.VUE_APP_GOOGLE_MAPS_KEY !== undefined && process.env.VUE_APP_GOOGLE_MAPS_KEY !== "") ? process.env.VUE_APP_GOOGLE_MAPS_KEY : '{{ VUE_APP_GOOGLE_MAPS_KEY }}';
Vue.use(VueGoogleMaps, {
    load: {
        key: GOOGLE_MAPS_KEY,
        libraries: 'places',
        installComponents: true
    },
})

Vue.filter('percentage', function (value, decimals) {
    if (!value) {
        value = 0;
    }
    if (!decimals) {
        decimals = 0;
    }
    value = value * 100;
    value = Math.round(value * Math.pow(10, decimals)) / Math.pow(10, decimals);
    value = value + '%';
    return value;
});

Vue.filter('number', function (value) {
    if (!value) {
        value = 0;
    }
    const amt = Number(value)

    var si = [
        { value: 1, symbol: "" },
        { value: 1E3, symbol: "k" },
        { value: 1E6, symbol: "M" },
        { value: 1E9, symbol: "G" },
        { value: 1E12, symbol: "T" },
        { value: 1E15, symbol: "P" },
        { value: 1E18, symbol: "E" }
    ];
    var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var i;
    for (i = si.length - 1; i > 0; i--) {
        if (amt >= si[i].value) {
            break;
        }
    }
    return (amt / si[i].value).toLocaleString(undefined, {maximumFractionDigits: 2}).replace(rx, "$1") + si[i].symbol;

    // return amt && amt.toLocaleString(undefined, {maximumFractionDigits: 2}) || '0'
});

Vue.filter('data', function (value) {
    return moment(+value).format('DD/MM/YY');
});

Vue.filter('currency', function (value) {
    return `${value.toLocaleString()} €`
});



Vue.filter('data_custom', function (value, format) {
    return moment(+value).format(format);
});

Vue.filter('application_name', function (value, apps) {
    apps = apps || [];
    let a = apps.filter(a => {
        return a.id.trim() === value
    });
    a = (a && a.length) ? a.pop().name : "";
    return a;
});

new Vue({
    router,
    i18n,
    render: h => h(App)
}).$mount('#app');



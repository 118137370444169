import axios from 'axios';

const API_URL = (process.env.VUE_APP_API_URL !== undefined && process.env.VUE_APP_API_URL !== "") ? process.env.VUE_APP_API_URL : '{{ VUE_APP_API_URL }}';
const fileDownload = require('js-file-download');

export default {
    install: function (Vue) {
        // global $http object
        Object.defineProperty(Vue.prototype, '$http', {value: axios});
        // axios settings
        axios.defaults.headers.common['Content-Type'] = 'application/json;charset=utf-8';
        axios.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8';
        axios.defaults.headers.get['Content-Type'] = 'application/json;charset=utf-8';
        axios.defaults.withCredentials = true;
        /**
         * Creazione della risposta
         * @param response
         * @param resolve
         * @param reject
         * @param token
         * @param redirectUnauthorized
         */
        const buildResponse = (response, resolve, reject) => {
            if (response && response.data && response.status && response.status == 200) {
                if (response.data.success) {
                    resolve(response.data.data);
                }
                reject(response.data);
            }
            if (response && response.data && response.data.message && response.status != 200) {
                reject(response.data);
            }
            reject({
                success: false,
                message: 'api-general-error',
            });
        };
        /**
         *
         */
        const baseConfig = () => {
            let token = localStorage.getItem('token');
            return {
                validateStatus: () => {
                    return true;
                },
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };
        };
        //
        // definizione delle api
        //
        const api = {
            getApiUrl() {
                return API_URL;
            },
            /**
             * @param email
             * @param password
             * @returns {Promise<any>}
             */
            login(email, password) {
                return new Promise((resolve, reject) => {
                    localStorage.removeItem('token');
                    localStorage.removeItem('publisher');
                    axios.post(`${API_URL}/public/api/auth/signin`, {
                        username: email.trim().toLowerCase(),
                        password: password.trim(),
                    }, {
                        validateStatus: () => {
                            return true;
                        },
                    })
                        .catch(() => {
                            reject({
                                success: false,
                                error: 'api-general-error',
                            });
                        })
                        .then((result) => {
                            buildResponse(result, (r) => {
                                localStorage.setItem('token', r.jwt);
                                resolve(r);
                            }, reject);
                        });
                });
            },
            /**
             * @param resource
             * @param data
             * @returns {Promise<unknown>}
             */
            get(resource, data = {}) {
                let config = baseConfig();
                if (data) {
                    config.data = data;
                }
                return new Promise((resolve, reject) => {
                    axios.get(`${API_URL}${resource}`, config)
                        .catch((error) => {
                            reject({
                                success: false,
                                error: error,
                            });
                        })
                        .then((result) => {
                            buildResponse(result, resolve, reject);
                        });
                });
            },
            paymentDownload(resource, data) {
                let config = baseConfig();
                config.responseType = "blob";
                config.headers.contentType = "blob";
                if (data) {
                    config.data = data;
                }
                return new Promise((resolve, reject) => {
                    axios.get(`${API_URL}${resource}`, config)
                        .catch((error) => {
                            reject({
                                success: false,
                                error: error,
                            });
                        })
                        .then((result) => {
                            const pattern = /".*?"/g;
                            const filename = pattern.exec(result.headers["content-disposition"]);
                            const downloadFileName = `payment_${filename[0].replace(/"/g, "").trim()}`;
                            fileDownload(result.data, downloadFileName);
                            resolve(true);
                        });
                });
            },
            /**
             * Nuovo inserimento di una risorsa
             * @param resource
             * @param data
             * @returns {Promise<unknown>}
             */
            post(resource, data) {
                let config = baseConfig();
                return new Promise((resolve, reject) => {
                    axios.post(`${API_URL}${resource}`, data, config)
                        .catch((error) => {
                            reject({
                                success: false,
                                error: error,
                            });
                        }).then((result) => {
                        buildResponse(result, resolve, reject);
                    });
                });
            },
            campaignsDownload(resource, data) {
                let config = baseConfig();
                config.responseType = "blob";
                config.headers.contentType = "blob";
                return new Promise((resolve, reject) => {
                    axios.post(`${API_URL}${resource}`, data, config)
                        .catch((error) => {
                            reject({
                                success: false,
                                error: error,
                            });
                        }).then((result) => {
                        const pattern = /".*?"/g;
                        const filename = pattern.exec(result.headers["content-disposition"]);
                        const downloadFileName = `${filename[0].replace(/"/g, "").trim()}`;
                        fileDownload(result.data, downloadFileName);
                        resolve(true);
                    });
                });
            },
            /**
             * Modifica di una risorsa
             * @param resource
             * @param data
             * @returns {Promise<unknown>}
             */
            put(resource, data) {
                let config = baseConfig();
                return new Promise((resolve, reject) => {
                    axios.put(`${API_URL}${resource}`, data, config)
                        .catch((error) => {
                            reject({
                                success: false,
                                error: error,
                            });
                        }).then((result) => {
                        buildResponse(result, resolve, reject);
                    });
                });
            },
            putFile(resource, field, data, filename) {
                let config = baseConfig();
                config.headers["Content-Type"] = "multipart/form-data";
                let form = new FormData();
                form.append(field, data, filename);
                return new Promise((resolve, reject) => {
                    axios.put(`${API_URL}${resource}`, form, config)
                        .catch((error) => {
                            reject({
                                success: false,
                                error: error,
                            });
                        }).then((result) => {
                        buildResponse(result, resolve, reject);
                    });
                });
            },
            /**
             * Eliminazione di una risorsa
             * @param resource
             * @param data
             * @returns {Promise<unknown>}
             */
            delete(resource, data = {}) {
                let config = baseConfig();
                if (data) {
                    config.data = data;
                }
                return new Promise((resolve, reject) => {
                    axios.delete(`${API_URL}${resource}`, config)
                        .catch((error) => {
                            reject({
                                success: false,
                                error: error,
                            });
                        })
                        .then((result) => {
                            buildResponse(result, resolve, reject);
                        });
                });
            },
        };
        Vue.prototype.api = api;
    },
};

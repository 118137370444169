<script>
export default {
  name: "CheckAccessToken",
  mounted() {
    const token = this.$route.query.token;
    const publisherId = this.$route.query.publisher;

    const redirect = this.$route.query.redirect
        .replace(new RegExp(`&?token=${token}`, 'g'), '')
        .replace(new RegExp(`&?publisher=${publisherId}`, 'g'), '')
        .replace(new RegExp('\\?&', 'g'), '?');

    this.service.tokenLogin(token, publisherId)
        .then(() => {
          this.$router.push({path: redirect.toString()});
        })
        .catch(() => {
          this.$router.push({
            name: 'login.page',
            query: {redirectFrom: this.$route.fullPath},
          });
        });
  },
};
</script>

<template>
  <main/>
</template>
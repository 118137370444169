<template>
    <div>
        <h1 class="logo">
            <svg viewBox="0 0 121 37">
                <use xlink:href="#logo" width="100%" height="100%"></use>
            </svg>
        </h1>
        <span class="text">{{stext}}</span>
    </div>
</template>
<script>
    export default {
        name: 'FormLogo',
        props: {
            subtitle: {
                required: false
            }
        },
        data() {
            return {
                stext: null
            }
        },
        mounted() {
            this.init();
        },
        methods:{
            init() {
                this.stext = this.subtitle || this.$t('common.slogan');
            }
        }
    }
</script>
<style lang="scss"></style>

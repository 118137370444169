<script>
import { Bar } from "vue-chartjs";
import moment from "moment";

export default {
	name: "DashboardHistogramChart",
	extends: Bar,
	props: {
		data: {
			required: true,
		},
	},
	data() {
		return {};
	},
	mounted() {
		this.updateData();
	},
	methods: {
		updateData() {
			let d = [];
			let labels = [];
			if (this.data && Array.isArray(this.data)) {
				this.data.map((dm) => {
					d.push(dm.count);
					let from = moment(+localStorage.getItem("selected_time_period_from"));
					let to = moment(+localStorage.getItem("selected_time_period_to"));
					let duration = moment.duration(to.diff(from)).asHours();

					if (duration > 48) {
						labels.push(moment(dm.date).format("DD/MM/YYYY"));
					} else {
						labels.push(moment(dm.date).format("DD/MM - HH:00"));
					}
				});
			}

			this.renderChart(
				{
					datasets: [
						{
							data: d,
							backgroundColor: "#ed7d3155",
							fill: true,
							fontColor: "#ed7d31",
							borderColor: "#ed7d31",
						},
					],
					labels: labels,
				},
				{
					legend: {
						display: false,
					},
					scales: {
						yAxes: [
							{
								ticks: {
									beginAtZero: true,
								},
							},
						],
					},
				}
			);
		},
	},
	watch: {
		data: {
			deep: true,
			handler() {
				this.updateData();
			},
		},
	},
};
</script>
<style>
</style>

<template>
	<main id="development" class="development main-content">

		<div class="first-row row" v-if="!loading && application">
			<div class="col half">
				<div class="welcome">
					<div class="welcome--box">
						<h1>
							{{application.name}}
						</h1>
						<h2 @click="goBack()" class="pointer">
							{{ $t('common.back') }}
						</h2>
					</div>
				</div>
			</div>
		</div>

		<div class="row panel" v-if="!loading && application">
			<div class="col one-quarters">
				<AppLogoImage :image-url="application.icon" :app-id="application.id"></AppLogoImage>
				<p class="logo_image_information">
					{{$t('development.detail.icon_information')}}
				</p>
			</div>
			<div class="col  half">
				<p class="app_detail"><strong>{{$t('development.detail.name')}}: </strong>{{application.name}}</p>
				<p class="app_detail"><strong>{{$t('development.detail.categories')}}: </strong>
					<span v-for="(c, i) in application.categories" :key="i">
						{{$t(`development.categories.${c}`)}}<span v-if="i < application.categories.length - 1">,</span>
					</span>
					<span v-if="application && application.categories && application.categories.length <= 0">
						{{$t('development.detail.no-categories')}}
					</span>
				</p>
				<p class="app_detail"><strong>{{$t('development.detail.apyKey')}}: </strong>{{application.id}}</p>
				<p class="app_detail"><strong>{{$t('development.detail.secret')}}: </strong>{{application.secret}}</p>
			</div>
			<div class="col one-quarters col__end col__center">
				<button type="button" class="btn big" @click="show_app_modal = true">
					<span>{{$t('development.detail.edit')}}</span>
				</button>
			</div>
		</div>

		<div class="row row__padding_top" v-if="!loading && application">
			<div class="col three-quarters ">
				<h2>
					{{ $t('common.platforms') }}
				</h2>
			</div>
			<div class="col one-quarters col__end col__center">
				<button type="button" class="btn big" @click="show_app_platform_modal = true">
					<span>{{$t('development.detail.add_platform')}}</span>
				</button>
			</div>
		</div>

		<div class="row row__padding_top panel" v-if="!loading && application">
			<div class="col one-quarters platforms">
				<div v-for="(p, index) in platforms" :key="index" class="pointer platforms__list_item"
					 :class="{'active': selectedPlatform.platform === p.platform && selectedPlatform.identifier === p.identifier}"
					 @click="selectedPlatform = p">
					<svg viewBox="0 0 40 40">
						<use :href="`#icons--${p.platform}`" width="100%" height="100%"></use>
					</svg>
					<span>{{ p.identifier }}</span>
				</div>
			</div>
			<div class="col three-quarters" v-if="selectedPlatform">
				<div class="platforms__detail">
					<p class="app_detail"><strong>{{$t('common.platform')}}: </strong>{{selectedPlatform.platform}}</p>
					<p class="app_detail"><strong>{{$t('development.detail.identifier')}}: </strong>{{selectedPlatform.identifier}}
					</p>

					<template v-if="selectedPlatform && application && application.id">
						<TestApp :app-id="application.id" :platform="selectedPlatform" :show-button-stop="false" :start="selectedPlatformTestStart"></TestApp>
					</template>


					<hr>
					<div class="remove col__center text-center">
						<button class="btn btn_red" @click="removePlatform()">
							{{$t('common.remove')}}
						</button>
					</div>

				</div>
			</div>
		</div>

		<LoadingSpinner v-if="loading"></LoadingSpinner>
		<FormAppModal v-if="show_app_modal" @close="closeAppModal($event)" :app="application"></FormAppModal>
		<FormAppPlatformModal v-if="show_app_platform_modal" :app-id="application.id" @close="closeAppFormModal($event)"></FormAppPlatformModal>
	</main>
</template>

<script>
    import LoadingSpinner from "../components/common/Loading";
    import FormAppModal from "../components/development/FormAppModal";
    import FormAppPlatformModal from "../components/development/FormAppPlatformModal";
    import TestApp from "../components/development/TestApp";
    import AppLogoImage from "../components/development/AppLogoImage";

    export default {
        name: 'DevelopmentPlatformPage',
        components: {AppLogoImage, TestApp, FormAppPlatformModal, FormAppModal, LoadingSpinner},
        data() {
            return {
                loading: false,
                application: {},
                platforms: [],
                selectedPlatform: null,
				selectedPlatformTestStart: false,
                show_app_modal: false,
                show_app_platform_modal: false
            }
        },
        methods: {
            init() {
                this.loadApplication()
            },
            goBack() {
                this.$router.push({name: 'development.page'});
            },
            closeAppModal(e) {
                if (e !== null) {
                    this.loading = true;
                    // inserimento app
                    this.service.applications.update(this.$route.params.id, e.name, e.categories)
                        .then(() => {
                            this.loadApplication();
                        });
                }
                this.show_app_modal = false;
            },
            closeAppFormModal() {
                this.loadApplication()
                this.show_app_platform_modal = false;
            },
            loadApplication() {
                this.loading = true;
                this.applications = [];
                this.service.applications.single(this.$route.params.id)
                    .then(app => {
                        this.application = app
                        this.service.applications.platforms.list(this.$route.params.id)
                            .then(platforms => {
                                this.platforms = platforms;
                                if (this.platforms.length > 0) {
                                    this.selectedPlatform = this.platforms[0];
                                }
                            })
                            .finally(() => {
                                this.loading = false;
                            })
                    });
            },
            removePlatform() {
                if (confirm(this.$t('development.detail.remove_platform'))) {
                    this.loading = true;
                    this.service.applications.platforms.delete(this.$route.params.id, this.selectedPlatform.platform, this.selectedPlatform.identifier)
                        .then(() => {
							this.loadApplication();
                        });
                }
            }
        },
        mounted() {
            this.init();
        }
    }
</script>

<style lang="scss">
	.app_detail {
		width: 100%;
		padding-bottom: 15px;
		//font-size: 15px !important;
	}

	.logo_image_information {
		font-size: 0.7em;
		width: 50%;
	}

	.platforms {
		display: flex;
		flex-wrap: nowrap;
		flex-direction: column;

		&__list_item {
			display: flex;
			align-items: center;
			min-height: 60px;
			border-right: 1px solid $grey;
			padding: 10px;
			font-size: 15px;

			svg {
				width: 15px;
				margin-right: 10px;
			}
		}

		&__detail {
			width: 100%;
			padding: 20px;
		}

		.active {
			background: $orange;
			color: $white;
		}
	}
</style>

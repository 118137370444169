<script>
import LoadingSpinner from "@components/common/Loading.vue";

export default {
  name: "OptinAcceptedModal",
  components: { LoadingSpinner },
  props: {
    campaignName: {
      type: String,
      default: "",
    },
    applicationIds: {
      type: Array,
      default: () => [],
    },
    brandName: {
      type: String,
      default: "",
    },
    showAutoOptinInvite: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      applications: [],
      imageLoaded: false,
    };
  },
  computed: {
    multipleApps() {
      return this.applications.length > 1;
    },
    loading() {
      return !this.applications.length > 0 && !this.imageLoaded;
    },
  },
  mounted() {
    this.service.applications.list()
      .then(response => {
        this.applications = response.filter(application => this.applicationIds.includes(application.id));
      });
  },
};
</script>

<template>
  <transition name="optinAccepted.modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div v-if="loading" class="modal-container">
          <div class="loading-wrapper">
            <loading-spinner />
          </div>
        </div>
        <div v-else class="modal-container">
          <div id="modal-content">
            <div class="modal-content-section">
              <h3>{{ $t('campaigns.optin.accepted.modal.title') }}</h3>
              <div v-if="multipleApps">
                {{ $t('campaigns.optin.accepted.modal.textMultipleAppsFirstPart') }}
                <ul>
                  <li v-for="app in applications" :key="app.id">
                    {{ app.name }}
                  </li>
                </ul>
                {{
                  $t('campaigns.optin.accepted.modal.textMultipleAppsLastPart', {
                    campaignName: this.campaignName,
                  })
                }}
              </div>
              <div v-else
                v-html="$t('campaigns.optin.accepted.modal.textSingleApp', { appName: this.applications[0].name, campaignName: this.campaignName })" />
            </div>
            <div class="modal-content-section">
              <div class="modal-image-wrapper">
                <img src="@/assets/images/optin_accepted.webp" alt="" @load="imageLoaded = true">
              </div>
            </div>
            <div class="modal-content-section">
              <div v-if="showAutoOptinInvite">
                <div>
                  <strong>E ora?</strong>
                </div>
                <div
                  v-html="$t('campaigns.optin.accepted.modal.automaticCampaignAcceptance.inviteText', { brandName: this.brandName })" />
              </div>
              <div class="modal-button-group">
                <button v-if="showAutoOptinInvite" class="primary" @click="$emit('autoAcceptNextOptins')">
                  {{ $t('campaigns.optin.accepted.modal.automaticCampaignAcceptance.enableButtonText') }}
                </button>
                <button class="secondary" @click="$emit('close')">
                  {{ $t('campaigns.optin.accepted.modal.closeModalButtonText') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<style scoped lang="scss">
.modal-container {
  display: flex;
  flex-direction: row;
  gap: 2rem;
  background-color: white;
  border-radius: 30px;
  width: 50rem;
  padding: 1.2rem 2.35rem;
}

#modal-side {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

#modal-content {
  flex-grow: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 2.5rem;
}

.modal-separator {
  background-color: $orange;
  width: 40%;
  height: 5px;
  margin-left: calc(calc(65% / 2) - 20%);
}

.modal-content-section {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  color: black;
  font-size: 1.3rem;
  font-weight: normal;
  line-height: 1.3;
}

.modal-image-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-image-wrapper img {
  width: 15rem;
  height: 15rem;
}

.modal-content-section ul {
  margin-bottom: 1rem;
  list-style-type: disc;
  list-style-position: inside;
}

.modal-button-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  gap: 1rem;
  margin: 0.2rem 5rem 2rem 5rem;
}

button {
  border-radius: 25px;
  padding: 0.6rem;
  width: 65%;
  font-size: 0.8rem;
  font-weight: bold;
  border: 3px solid $orange;
}

button.primary {
  background-color: $orange;
  color: white;
}

button.primary:hover {
  background-color: white;
  color: black;
}

button.secondary {
  background-color: white;
  color: black;
}

button.secondary:hover {
  background-color: #ececec;
}

.loading-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>

<template>
	<main id="development" class="development main-content">

		<div class="first-row row">
			<div class="col half">
				<div class="welcome">
					<div class="welcome--box">
						<h1>
							{{ $t('development.title') }}
						</h1>
						<h2>
							{{ $t('development.subtitle') }}
						</h2>
					</div>
				</div>
			</div>
		</div>

		<div class="row">
			<div class="col three-quarters" style="height: 100%;">
				<div class="panel full panel__right_margin panel__bottom_margin">
					<h4>{{$t('development.app.title')}}</h4>
					<p>
						{{$t('development.app.description')}}
					</p>
					<p class="panel__top_margin_big">
						<button type="button" class="btn big" @click="show_app_modal = true">
							<span>{{ $t('development.app.addnew') }}</span>
						</button>
					</p>

				</div>
			</div>
			<div class="col one-quarters" style="height: 100%;">
				<div class="panel full panel__bottom_margin">
					<h4>{{$t('development.support.title')}}</h4>
					<p>
						{{$t('development.support.description')}}
					</p>
				</div>
			</div>
		</div>

		<div class="row">
			<LoadingSpinner v-if="loading_applications"></LoadingSpinner>
			<h4 v-if="!loading_applications" class="panel__bottom_margin">{{$t('common.application')}}</h4>
			<div v-if="!loading_applications" class="row">
				<BoxApp v-for="a in applications" :key="a.id" :app="a"></BoxApp>
			</div>
		</div>

		<FormAppModal v-if="show_app_modal" @close="closeAppModal($event)"></FormAppModal>

	</main>
</template>

<script>
    import FormAppModal from "../components/development/FormAppModal";
    import LoadingSpinner from "../components/common/Loading";
    import BoxApp from "../components/development/BoxApp";
    export default {
        name: 'DevelopmentPage',
        components: {BoxApp, LoadingSpinner, FormAppModal},
        data() {
            return {
                loading_applications: false,
				applications: [],
                show_app_modal: false
			}
        },
        methods: {
            init() {
				this.loadApplications();
            },
            closeAppModal(e) {
                if(e !== null) {
                    this.loading_applications = true;
                    // inserimento app
                    this.service.applications.create(e.name, e.categories)
						.then(res => {
						    alert(this.$t('development.app.created'));
                            console.log(res);
						})
						.finally(() => {
						    this.loadApplications();
						})
				}
                this.show_app_modal = false;
			},
			loadApplications() {
				this.loading_applications = true;
				this.applications = [];
				this.service.applications.list()
					.then(apps => {
					    this.applications = {...apps}
					})
					.finally(() => {
					    this.loading_applications = false;
					})
			}
        },
        mounted() {
            this.init();
        }
    }
</script>

<style lang="scss">
	@import '~@/assets/scss/pages/_development';
</style>

<template>
  <transition name="campaign.modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container modal-container-big">
          <div class="modal-header">
            <slot name="header">
              <h3 v-if="campaign.name">{{ campaign.name }}</h3>
            </slot>
          </div>
          <div class="modal-body">
            <slot name="body">
              <div v-if="!loading" class="first-row row">
                <div class="campaign--data">
                  <!-- Stategia -->
                  <span class="campaign--data--info">
										<small class="campaign--data--info--title">
											{{ $t("campaigns.modal.strategy") }}
										</small>
										<span class="campaign--data--info--label">
											{{ $t(`campaigns.strategies[${campaign.strategy}]`) }}
										</span>
									</span>

                  <!-- Nummero notifiche -->
                  <!-- span class="campaign--data--info">
                    <small class="campaign--data--info--title">
                      {{ $t("campaigns.modal.notifications") }}
                    </small>
                    <span class="campaign--data--info--label">
                      {{ campaign.notifications }}
                    </span>
                  </span -->

                  <!-- frequenza invio -->
                  <!-- span class="campaign--data--info">
                    <small class="campaign--data--info--title">
                      {{ $t("campaigns.modal.frequency") }}
                    </small>
                    <span class="campaign--data--info--label">
                      {{
                        $tc("common.time.title", campaign.frequency_unit, {
                          count: campaign.frequency_unit,
                        })
                      }}
                      <span v-if="campaign.frequency_type">
                        {{
                          $t(
                            `common.time.type.${getFrequnecyType(
                              campaign.frequency_type
                            )}`
                          )
                        }}
                      </span>
                    </span>
                  </span -->

                  <!-- Note -->
                  <!-- <span class="campaign--data--info note">
                    <small class="campaign--data--info--title">
                      {{ $t('campaigns.modal.note') }}
                    </small>
                    <span class="campaign--data--info--label">

                    </span>
                  </span> -->

                  <!-- Revenue -->
                  <span class="campaign--data--info revenue">
										<small class="campaign--data--info--title">
											{{ $t("campaigns.modal.revenue_type") }} <!--({{
                        campaign.optins[0].type
                      }})-->
										</small>
                    <!-- div class="row">
                      <div class="col full">
                        <div class="col three-quarters-campaign"></div>
                        <div class="col one-quarters-campaign">
                          <div class="row">
                            <div class="col half text-center svg-icon">
                              <svg viewBox="0 0 65 80">
                                <path
                                    d="M35.863 6.961C41.102.33 48.385 0 48.385 0s1.084 6.513-4.121 12.788c-5.557 6.699-11.873 5.603-11.873 5.603s-1.186-5.269 3.472-11.43ZM33.57 22.954c2.182 0 7.184-3.717 13.695-3.717 11.207 0 15.616 8.001 15.616 8.001s-8.623 4.423-8.623 15.157C54.258 54.503 65 58.676 65 58.676s-7.509 21.206-17.652 21.206c-4.658 0-7.668-3.15-13.188-3.15-5.002 0-9.966 3.267-13.198 3.267C11.7 79.999 0 59.884 0 43.714 0 27.805 9.903 19.46 19.193 19.46c6.038 0 10.725 3.494 14.377 3.494Z"
                                />
                              </svg>
                            </div>
                            <div class="col half svg-icon">
                              <svg viewBox="0 0 67 81">
                                <path
                                    d="M9.81 51.313c0 3.211-2.208 5.84-4.904 5.84-2.697 0-4.906-2.626-4.906-5.84V32.197c0-3.211 2.207-5.84 4.906-5.84s4.904 2.629 4.904 5.84v19.116Zm57.19 0c0 3.211-2.208 5.84-4.07 5.84-3.536 0-5.743-2.626-5.743-5.84V32.197c0-3.211 2.207-5.84 5.743-5.84 1.862 0 4.07 2.629 4.07 5.84v19.116ZM44.224 7.881l3.147-5.726c.258-.467.181-1.013-.167-1.209-.348-.196-.847.024-1.102.491l-3.22 5.859c-3.024-1.192-6.243-1.798-9.488-1.786-3.426 0-6.666.653-9.555 1.815L20.604 1.44c-.258-.468-.755-.69-1.103-.491-.347.198.259.741-.167 1.209l3.163 5.754c-6.581 3.548-11.013 9.016-11.013 15.758 0 .684.049 1.63.136 2.026h43.55c.09-.396.137-1.34.137-2.026 0-6.763-4.461-11.77-11.078-15.786l-.005-.003Zm-20.777 9.995c-1.058 0-1.916-.176-1.917-1.942 0-1.073.858-1.943 1.916-1.943h.001c1.058 0 1.916.87 1.916 1.943-.001 1.666-.858 1.942-1.916 1.942Zm19.997 0c-1.059 0-1.917-.176-1.917-1.942-.001-1.073.857-1.943 1.915-1.943h.002c1.058 0 1.916.87 1.915 1.943 0 1.666-.858 1.942-1.915 1.942Zm-31.965 9.749v32.562h.018c.224 2.75 2.719 4.935 5.743 4.935h32.3c3.021 0 6.03-2.185 5.743-4.935h.018V27.625H11.479ZM30.254 64.96v10.38c0 2.905-1.784 5.534-4.906 5.534-2.699 0-4.904-2.629-4.904-5.534V64.968l9.81-.008Zm16.35-.005V75.34c0 2.905-2.207 5.534-4.907 5.534-2.699 0-4.906-2.629-4.906-5.534V64.955h9.813Z"
                                />
                              </svg>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div -->
										<div class="row revenue">
											<div
                          class="col full"
                          v-for="(o, index) in campaign.optins"
                          :key="index"
                      >
												<div class="col text_small three-quarters-campaign">
													<strong>{{
                              o.application_id | application_name(apps)
                            }}</strong>
												</div>
                        <!--	<div class="col one-quarters-campaign">
                              <div class="row">
                                <div class="col text_small half text-center">
                                  &lt;!&ndash;{{ o.value_ios }} &euro;&ndash;&gt;
                                </div>
                                <div class="col text_small half text-center">
                                  &lt;!&ndash;{{ o.value_android }} &euro;&ndash;&gt;
                                </div>
                              </div>
                            </div>-->
											</div>
										</div>
									</span>
                </div>
                <!-- <div class="col half">
                  <div class="row ">
                    <div class="col half">
                      <div class="panel full panel__right_margin">
                        <h2>{{ $t('campaigns.modal.strategy') }}</h2>
                        <p>
                          {{ $t(`campaigns.strategies[${campaign.strategy}]`) }}
                        </p>
                      </div>
                    </div>
                    <div class="col half">
                      <div class="panel full panel__right_margin">
                        <h2>{{ $t('campaigns.modal.notifications') }}</h2>
                        <p>
                          {{ campaign.notifications }}
                        </p>
                      </div>
                    </div>
                  </div>

                  <div class="row panel__top_margin">
                    <div class="col half">
                      <div class="panel full panel__right_margin">
                        <h2>{{ $t('campaigns.modal.frequency') }}</h2>
                        <p>
                          {{ $tc('common.time.title', campaign.frequency_unit, { count:
                          campaign.frequency_unit }) }}
                          <span v-if="campaign.frequency_type">
                            {{ $t(`common.time.type.${getFrequnecyType(campaign.frequency_type)}`) }}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div class="col half">
                      <div class="panel full panel__right_margin">
                        <h2>{{ $t('campaigns.modal.note') }}</h2>
                      </div>
                    </div>
                  </div>

                  <div class="row ">
                    <div class="panel panel__top_margin col full panel__right_margin"
                       v-if="campaign.optins">
                      <h2>{{ $t('campaigns.modal.revenue_type') }} ({{ campaign.optins[0].type
                        }})</h2>
                      <div class="row">
                        <div class="col full">
                          <div class="col three-quarters-campaign">
                          </div>
                          <div class="col one-quarters-campaign">
                            <div class="row">
                              <div class="col half text-center">
                                <strong>iOS</strong>
                              </div>
                              <div class="col half">
                                <strong>Android</strong>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row revenue">

                        <div class="col full" v-for="(o, index) in campaign.optins"
                           :key="index">
                          <div class="col text_small three-quarters-campaign">
                            {{ o.applicationId | application_name(apps)}}
                          </div>
                          <div class="col one-quarters-campaign">
                            <div class="row">
                              <div class="col text_small half">
                                {{ o.value_ios }} &euro;
                              </div>
                              <div class="col text_small half">
                                {{ o.value_android }} &euro;
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div> -->
                <div class="campaign--phones scoped">
                  <div class="phone-box">
                    <div class="phone">
                      <img alt="" src="@images/phone/base_phone.png"/>
                      <div class="phone__safari">
                        <img alt="" src="@images/phone/phone_lock.png"/>
                      </div>
                      <div class="phone__lock_iframe">
                        <div class="phone__lock_iframe__title">
                          {{ campaign.brand }}
                        </div>
                        <div class="phone__lock_iframe__content">
                          {{ campaign.message }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="phone-box">
                    <div class="phone">
                      <img alt="" src="@images/phone/base_phone.png"/>
                      <div class="phone__safari">
                        <img alt="" src="@images/phone/phone_safari.png"/>
                      </div>
                      <div class="phone__safari_iframe">
                        <iframe
                            :src="campaign.content"
                            frameborder="0"
                            title=""
                        ></iframe>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <div class="col half">
                  <div class="row">
                    <div class="col half">
                      <div class="phone">
                        <img src="@images/phone/base_phone.png">
                        <div class="phone__safari">
                          <img src="@images/phone/phone_lock.png">
                        </div>
                        <div class="phone__lock_iframe">
                          <div class="phone__lock_iframe__title">
                            {{ campaign.brand }}
                          </div>
                          <div class="phone__lock_iframe__content">
                            {{ campaign.message }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col half">
                      <div class="phone">
                        <img src="@images/phone/base_phone.png">
                        <div class="phone__safari">
                          <img src="@images/phone/phone_safari.png">
                        </div>
                        <div class="phone__safari_iframe">
                          <iframe :src="campaign.content" frameborder="0"></iframe>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> -->
              </div>
              <div v-if="loading" class="first-row row">
                {{ $t("common.loading") }}
              </div>
            </slot>
          </div>
          <div class="modal-footer">
            <slot name="footer">
              <button type="button" class="btn big" @click="$emit('close')">
                <span>{{ $t("common.close") }}</span>
              </button>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
export default {
  name: "CampaignModal",
  props: {
    campaign: {
      required: true,
    },
  },
  data() {
    return {
      apps: [],
      loading: true,
    };
  },
  mounted() {
    this.service.applications
        .list()
        .then((apps) => {
          this.apps = apps;
        })
        .finally(() => {
          this.loading = false;
        });
  },
  methods: {
    getFrequnecyType(f) {
      return f ? f.trim().toLowerCase() : null;
    },
  },
};
</script>
<style lang="scss">
.revenue {
  max-height: 10vh;
  overflow: scroll;
}

.phone {
  position: relative;
  display: block;
  width: 100%;
  height: 0;
  padding-bottom: 205.5%;
  margin: 0 auto;

  img {
    display: block;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
  }

  &__safari {
    position: absolute;
    top: 0;
    left: 0;
    @include size(100%, 100%);
    z-index: 1;

    img {
      display: block;
      width: 100%;
    }
  }

  &__safari_iframe {
    position: absolute;
    top: 13%;
    left: 4%;
    @include size(92%, 76%);
    z-index: 2;

    iframe {
      display: block;
      @include size(100%, 100%);
    }
  }

  &__lock_iframe {
    position: absolute;
    top: 33%;
    left: 7%;
    @include size(86%, auto);
    z-index: 2;

    background: #bfb7b3;
    opacity: 0.9;
    border-radius: 10px;

    padding: 2%;

    &__title {
      font-weight: 700;
      color: $black;
      font-size: 12px;
    }

    &__content {
      margin-top: 10px;
      color: $black;
      font-size: 12px;
      line-height: 14px;
    }
  }
}

.text-center {
  text-align: center;
  justify-content: center;
}

.svg-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;

  svg {
    display: block;
    width: 80%;
    height: 80%;
    fill: $grey;
    margin: 0 auto;
  }
}

.campaign {
  &--phones {
    display: flex;
    justify-content: space-around;

    &.scoped {
      width: 60%;
    }

    .phone-box {
      display: block;
      width: 40%;
    }
  }

  &--data {
    display: block;
    width: 40%;
    padding-right: 30px;

    &--info {
      display: block;
      width: 100%;
      margin-bottom: 30px;

      &--title,
      &--label {
        display: block;
        width: 100%;
      }

      &--title {
        text-transform: uppercase;
        font-size: 0.8rem;
      }

      &--label {
        font-weight: 700;
        font-size: 1.3rem;
      }

      &.note {
        .campaign--label {
          font-size: 0.9rem;
          font-weight: 400;
        }
      }

      strong {
        font-size: 1rem;
      }
    }
  }
}
</style>

<template>
    <nav class="dashboard--menu main-menu" role="navigation">
        <router-link :to="{ name: 'dashboard.page' }" class="menu-link" :class="{'active': is('')}">
            <span class="menu-link--icon">
                <svg viewBox="0 0 40 40">
                    <use xlink:href="#icons--overview" width="100%" height="100%"></use>
                </svg>
            </span> <span class="menu-link--label">
                {{$t('menu.overview')}}
            </span>
        </router-link>
        <!--<router-link :to="{ name: 'realtime.page' }" class="menu-link" :class="{'active': is('realtime')}">
            <span class="menu-link--icon">
                <svg viewBox="0 0 40 40">
                    <use xlink:href="#icons--realtime" width="100%" height="100%"></use>
                </svg>
            </span> <span class="menu-link--label">
                {{$t('menu.realtime')}}
            </span>
        </router-link>-->
        <router-link :to="{ name: 'notifications.page' }" class="menu-link" :class="{'active': is('notifications')}">
            <span class="menu-link--icon">
                <svg viewBox="0 0 40 40">
                    <use xlink:href="#icons--notifications" width="100%" height="100%"></use>
                </svg>
            </span> <span class="menu-link--label">
                {{$t('menu.notifications')}}
            </span>
        </router-link>
        <hr />
        <router-link :to="{ name: 'campaigns.page' }" class="menu-link" :class="{'active': is('campaigns')}">
            <span class="menu-link--icon">
                <svg viewBox="0 0 40 40">
                    <use xlink:href="#icons--campaigns" width="100%" height="100%"></use>
                </svg>
            </span> <span class="menu-link--label">
                {{$t('menu.campaigns')}}
            </span>
        </router-link>
        <router-link :to="{ name: 'report.page' }" class="menu-link" :class="{'active': is('report')}">
            <span class="menu-link--icon">
                <svg viewBox="0 0 40 40">
                    <use xlink:href="#icons--reports" width="100%" height="100%"></use>
                </svg>
            </span> <span class="menu-link--label">
                {{$t('menu.report')}}
            </span>
        </router-link>
        <router-link :to="{ name: 'payments.page' }" class="menu-link"  :class="{'active': is('payments')}">
            <span class="menu-link--icon">
                <svg viewBox="0 0 40 40">
                    <use xlink:href="#icons--payments" width="100%" height="100%"></use>
                </svg>
            </span>
            <span class="menu-link--label">
                {{$t('menu.payments')}}
            </span>
        </router-link>
        <hr />
        <router-link :to="{ name: 'development.page' }" class="menu-link"  :class="{'active': is('development')}">
            <span class="menu-link--icon">
                <svg viewBox="0 0 40 40">
                    <use xlink:href="#icons--development" width="100%" height="100%"></use>
                </svg>
            </span>
            <span class="menu-link--label">
                {{$t('menu.development')}}
            </span>
        </router-link>
        <hr />
        <!--<router-link to="/" class="menu-link">
            <span class="menu-link--icon">
                <svg viewBox="0 0 40 40">
                    <use xlink:href="#icons--realtime" width="100%" height="100%"></use>
                </svg>
            </span>
            <span class="menu-link--label">
                Real Time
            </span>
        </router-link>
        <hr />
        <router-link to="/" class="menu-link">
            <span class="menu-link--icon">
                <svg viewBox="0 0 40 40">
                    <use xlink:href="#icons--notifications" width="100%" height="100%"></use>
                </svg>
            </span>
            <span class="menu-link--label">
                Notifiche
            </span>
        </router-link>
        <router-link to="/" class="menu-link">
            <span class="menu-link--icon">
                <svg viewBox="0 0 40 40">
                    <use xlink:href="#icons--reports" width="100%" height="100%"></use>
                </svg>
            </span>
            <span class="menu-link--label">
                Report
            </span>
        </router-link>
        <router-link to="/" class="menu-link">
            <span class="menu-link--icon">
                <svg viewBox="0 0 40 40">
                    <use xlink:href="#icons--payments" width="100%" height="100%"></use>
                </svg>
            </span>
            <span class="menu-link--label">
                Pagamenti
            </span>
        </router-link>
        <router-link to="/" class="menu-link">
            <span class="menu-link--icon">
                <svg viewBox="0 0 40 40">
                    <use xlink:href="#icons--documents" width="100%" height="100%"></use>
                </svg>
            </span>
            <span class="menu-link--label">
                Documenti
            </span>
        </router-link>
        <hr />
        <router-link to="/" class="menu-link">
            <span class="menu-link--icon">
                <svg viewBox="0 0 40 40">
                    <use xlink:href="#icons--development" width="100%" height="100%"></use>
                </svg>
            </span>
            <span class="menu-link--label">
                Sviluppo
            </span>
        </router-link>
        <hr />
        <router-link to="/" class="menu-link">
            <span class="menu-link--icon">
                <svg viewBox="0 0 40 40">
                    <use xlink:href="#icons--faq" width="100%" height="100%"></use>
                </svg>
            </span>
            <span class="menu-link--label">
                FAQ
            </span>
        </router-link>-->
    </nav>
</template>
<script>
    export default {
        name: 'Menu',
        methods: {
            is(page) {
                let path = this.$route.path;
                path = path.replace('/app/', '');
                path = path.replace('/app', '');
                path = path.toLowerCase().trim();
                page = page.toLowerCase().trim();
                if(path == page) {
                    return true;
                }
                return false;
            }
        }
    }
</script>
<style lang="scss">
    .main-menu {
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        .menu-link {
            display: flex;
            @extend %flexCenterStart;
            @include size(100%, var(--menu-link));
            padding: 0 var(--gutter);
            margin: 3px 0;
            position: relative;

            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                background: $orange;
                @include size(3px, 100%);
                transform: scaleX(0);
                transform-origin: left center;
                transition: 400ms $cubic;
            }

            &--icon {
                display: flex;
                @extend %flexCenterStart;
                @include size(var(--icon), var(--icon));

                svg {
                    @include size(60%, 60%);
                    fill: $grey;
                }
            }

            &--label {
                width: calc(100% - var(--icon));
                font-size: 0.8em;
                color: $dark_grey;
            }

            &:hover {
                &:before {
                    transform: scaleX(1);
                }
            }

            &.active {
                &:before {
                    transform: scaleX(1);
                }

                .menu-link {
                    &--icon {
                        svg {
                            fill: $orange;
                        }
                    }

                    &--label {
                        color: $orange;
                    }
                }
            }
        }

        hr {
            display: block;
            background: $grey;
            @include size(calc(100% - calc(var(--gutter)) * 2), 1px);
            margin: 0 auto;
            box-shadow: 0;
            border: 0;
        }
    }
</style>

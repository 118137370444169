<template>
	<div class="col third pointer" @click="go(app.id)">
		<div class="panel row panel__bottom_margin">
			<div class="col one-quarters">
				<AppLogoImage :image-url="app.icon" :app-id="app.id"></AppLogoImage>
			</div>
			<div class="col half">
				<h4>{{ app.name }}</h4>
				<p style="line-height: 2em;">
					<strong>{{ $t('development.app.created_by') }}</strong>
					<span v-if="app.created_by != null">{{app.created_by.first_name}} {{app.created_by.last_name}}</span><br>
					<strong>{{ $t('development.app.app_key') }}:</strong>
					{{app.id}}<br>
				</p>
				<AppPlatformIcons :app="app"></AppPlatformIcons>
			</div>

		</div>
	</div>
</template>
<script>
    import AppPlatformIcons from "./AppPlatformIcons";
	import AppLogoImage from "@components/development/AppLogoImage";

    export default {
        name: 'BoxApp',
        components: {AppLogoImage, AppPlatformIcons},
        props: {
            app: {
                type: Object,
                required: true
            }
        },
        methods: {
            go(appId) {
                this.$router.push({
                    name: 'development.detail.page',
                    params: {id: appId}
                })
            }
        }
    }
</script>

<style lang="scss" scoped>
</style>

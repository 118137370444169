<template>
	<div class="login-form panel form">
		<form-logo></form-logo>
		<form
			class="form"
			autocomplete="off"
			@submit.prevent="submitForm"
			v-if="publishers.length <= 0"
		>
			<div v-if="error" class="error error--general">
				{{ error }}
			</div>
			<div class="fieldset icon">
				<span class="icon">
					<svg viewBox="0 0 93 70">
						<use xlink:href="#icons--mail" width="100%" height="100%"></use>
					</svg>
				</span>
				<input
					type="text"
					name="email"
					:placeholder="$t('form.login.email')"
					class="input"
					autocomplete="off"
					v-model.trim="$v.login.email.$model"
				/>
			</div>
			<div v-if="$v.login.email.$invalid" class="error error--form">
				<span v-if="submitted && !$v.login.email.required">
					{{ $t("form.login.errors.email_required") }}
				</span>
				<span v-if="submitted && !$v.login.email.email">
					{{ $t("form.login.errors.email") }}
				</span>
			</div>
			<div class="fieldset icon">
				<span class="icon">
					<svg viewBox="0 0 69 69">
						<use xlink:href="#icons--key" width="100%" height="100%"></use>
					</svg>
				</span>
				<input
					type="password"
					name="password"
					:placeholder="$t('form.login.password')"
					class="input"
					autocomplete="off"
					v-model.trim="$v.login.password.$model"
				/>
			</div>
			<div v-if="$v.login.password.$invalid" class="error error--form">
				<span v-if="submitted && !$v.login.password.required">
					{{ $t("form.login.errors.password_required") }}
				</span>
			</div>
			<button type="submit" :disabled="loading" class="btn big full">
				<span v-if="loading">{{ $t("common.loading") }}</span>
				<span v-if="!loading">{{ $t("form.login.sign_in") }}</span>
			</button>
			<div class="form-utility" style="width: 100%">
				<label class="checkbox">
					<!--<input type="checkbox" id="ricordami" class="checkbox-input"/><span class="checkbox-fake"></span>
					<span class="checkbox-label">
					 	{{ $t('form.login.remember_me') }}
				 	</span>--></label
				>
				<router-link
					:to="{ name: 'password.request.reset.page' }"
					class="change-password"
				>
					{{ $t("form.login.forgotten_password") }}
				</router-link>
			</div>
		</form>

		<!-- una volta fatta l'autenticazione scelgo il publisher da utilizzare -->
		<form
			class="form"
			autocomplete="off"
			@submit.prevent="submitFormPublisher"
			v-if="publishers.length > 1"
		>
			<div v-if="error" class="error error--general">
				{{ error }}
			</div>
			<div class="">
				<span class="text">{{
					$t("form.login.publisher.selectpublisher")
				}}</span>
			</div>
			<div class="fieldset icon">
				<select
					class="input select"
					name="publisher"
					v-model.trim="$v.selection.publisher.$model"
				>
					<option
						:value="p.publisher.id"
						v-for="p in publishers"
						v-bind:key="p.publisher.id"
					>
						{{ p.publisher.name }}
						<span v-if="p.publisher.address">
							- {{ p.publisher.address }}
						</span>
						<span v-if="p.publisher.city">, {{ p.publisher.city }} </span>
					</option>
				</select>
			</div>
			<button type="submit" :disabled="loading" class="btn big full">
				<span v-if="loading">{{ $t("common.loading") }}</span>
				<span v-if="!loading">{{ $t("common.select") }}</span>
			</button>
		</form>
	</div>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import { email } from "vuelidate/lib/validators";
import FormLogo from "../common/FormLogo";
import moment from "moment";

export default {
	name: "LoginForm",
	components: { FormLogo },
	data() {
		// pulisco la local storage
		localStorage.removeItem("token");
		return {
			submitted: false,
			error: "",
			loading: false,
			publishers: [],
			login: {
				email: "",
				password: "",
			},
			selection: {
				publisher: {},
			},
		};
	},
	validations: {
		login: {
			email: {
				required,
				email,
			},
			password: {
				required,
			},
		},
		selection: {
			publisher: {
				required,
			},
		},
	},
	methods: {
		selectPublisher(publisher) {
			localStorage.setItem("publisher", publisher);
			let toPath = "/app";
			if (this.$route.query.redirectTo) {
				toPath = this.$route.query.redirectTo;
			}
			this.service.applications.list().then((apps) => {
				let tmpApps = [];
				let tmpNamesApps = [];
				apps.map((a) => {
					tmpApps.push(a.id);
					tmpNamesApps.push(a.name);
				});
				localStorage.setItem("selected_apps", JSON.stringify(tmpApps));
				localStorage.setItem("selected_apps_name", JSON.stringify(tmpNamesApps));
				localStorage.setItem(
					"selected_time_period_from",
					moment(new Date()).subtract(30, "d").startOf("day").valueOf()
				);
				localStorage.setItem(
					"selected_time_period_to",
					moment(new Date()).endOf("day").endOf("day").valueOf()
				);
				localStorage.setItem("selected_time_period_keys", 30);
				this.$router.push(toPath).catch(() => {});
			});
		},
		submitForm() {
			this.$v.login.$touch();
			this.submitted = true;
			this.error = "";
			this.loading = true;
			if (!this.$v.login.$invalid) {
				this.api
					.login(this.login.email, this.login.password)
					.then((dataLogin) => {
						// se ha un solo elemento vado direttamente
						// alla dashboard
						if (dataLogin.publishers.length == 1) {
							this.selectPublisher(dataLogin.publishers[0].publisher.id);
						}
						this.publishers = dataLogin.publishers;
						this.submitted = false;
						this.selection.publisher = dataLogin.publishers[0].publisher.id;
					})
					.catch((err) => {
						let errorMessage = `form.login.errors.${err.message}`;
						if (err.message == "api-general-error") {
							errorMessage = `errors.api-general-error`;
						}
						this.error = this.$t(errorMessage);
						this.submitted = false;
						this.login.password = "";
					})
					.finally(() => {
						this.loading = false;
					});
			} else {
				this.loading = false;
			}
		},
		submitFormPublisher() {
			this.$v.$touch();
			this.submitted = true;
			this.error = "";
			this.loading = true;
			if (!this.$v.selection.$invalid) {
				this.selectPublisher(this.selection.publisher);
			} else {
				this.loading = false;
			}
		},
	},
};
</script>
<style lang="scss"></style>

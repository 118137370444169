<template>
	<div class="map_width">
		<div class="row">
			<div class="col half">
				<span class="switch--box" :class="{ 'is-location': check == false }">
					<span class="switch--label" @click="check = false">
						{{ $t("realtime.locations.title") }}
					</span>
					<label class="switch">
						<input type="checkbox" v-model="check" />
						<span class="slider round"></span>
					</label>
					<span class="switch--label" @click="check = true">
						{{ $t("realtime.notifications.title") }}
					</span>
				</span>
			</div>
			<!-- <div class="col half">
				{{ $t('realtime.locations.title') }}
				<span class="panel__right_margin"></span>
				<label class="switch">
					<input type="checkbox" v-model="check.locations">
					<span class="slider round"></span>
				</label>
				<span class="panel__right_margin"></span>
				<span class="panel__right_margin"></span>
				<span class="panel__right_margin"></span>
				<span class="panel__right_margin"></span>

				{{ $t('realtime.notifications.title') }}
				<span class="panel__right_margin"></span>
				<label class="switch">
					<input type="checkbox" v-model="check.notifications">
					<span class="slider slider_blue round"></span>
				</label>
				<br>
				<br>
			</div> -->
		</div>
		<!--<div v-bind:key="index" v-for="(l, index) in data.locations">
			{{+l.lat }}, {{+l.lon }}
		</div>-->

		<div class="map_container">
			<GmapMap
				:clickable="clickable"
				:draggable="draggable"
				:options="options"
				ref="mapRef"
				style="width: 100%; height: 900px"
				:zoom="zoom"
				@dragend="updateData()"
				:center="{ lat: center.lat, lng: center.lon }"
			>
				<div v-if="!check">
					<div v-bind:key="index" v-for="(l, index) in data.locations">
						<gmap-circle
							:center="{ lat: +l.lat, lng: +l.lon }"
							:radius="l.radius"
							:options="{
								editable: false,
								strokeColor: '#ed7d31',
								strokeOpacity: 0.3,
								strokeWeight: 20,
								fillColor: '#ed7d31',
								fillOpacity: 0.9,
							}"
						>
						</gmap-circle>
						<gmap-custom-marker
							class="bubble"
							:marker="{ lat: +l.lat - 0.07, lng: +l.lon }"
						>
							<div :style="{ color: 'white' }">{{ l.count | number }}</div>
							<!--<img src="@images/marker.png" />
							<div :style="{ 'display': `block`, color: 'white', 'font-size': `${l.font_size}rem`}">
								{{ l.count | number }}
							</div>-->
						</gmap-custom-marker>
					</div>
				</div>

				<div v-if="check">
					<div v-bind:key="index" v-for="(l, index) in data.notifications">
						<gmap-circle
							:center="{ lat: +l.lat, lng: +l.lon }"
							:radius="l.radius"
							:options="{
								editable: false,
								strokeColor: '#0098A1',
								strokeOpacity: 0.3,
								strokeWeight: 20,
								fillColor: '#0098A1',
								fillOpacity: 0.9,
							}"
						>
						</gmap-circle>
						<gmap-custom-marker
							class="bubble"
							:marker="{ lat: +l.lat, lng: +l.lon }"
						>
							<div :style="{ color: 'white' }">{{ l.count | number }}</div>
							<img alt="" src="@images/marker.png" />
							<!--<span :style="{ color: 'white', 'font-size': `${l.font_size}rem` }"
							>{{ l.count | number }}</span>-->
						</gmap-custom-marker>
					</div>
				</div>
			</GmapMap>

			<div class="zoom_buttons">
				<div class="zoom_buttons__button" @click="updateZoom(1)">+</div>
				<div class="zoom_buttons__button" @click="updateZoom(-1)">-</div>
			</div>
		</div>
	</div>
</template>
<script>
import GmapCustomMarker from "vue2-gmap-custom-marker";

export default {
	name: "RealTimeGraph",
	components: {
		"gmap-custom-marker": GmapCustomMarker,
	},
	data() {
		return {
			apps: [],
			map: null,
			clickable: false,
			draggable: true,
			zoom: 7,
			center: {
				lat: 41.89193,
				lon: 12.51133,
			},
			data: {
				locations: [],
				notifications: [],
			},
			check: false,
			// check: {
			// 	locations: true,
			// 	notifications: false
			// },
			options: {
				zoomControl: false,
				scrollwheel: false,
				mapTypeControl: false,
				scaleControl: false,
				streetViewControl: false,
				rotateControl: false,
				disableDoubleClickZoom: true,
				fullscreenControl: false,
				disableDefaultUi: false,
			},
			timer: "",
		};
	},
	watch: {
		// 'check.locations': {
		// 	handler () {
		// 		this.check.notifications = !this.check.locations;
		// 	},
		// 	deep: true
		// },
		// 'check.notifications': {
		// 	handler () {
		// 		this.check.locations = !this.check.notifications;
		// 	},
		// 	deep: true
		// }
	},
	mounted() {
		this.$refs.mapRef.$mapPromise.then((map) => {
			this.map = map;
			setTimeout(() => {
				this.init();
				this.updateData();
			}, 500);
		});
		this.$root.$on("filters_updated", () => {
			if (this.$router.currentRoute.name == "realtime.page") {
				this.init();
				this.updateData();
			}
		});

		this.timer = setInterval(() => {
			if (this.$router.currentRoute.name == "realtime.page") {
				this.updateData();
			} else {
				clearInterval(this.timer);
			}
		}, 10000999999);
	},
	methods: {
		createDataFromGraph(data) {
			return new Promise((resolve) => {
				let result = [];

				// prendo il massimo e il minimo
				let max = Number.NEGATIVE_INFINITY;
				let min = Number.POSITIVE_INFINITY;

				let bubble_min = 1900; //20000; zoom=6
				let bubble_max = 6000; //150000;
				let font_size_max = 3.8; // 2.4;
				let font_size_min = 0.7; //0.7;
				//let y_position_fi_max = 0.007; //0.3;
				//let y_position_fi_min = 0.002; //0.1;

				data.map((d) => {
					if (+d.count > max) max = +d.count;
					if (+d.count < min) min = +d.count;
				});

				this.$refs.mapRef.$mapPromise.then(() => {
					data.map((d) => {
						let fi = (+d.count / max) * 100;
						let radius = (+d.count * bubble_max) / max;
						radius = radius < bubble_min ? bubble_min : radius;
						let font_size = (+d.count * font_size_max) / max;
						font_size = font_size < font_size_min ? font_size_min : font_size;

						//let y_position_fi = ((+d.count) * y_position_fi_max) / max;
						//y_position_fi = (y_position_fi < y_position_fi_min) ? y_position_fi_min : y_position_fi;

						let zoomFi = 13;
						if (this.zoom == 11) zoomFi = 0.1;
						if (this.zoom == 10) zoomFi = 2;
						if (this.zoom == 9) zoomFi = 4;
						if (this.zoom == 8) zoomFi = 8;
						if (this.zoom == 7) zoomFi = 14;
						if (this.zoom == 6) zoomFi = 20;
						if (this.zoom == 5) zoomFi = 40;
						if (this.zoom == 4) zoomFi = 60;

						result.push({
							radius: radius + radius * zoomFi,
							lat: d.lat,
							lon: d.lon,
							count: d.count,
							fi: fi,
							font_size: font_size,
							y_position_fi: (radius / 100) * 8,
							z: this.zoom,
						});
					});
					resolve(result);
				});
			});
		},
		init() {
			this.apps = localStorage.getItem("selected_apps");
			try {
				this.apps = JSON.parse(this.apps);
			} catch (e) {
				this.apps = [];
			}
		},
		updateData() {
			if (this.map) {
				let data = {
					top_left: {
						lat: this.map.getBounds().getNorthEast().lat(),
						lon: this.map.getBounds().getSouthWest().lng(),
					},
					bottom_right: {
						lat: this.map.getBounds().getSouthWest().lat(),
						lon: this.map.getBounds().getNorthEast().lng(),
					},
					zoom: this.zoom,
					api_keys: this.apps,
				};

				this.service.statistics.realtime
					.geogrid(data.top_left, data.bottom_right, data.zoom, data.api_keys)
					.then((res) => {
						this.data.locations = [];
						this.data.notifications = [];

						this.createDataFromGraph(res.locations).then((data) => {
							this.data.locations = data;

							this.createDataFromGraph(res.notifications).then((data) => {
								this.data.notifications = data;

								// calculate center by locations
								let minX, maxX, minY, maxY;
								for (var i = 0; i < this.data.locations.length; i++) {
									minX =
										this.data.locations[i].lat < minX || minX == null
											? this.data.locations[i].lat
											: minX;
									maxX =
										this.data.locations[i].lat > maxX || maxX == null
											? this.data.locations[i].lat
											: maxX;
									minY =
										this.data.locations[i].lon < minY || minY == null
											? this.data.locations[i].lon
											: minY;
									maxY =
										this.data.locations[i].lon > maxY || maxY == null
											? this.data.locations[i].lon
											: maxY;
								}
								this.center.lat = (minX + maxX) / 2;
								this.center.lon = (minY + maxY) / 2;
							});
						});
					})
					.catch((err) => {
						console.log(err);
					})
					.finally(() => {});
			}
		},
		updateZoom(z) {
			if (this.zoom <= 4 && z == -1) z = 0;
			if (this.zoom >= 12 && z == 1) z = 0;
			if (z != 0) {
				this.zoom = this.zoom + z;
				this.updateData();
			}
		},
		beforeDestroy() {
			clearInterval(this.timer);
		},
	},
};
</script>
<style>
.r {
	flex-direction: row-reverse;
}

.map_width {
	width: 100% !important;
}

.bubble div {
	font-size: 20px;
	margin-top: -10px;
	margin-left: -5px;
}

.zoom_buttons {
	position: relative;
	float: right;
	margin-top: -130px;
	margin-right: 20px;
}

.zoom_buttons__button {
	float: left;
	width: 100%;
	height: 50px;
	background: white;
	line-height: 50px;
	font-size: 200%;
	text-align: center;
	margin: 0 3px 5px 3px;
	border-radius: 10px;
	box-shadow: 2px 2px 2px grey;
	cursor: pointer;
}
</style>

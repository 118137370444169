import _ from 'underscore';
import moment from "moment";

export default {
    install: function (Vue, router) {
        let service = {};
        Vue.mixin({
            mounted() {
                /**
                 *
                 * @returns {Promise<unknown>}
                 */
                service.profile = () => {
                    let url = `/public/api/me/profile`;
                    return this.api.get(url);
                };
                /**
                 *
                 * @param data
                 * @returns {Promise<unknown>}
                 */
                service.save_profile = (data) => {
                    let url = `/public/api/me/profile`;
                    return new Promise((resolve, reject) => {
                        this.api
                            .put(url, data)
                            .then((data) => {
                                resolve(data);
                            })
                            .catch((err) => reject(err));
                    });
                };
                /**
                 * Refresh token
                 * @returns {Promise<unknown>}
                 */
                service.get_current_publisher = () => {
                    let publisher_select = localStorage.getItem('publisher');
                    return +publisher_select || 0;
                };
                service.refresh_token = () => {
                    return new Promise((resolve) => {
                        this.api
                            .get('/public/api/me/refresh-token')
                            .then((response) => {
                                let publisher = null;

                                response.publishers.map((p) => {
                                    if (+p.publisher.id == service.get_current_publisher()) {
                                        publisher = p;
                                    }
                                });

                                if (!publisher) {
                                    publisher = response.publishers[0];
                                }

                                resolve({
                                    user: response.user,
                                    role: publisher.role,
                                    publisher: publisher.publisher,
                                });
                            })
                            .catch(() => {
                                router.push('/login');
                            });
                    });
                };
                /**
                 *
                 * @param email
                 * @returns {Promise<unknown>}
                 */
                service.send_request_reset_password = (email) => {
                    return this.api.post('/public/api/auth/password-recovery', {
                        email: email,
                    });
                };
                /**
                 *
                 * @param token
                 * @returns {Promise<unknown>}
                 */
                service.check_token = (token) => {
                    let url = `/public/api/auth/password-recovery/${token}`;
                    return this.api.get(url);
                };
                /**
                 * Change password
                 * @param email
                 * @returns {Promise<unknown>}
                 */
                service.change_password_by_token = (token, password, repeat) => {
                    let url = `/public/api/auth/password-recovery/${token}/complete`;
                    return this.api.post(url, {
                        password: password,
                        confirm: repeat,
                    });
                };
                service.publisher = {};
                /**
                 *
                 * @returns {Promise<unknown>}
                 */
                service.publisher.profile = () => {
                    let publisher_id = service.get_current_publisher();
                    let url = `/public/api/publishers/${publisher_id}`;
                    return this.api.get(url);
                };
                /**
                 *
                 * @param data
                 * @returns {Promise<unknown>}
                 */
                service.publisher.save_profile = (data) => {
                    let publisher_id = service.get_current_publisher();
                    let url = `/public/api/publishers/${publisher_id}`;
                    return new Promise((resolve, reject) => {
                        this.api
                            .put(url, data)
                            .then((data) => {
                                // sistemo
                                resolve(data);
                            })
                            .catch((err) => reject(err));
                    });
                };
                service.publisher.autoAcceptBrand = (brand) => {
                    const publisherId = service.get_current_publisher();
                    const url = `/public/api/publishers/${publisherId}/brands`;

                    return new Promise((resolve, reject) => {
                        this.api.put(url, {brand: brand})
                            .then(response => resolve(response))
                            .catch(err => reject(err));
                    });
                };
                service.publisher.removeBrand = (brandName) => {
                    const publisherId = service.get_current_publisher();
                    const url = `/public/api/publishers/${publisherId}/brands/remove`;

                    return new Promise((resolve, reject) => {
                        this.api.post(url, {
                            brand: brandName,
                        })
                            .then(response => resolve(response))
                            .catch(err => reject(err));
                    });
                };
                service.publisher.getBrands = () => {
                    const publisherId = service.get_current_publisher();
                    const url = `/public/api/publishers/${publisherId}/brands`;

                    return new Promise((resolve, reject) => {
                        this.api.get(url)
                            .then(response => resolve(response))
                            .catch(err => reject(err));
                    });
                };

                service.invites = {};
                /**
                 *
                 * @returns {Promise<unknown>}
                 */
                service.invites.list = () => {
                    return new Promise((resolve, reject) => {
                        let publisher_id = service.get_current_publisher();
                        this.api
                            .get(`/public/api/publishers/${publisher_id}/invites`)
                            .then((response) => {
                                let result = null;
                                response = response.filter((item) => {
                                    return item.acceptedAt == null;
                                });
                                result = _.chain(response)
                                    .groupBy('role')
                                    .map(function (value, key) {
                                        return {
                                            role: key,
                                            data: value,
                                        };
                                    })
                                    .value();
                                resolve(result);
                            })
                            .catch((err) => {
                                reject(err);
                            });
                    });
                };
                /**
                 *
                 * @param data
                 * @returns {Promise<unknown>}
                 */
                service.invites.send = (data) => {
                    let publisher_id = service.get_current_publisher();
                    let url = `/public/api/publishers/${publisher_id}/invites`;
                    return this.api.post(url, data);
                };
                service.invites.delete = (id) => {
                    let publisher_id = service.get_current_publisher();
                    let url = `/public/api/publishers/${publisher_id}/invites/${id}`;
                    return this.api.delete(url);
                };
                (service.invites.check_token = (token) => {
                    let url = `/public/api/invites/${token}`;
                    return this.api.get(url);
                }),
                    (service.invites.set_password = (token, password, repeat, locale) => {
                        let url = `/public/api/invites/${token}/complete`;
                        return this.api.post(url, {
                            password: password,
                            confirm: repeat,
                            locale: locale,
                        });
                    });

                service.applications = {};
                service.applications.list = () => {
                    return new Promise((resolve, reject) => {
                        let publisher_id = service.get_current_publisher();
                        this.api
                            .get(`/public/api/publishers/${publisher_id}/applications`)
                            .then((response) => {
                                resolve(response);
                            })
                            .catch((err) => {
                                reject(err);
                            });
                    });
                };
                service.applications.single = (appId) => {
                    return new Promise((resolve, reject) => {
                        let publisher_id = service.get_current_publisher();
                        this.api
                            .get(`/public/api/publishers/${publisher_id}/applications`)
                            .then((response) => {
                                if (response && response.length) {
                                    let app = response.filter((r) => {
                                        return r.id == appId;
                                    });
                                    if (app && app.length && app.length == 1) {
                                        resolve(app[0]);
                                    }
                                }
                            })
                            .catch((err) => {
                                reject(err);
                            });
                    });
                };
                service.applications.create = (name, categories) => {
                    let publisher_id = service.get_current_publisher();
                    let params = {
                        name: name,
                        categories: categories,
                    };
                    return this.api.post(
                        `/public/api/publishers/${publisher_id}/applications`,
                        params,
                    );
                };
                service.applications.update = (appId, name, categories) => {
                    let publisher_id = service.get_current_publisher();
                    let params = {
                        name: name,
                        categories: categories,
                    };
                    return this.api.put(
                        `/public/api/publishers/${publisher_id}/applications/${appId}`,
                        params,
                    );
                };
                service.applications.update_icon = (appId, binaryData, filename) => {
                    let publisher_id = service.get_current_publisher();
                    return this.api.putFile(
                        `/public/api/publishers/${publisher_id}/applications/${appId}/icon`,
                        'icon',
                        binaryData,
                        filename,
                    );
                };
                service.applications.platforms = {};
                service.applications.platforms.list = (appId) => {
                    return new Promise((resolve, reject) => {
                        let publisher_id = service.get_current_publisher();
                        this.api
                            .get(
                                `/public/api/publishers/${publisher_id}/applications/${appId}/platforms`,
                            )
                            .then((response) => {
                                resolve(response);
                            })
                            .catch((err) => {
                                reject(err);
                            });
                    });
                };
                service.applications.platforms.add = (appId, platform, identifier) => {
                    let publisher_id = service.get_current_publisher();
                    let params = {
                        platform: platform,
                        identifier: identifier,
                    };
                    return this.api.post(
                        `/public/api/publishers/${publisher_id}/applications/${appId}/platforms`,
                        params,
                    );
                };
                service.applications.platforms.delete = (
                    appId,
                    platform,
                    identifier,
                ) => {
                    let publisher_id = service.get_current_publisher();
                    let params = {
                        platform: platform,
                        identifier: identifier,
                    };
                    debugger;
                    return this.api.delete(
                        `/public/api/publishers/${publisher_id}/applications/${appId}/platforms`,
                        params,
                    );
                };
                service.applications.platforms.verify = (
                    appId,
                    platform,
                    identifier,
                ) => {
                    let publisher_id = service.get_current_publisher();
                    let params = {
                        platform: platform,
                        identifier: identifier,
                    };
                    return this.api.post(
                        `/public/api/publishers/${publisher_id}/applications/${appId}/verify`,
                        params,
                    );
                };

                service.statistics = {};
                service.statistics.installations = (apps, from, to) => {
                    return new Promise((resolve, reject) => {
                        let publisher_id = service.get_current_publisher();
                        apps = Array.isArray(apps) ? apps : [apps];
                        let config = {
                            api_keys: apps,
                            start: from,
                            end: to,
                        };
                        this.api
                            .post(
                                `/public/api/publishers/${publisher_id}/statistics/installations/platforms`,
                                config,
                            )
                            .then((response) => {
                                resolve(response);
                            })
                            .catch((err) => {
                                reject(err);
                            });
                    });
                };

                service.statistics.returning = {};
                service.statistics.returning.stats = (apps, from, to) => {
                    return new Promise((resolve, reject) => {
                        let publisher_id = service.get_current_publisher();
                        apps = Array.isArray(apps) ? apps : [apps];
                        let config = {
                            api_keys: apps,
                            start: from,
                            end: to,
                        };
                        this.api
                            .post(
                                `/public/api/publishers/${publisher_id}/statistics/returning/stats`,
                                config,
                            )
                            .then((response) => {
                                resolve(response);
                            })
                            .catch((err) => {
                                reject(err);
                            });
                    });
                };
                service.statistics.returning.histogram = (apps, from, to) => {
                    return new Promise((resolve, reject) => {
                        let publisher_id = service.get_current_publisher();
                        apps = Array.isArray(apps) ? apps : [apps];
                        let config = {
                            api_keys: apps,
                            start: from,
                            end: to,
                            interval: 'd',
                        };
                        this.api
                            .post(
                                `/public/api/publishers/${publisher_id}/statistics/returning/histogram`,
                                config,
                            )
                            .then((response) => {
                                resolve(response);
                            })
                            .catch((err) => {
                                reject(err);
                            });
                    });
                };

                service.statistics.growth = {};
                service.statistics.growth.stats = (apps, from, to) => {
                    return new Promise((resolve, reject) => {
                        let publisher_id = service.get_current_publisher();
                        apps = Array.isArray(apps) ? apps : [apps];
                        let config = {
                            api_keys: apps,
                            start: from,
                            end: to,
                        };
                        this.api
                            .post(
                                `/public/api/publishers/${publisher_id}/statistics/growth/stats`,
                                config,
                            )
                            .then((response) => {
                                resolve(response);
                            })
                            .catch((err) => {
                                reject(err);
                            });
                    });
                };
                service.statistics.growth.histogram = (apps, from, to) => {
                    return new Promise((resolve, reject) => {
                        let publisher_id = service.get_current_publisher();
                        apps = Array.isArray(apps) ? apps : [apps];
                        let config = {
                            api_keys: apps,
                            start: from,
                            end: to,
                            interval: 'd',
                        };
                        this.api
                            .post(
                                `/public/api/publishers/${publisher_id}/statistics/growth/histogram`,
                                config,
                            )
                            .then((response) => {
                                resolve(response);
                            })
                            .catch((err) => {
                                reject(err);
                            });
                    });
                };

                service.statistics.realtime = {};
                service.statistics.realtime.geogrid = (
                    top_left,
                    bottom_right,
                    zoom,
                    apps,
                ) => {
                    return new Promise((resolve, reject) => {
                        let publisher_id = service.get_current_publisher();
                        let config = {
                            top_left: top_left,
                            bottom_right: bottom_right,
                            zoom: zoom,
                            api_keys: apps,
                        };
                        this.api
                            .post(
                                `/public/api/publishers/${publisher_id}/statistics/realtime/geogrid`,
                                config,
                            )
                            .then((response) => {
                                resolve(response);
                            })
                            .catch((err) => {
                                reject(err);
                            });
                    });
                };

                service.statistics.notifications = {};
                service.statistics.notifications.stats = (apps, from, to) => {
                    return new Promise((resolve, reject) => {
                        let publisher_id = service.get_current_publisher();
                        apps = Array.isArray(apps) ? apps : [apps];
                        let config = {
                            api_keys: apps,
                            start: from,
                            end: to,
                        };
                        this.api
                            .post(
                                `/public/api/publishers/${publisher_id}/statistics/notifications/stats`,
                                config,
                            )
                            .then((response) => {
                                resolve(response);
                            })
                            .catch((err) => {
                                reject(err);
                            });
                    });
                };
                service.statistics.notifications.histogram = (apps, from, to) => {
                    return new Promise((resolve, reject) => {
                        let publisher_id = service.get_current_publisher();
                        apps = Array.isArray(apps) ? apps : [apps];
                        let config = {
                            api_keys: apps,
                            start: from,
                            end: to,
                            interval: 'd',
                        };
                        this.api
                            .post(
                                `/public/api/publishers/${publisher_id}/statistics/notifications/histogram`,
                                config,
                            )
                            .then((response) => {
                                resolve(response);
                            })
                            .catch((err) => {
                                reject(err);
                            });
                    });
                };

                service.statistics.impressions = {};
                service.statistics.impressions.stats = (apps, from, to) => {
                    return new Promise((resolve, reject) => {
                        let publisher_id = service.get_current_publisher();
                        apps = Array.isArray(apps) ? apps : [apps];
                        let config = {
                            api_keys: apps,
                            start: from,
                            end: to,
                        };
                        this.api
                            .post(
                                `/public/api/publishers/${publisher_id}/statistics/impressions/stats`,
                                config,
                            )
                            .then((response) => {
                                resolve(response);
                            })
                            .catch((err) => {
                                reject(err);
                            });
                    });
                };
                service.statistics.impressions.histogram = (apps, from, to) => {
                    return new Promise((resolve, reject) => {
                        let publisher_id = service.get_current_publisher();
                        apps = Array.isArray(apps) ? apps : [apps];
                        let config = {
                            api_keys: apps,
                            start: from,
                            end: to,
                            interval: 'd',
                        };
                        this.api
                            .post(
                                `/public/api/publishers/${publisher_id}/statistics/impressions/histogram`,
                                config,
                            )
                            .then((response) => {
                                resolve(response);
                            })
                            .catch((err) => {
                                reject(err);
                            });
                    });
                };

                service.statistics.coverage = (apps, from, to) => {
                    return new Promise((resolve, reject) => {
                        let publisher_id = service.get_current_publisher();
                        apps = Array.isArray(apps) ? apps : [apps];
                        let config = {
                            api_keys: apps,
                            start: from,
                            end: to,
                        };
                        this.api
                            .post(
                                `/public/api/publishers/${publisher_id}/statistics/campaigns/coverage`,
                                config,
                            )
                            .then((response) => {
                                resolve(response);
                            })
                            .catch((err) => {
                                reject(err);
                            });
                    });
                };

                service.statistics.events = {};
                service.statistics.events.histogram = (apps, from, to) => {
                    return new Promise((resolve, reject) => {
                        let publisher_id = service.get_current_publisher();
                        apps = Array.isArray(apps) ? apps : [apps];
                        let config = {
                            api_keys: apps,
                            start: from,
                            end: to,
                            interval: 'd',
                        };
                        this.api
                            .post(
                                `/public/api/publishers/${publisher_id}/statistics/events/histogram`,
                                config,
                            )
                            .then((response) => {
                                resolve(response);
                            })
                            .catch((err) => {
                                reject(err);
                            });
                    });
                };

                service.campaigns = {};
                service.campaigns.types = {
                    ACCEPTED: 'accepted',
                    CURRENT: 'current',
                    EXPIRED: 'expired',
                    REJECTED: 'rejected',
                    SENT: 'sent',
                };
                service.campaigns.status = {
                    CURRENT: 0,
                    REJECTED: 1,
                    ACCEPTED: 2,
                };
                service.campaigns.list = (apps, type) => {
                    return new Promise((resolve, reject) => {
                        type = type || service.campaigns.types.CURRENT;
                        let publisher_id = service.get_current_publisher();
                        apps = Array.isArray(apps) ? apps : [apps];
                        let config = {
                            api_keys: apps,
                            type: type,
                        };
                        this.api
                            .post(
                                `/public/api/publishers/${publisher_id}/optins/search`,
                                config,
                            )
                            .then((response) => {
                                resolve(response);
                            })
                            .catch((err) => {
                                reject(err);
                            });
                    });
                };
                service.campaigns.update = (apps, campaign_id, status) => {
                    return new Promise((resolve, reject) => {
                        status = status || service.campaigns.status.ACCEPTED;
                        let publisher_id = service.get_current_publisher();
                        apps = Array.isArray(apps) ? apps : [apps];
                        let config = {
                            api_keys: apps,
                            status: status,
                            campaign_id: campaign_id,
                        };
                        this.api
                            .put(`/public/api/publishers/${publisher_id}/optins`, config)
                            .then((response) => {
                                resolve(response);
                            })
                            .catch((err) => {
                                reject(err);
                            });
                    });
                };

                service.reports = {};
                service.reports.campaigns_list = (apps, from, to) => {
                    return new Promise((resolve, reject) => {
                        let publisher_id = service.get_current_publisher();
                        apps = Array.isArray(apps) ? apps : [apps];
                        let config = {
                            api_keys: apps,
                            start: from,
                            end: to,
                        };
                        this.api
                            .post(
                                `/public/api/publishers/${publisher_id}/reports/campaigns`,
                                config,
                            )
                            .then((response) => {
                                resolve(response);
                            })
                            .catch((err) => {
                                reject(err);
                            });
                    });
                };

                service.reports.campaigns_search = (apps, from, to, campaigns) => {
                    return new Promise((resolve, reject) => {
                        let publisher_id = service.get_current_publisher();
                        apps = Array.isArray(apps) ? apps : [apps];
                        let config = {
                            api_keys: apps,
                            start: from,
                            end: to,
                            campaigns: campaigns,
                        };
                        this.api
                            .post(
                                `/public/api/publishers/${publisher_id}/reports/search`,
                                config,
                            )
                            .then((response) => {
                                resolve(response);
                            })
                            .catch((err) => {
                                reject(err);
                            });
                    });
                };

                service.reports.campaigns_download = (apps, from, to, campaigns) => {
                    return new Promise((resolve, reject) => {
                        let publisher_id = service.get_current_publisher();
                        apps = Array.isArray(apps) ? apps : [apps];
                        let config = {
                            api_keys: apps,
                            start: from,
                            end: to,
                            campaigns: campaigns,
                        };
                        this.api
                            .campaignsDownload(
                                `/public/api/publishers/${publisher_id}/reports/download`,
                                config,
                            )
                            .then((response) => {
                                resolve(response);
                            })
                            .catch((err) => {
                                reject(err);
                            });
                    });
                };

                service.payments = {};
                service.payments.get_list = () => {
                    return new Promise((resolve, reject) => {
                        let publisher_id = service.get_current_publisher();
                        let config = {};
                        this.api
                            .get(`/public/api/publishers/${publisher_id}/payments`, config)
                            .then((response) => {
                                resolve(response);
                            })
                            .catch((err) => {
                                reject(err);
                            });
                    });
                };

                service.payments.download = (document_id) => {
                    return new Promise((resolve, reject) => {
                        let publisher_id = service.get_current_publisher();
                        let config = {};
                        this.api
                            .paymentDownload(
                                `/public/api/publishers/${publisher_id}/documents/${document_id}/download`,
                                config,
                            )
                            .then((response) => {
                                resolve(response);
                            })
                            .catch((err) => {
                                reject(err);
                            });
                    });
                };

                service.payments.accept = (id) => {
                    return new Promise((resolve, reject) => {
                        let publisher_id = service.get_current_publisher();
                        let config = {};
                        this.api
                            .post(
                                `/public/api/publishers/${publisher_id}/payments/${id}/accept`,
                                config,
                            )
                            .then((response) => {
                                resolve(response);
                            })
                            .catch((err) => {
                                reject(err);
                            });
                    });
                };

                service.tokenLogin = (token, publisherId) => {
                    return new Promise((resolve, reject) => {
                        localStorage['token'] = token;

                        this.service.refresh_token()
                            .then(() => {
                                localStorage['publisher'] = publisherId;

                                this.service.applications.list()
                                    .then((apps) => {
                                        let tmpApps = [];

                                        apps.map((a) => {
                                            tmpApps.push(a.id);
                                        });

                                        localStorage['selected_apps'] = JSON.stringify(tmpApps);

                                        localStorage['selected_time_period_from'] = moment(new Date())
                                            .subtract(7, "d")
                                            .startOf("day")
                                            .valueOf();

                                        localStorage['selected_time_period_to'] = moment(new Date())
                                            .endOf("day")
                                            .valueOf();

                                        localStorage['selected_time_period_keys'] = 7;

                                        resolve();
                                    });
                            })
                            .catch(error => {
                                reject(error);
                            });
                    });
                };
            },
        });
        Vue.prototype.service = service;
    },
};

<template>
	<form class="form" autocomplete="off" @submit.prevent="submitForm" v-if="!loading">
		<div v-if="error" class="error error-panel panel">
			{{ error }}
		</div>
		<div class="fieldset icon">
			<input type="text" name="name" :placeholder="$t('form.publisher.name')" class="input" autocomplete="off" v-model.trim="$v.form.name.$model"/>
		</div>
		<div class="error">
			<span v-if="submitted && !$v.form.name.required">
				{{ $t('form.publisher.errors.name_required') }}
			</span>
		</div>
		<div class="fieldset icon">
			<input maxlength="10" type="text" name="vat" :placeholder="$t('form.publisher.vat')" class="input" autocomplete="off" v-model.trim="$v.form.vat.$model"/>
		</div>
		<div class="error">
			<span v-if="submitted && !$v.form.vat.required">
				{{ $t('form.publisher.errors.vat_required') }}
			</span>
		</div>
		<div class="fieldset icon">
			<input type="text" name="country" :placeholder="$t('form.publisher.country')" class="input" autocomplete="off" v-model.trim="$v.form.country.$model"/>
		</div>
		<div class="error">
			<span v-if="submitted && !$v.form.country.required">
				{{ $t('form.publisher.errors.country_required') }}
			</span>
		</div>
		<div class="fieldset icon">
			<input type="text" name="address" :placeholder="$t('form.publisher.address')" class="input" autocomplete="off" v-model.trim="$v.form.address.$model"/>
		</div>
		<div class="error">
			<span v-if="submitted && !$v.form.address.required">
				{{ $t('form.publisher.errors.address_required') }}
			</span>
		</div>
		<div class="threefourth padding-right">
			<div class="fieldset icon">
				<input type="text" name="city" :placeholder="$t('form.publisher.city')" class="input" autocomplete="off" v-model.trim="$v.form.city.$model"/>
			</div>
			<div class="error">
				<span v-if="submitted && !$v.form.city.required">
					{{ $t('form.publisher.errors.city_required') }}
				</span>
			</div>
		</div>
		<div class="onefourth padding-left">
			<div class="fieldset icon">
				<input maxlength="5" type="text" name="zip" :placeholder="$t('form.publisher.zip')" class="input" autocomplete="off" v-model.trim="$v.form.zip.$model"/>
			</div>
			<div class="error">
				<span v-if="submitted && !$v.form.zip.required">
					{{ $t('form.publisher.errors.zip_required') }}
				</span>
			</div>
		</div>
		<div class="fieldset icon">
			<input type="text" name="email" :placeholder="$t('form.publisher.email')" class="input" autocomplete="off" v-model.trim="$v.form.email.$model"/>
		</div>
		<div class="error">
			<span v-if="submitted && !$v.form.email.required">
				{{ $t('form.publisher.errors.email_required') }}
			</span> <span v-if="submitted && !$v.form.email.email">
				{{ $t('form.publisher.errors.email') }}
			</span>
		</div>
		<div class="fieldset icon">
			<input type="text" name="phone" :placeholder="$t('form.publisher.phone')" class="input" autocomplete="off" v-model.trim="$v.form.phone.$model"/>
		</div>
		<div class="error">
			<span v-if="submitted && !$v.form.phone.required">
				{{ $t('form.publisher.errors.phone_required') }}
			</span>
		</div>
		<div class="fieldset icon">
			<select class="input" name="currency" v-model.trim="$v.form.currency.$model">
				<option value="">{{ $t('form.publisher.select_currency') }}</option>
				<option value="EUR">{{ $t('common.currencies.eur') }} (&euro;)</option>
				<option value="USD">{{ $t('common.currencies.usd') }} (&dollar;)</option>
			</select>
		</div>
		<div class="error">
			<span v-if="submitted && !$v.form.currency.required">
				{{ $t('form.publisher.errors.currency_required') }}
			</span>
		</div>
		<button type="submit" :disabled="loading" class="btn big full">
			<span v-if="loading">{{ $t('common.loading') }}</span> <span v-if="!loading">{{ button_text }}</span>
		</button>
	</form>
</template>
<script>
import {required, email} from 'vuelidate/lib/validators'

export default {
	name: 'PublisherInfoForm',
	props: {
		submit_text: {
			required: false
		}
	},
	data() {
		return {
			submitted: false,
			error: '',
			loading: false,
			button_text: this.$t('common.save'),
			form: {
				name: '',
				country: '',
				city: '',
				address: '',
				zip: '',
				vat: '',
				email: '',
				phone: '',
				currency: ''
			}
		};
	},
	mounted() {
		this.init();
	},
	validations: {
		form: {
			name: {
				required
			},
			country: {
				required
			},
			city: {
				required
			},
			address: {
				required
			},
			zip: {
				required
			},
			vat: {
				required
			},
			email: {
				required,
				email
			},
			phone: {
				required
			},
			currency: {
				required
			}
		}
	},
	methods: {
		refresh_data(data) {
			Object.keys(this.form).forEach((k) => {
				this.form[k] = data[k];
			});
		},
		init() {
			if (this.submit_text) {
				this.button_text = this.submit_text
			}
			// get old data
			this.loading = true;
			this.service.publisher.profile()
			.then((data) => {
				this.refresh_data(data)
			})
			.catch((err) => {
				this.error = this.$t(`form.publisher.errors.${err.message}`);
			})
			.finally(() => {
				this.loading = false;
			})
		},
		submitForm() {
			this.$v.$touch();
			this.submitted = true;
			this.error = '';
			this.loading = true;
			if (!this.$v.$invalid) {
				this.service.publisher.save_profile(this.form)
				.then(() => {
					this.$emit('success');
				})
				.catch((err) => {
					this.error = this.$t(`form.publisher.errors.${err.message}`);
				})
				.finally(() => {
					this.loading = false
				})
			} else {
				this.loading = false;
			}
		}
	}
}
</script>
<style lang="scss"></style>

<template>
    <div id="dashboard" class="dashboard-layout">
        <Head />
        <Menu />
        <router-view />
    </div>
</template>

<script>
import Head from './dashboard/Head.vue';
import Menu from './dashboard/Menu.vue';

export default {
    name: 'DashboardLayout',
    components: {
        Head,
        Menu
    }
}
</script>

<style lang="scss">
@import '~@/assets/scss/layouts/_dashboard-layout';
</style>

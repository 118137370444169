<template>
  <main id="report" class="dashboard main-content">
    <div class="first-row row">
      <div class="col half">
        <div class="welcome">
          <div class="welcome--box">
            <h1>
              {{ $t("reports.title") }} </h1>
            <h2>
              {{ $t("reports.subtitle") }} </h2>
          </div>
        </div>
      </div>
      <div class="col half">
        <div class="row">
          <div class="col half">
            {{ $t("reports.select_time") }}
          </div>
          <div class="col half">
            {{ $t("reports.select_campaigns") }}
          </div>
        </div>
        <div class="row">
          <div class="col half">
            <select-time-period></select-time-period>
          </div>
          <div class="col half">
            <select-campaigns></select-campaigns>
          </div>
        </div>
      </div>
    </div>
    <div class="row panel__top_margin_big">
      <div class="col panel full">
        <div class="row">
          <div class="col report__header_selected third">
            <h4>{{ $t("reports.apps_selected") }}</h4>
            <span class="panel__top_margin" v-if="filter.apps.length < 6">
							<ul>
								<li v-for="a in filter.apps" v-bind:key="a">
									{{ a }}
								</li>
							</ul>
						</span> <span class="panel__top_margin" v-if="filter.apps.length >= 6">
							{{ filter.apps.length }} {{ $t("common.selected") }}
						</span>
          </div>
          <div class="col report__header_selected third">
            <h4>{{ $t("reports.time_selected") }}</h4>
            <span class="panel__top_margin">
							{{ $t("common.from") }} {{ filter.from | data }}
							{{ $t("common.to") }} {{ filter.to | data }}
						</span>
          </div>
          <div class="col report__header_selected third">
            <h4>{{ $t("reports.campaigns_selected") }}</h4>
            <span class="panel__top_margin" v-if="filter.campaigns.length < 6">
							<ul>
								<li v-for="c in filter.campaigns" v-bind:key="c">
									{{ c }}
								</li>
							</ul>
						</span> <span class="panel__top_margin" v-if="filter.campaigns.length >= 6">
							{{ filter.campaigns.length }} {{ $t("common.selected") }}
						</span>
          </div>
        </div>
        <div class="row row__padding_top_bottom_big">
          <div class="col three-quarters report__stat_box_container">
            <div class="report__stat_box">
              <span class="number">{{ notifications | number }}</span>
              <p>{{ $t("reports.notifications_provided") }}</p>
            </div>
            <div class="report__stat_box">
              <span class="number">{{ total | number }} €</span>
              <p>{{ $t("reports.enter") }}</p>
            </div>
          </div>
          <div class="col one-quarters col__end col__center">
            <button type="button" class="btn big" @click="show_detail = !show_detail">
              <span v-if="!show_detail">{{ $t("reports.button_detail_show") }}</span> <span v-if="show_detail">{{ $t("reports.button_detail_hide") }}</span>
            </button>
          </div>
        </div>
        <div class="row row__padding_top" v-if="show_detail">
          <table>
            <caption>Reports</caption>
            <thead>
            <tr>
              <th scope="col">{{ $t("reports.table.name") }}</th>
              <th scope="col">{{ $t("reports.table.appname") }}</th>
              <th scope="col" colspan="2">{{ $t("reports.table.date") }}</th>
              <th scope="col" colspan="2"> {{ $t("reports.table.performance_ios") }}</th>
              <th scope="col" colspan="2"> {{ $t("reports.table.performance_android") }}</th>
              <th scope="col">{{ $t("reports.table.total") }}</th>
            </tr>
            <tr>
              <th scope="col" class="subhead"></th>
              <th scope="col" lass="subhead"></th>
              <th scope="col" class="subhead">{{ $t("reports.table.start") }}</th>
              <th scope="col" class="subhead">{{ $t("reports.table.end") }}</th>
              <th scope="col" class="subhead text-right"> {{ $t("reports.table.notifications") }}</th>
              <th scope="col" class="subhead text-right"> {{ $t("reports.table.total") }}</th>
              <th scope="col" class="subhead text-right"> {{ $t("reports.table.notifications") }}</th>
              <th scope="col" class="subhead text-right"> {{ $t("reports.table.total") }}</th>
              <th scope="col" class="subhead"></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(app, index) in items" v-bind:key="index">
              <td>{{ app.campaign_name }}</td>
              <td>{{ app.name }}</td>
              <td>{{ app.start | data }}</td>
              <td>{{ app.end | data }}</td>
              <td class="text-right">
                {{ app.ios.notifications | number }}
              </td>
              <td class="text-right">
                {{ app.ios.revenue | number }}
              </td>
              <td class="text-right">
                {{ app.android.notifications | number }}
              </td>
              <td class="text-right">
                {{ app.android.revenue | number }}
              </td>
              <td>
                <strong>{{ (app.ios.revenue + app.android.revenue) | number }} €</strong>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <div class="row row__padding_top" v-if="show_detail">
          <button type="button" class="btn big" @click="downloadData">
            <span v-if="!loading">{{ $t("common.download") }}</span> <span v-if="loading">{{ $t("common.loading") }}</span>
          </button>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import SelectTimePeriod from "../components/common/filters/SelectTimePeriod";
import SelectCampaigns from "../components/common/filters/SelectCampaigns";

export default {
  name: "ReportPage",
  components: {
    SelectCampaigns,
    SelectTimePeriod
  },
  data() {
    return {
      show_detail: false,
      loading: false,
      err: "",
      items: [],
      notifications: 0,
      total: 0,
      filter: {
        from: null,
        to: null,
        apps: [],
        campaigns: [],
      },
    };
  },
  methods: {
    updateFilter() {
      let from = localStorage.getItem("selected_time_period_from");
      let to = localStorage.getItem("selected_time_period_to");
      this.filter.from = from;
      this.filter.to = to;
      this.filter.apps = localStorage.getItem("selected_apps_name");
      try {
        this.filter.apps = JSON.parse(this.filter.apps);
      } catch (e) {
        this.filter.apps = [];
      }
      this.filter.campaigns = localStorage.getItem("selected_campaigns_name");
      try {
        this.filter.campaigns = JSON.parse(this.filter.campaigns);
      } catch (e) {
        this.filter.campaigns = [];
      }
    },
    downloadData() {
      this.loading = true;
      this.err = "";
      this.notifications = 0;
      this.total = 0;
      // definizione delle app
      let app_ids = localStorage.getItem("selected_apps");
      try {
        app_ids = JSON.parse(app_ids);
      } catch (e) {
        app_ids = [];
      }
      let campaigns_ids = localStorage.getItem("selected_campaigns");
      try {
        campaigns_ids = JSON.parse(campaigns_ids);
      } catch (e) {
        campaigns_ids = [];
      }
      this.service.reports.campaigns_download(app_ids, this.filter.from, this.filter.to, campaigns_ids)
          .then(() => {
            this.loading = false;
          });
    },
    updateData() {
      this.loading = true;
      this.err = "";
      this.notifications = 0;
      this.total = 0;
      let app_ids = localStorage.getItem("selected_apps");
      try {
        app_ids = JSON.parse(app_ids);
      } catch (e) {
        app_ids = [];
      }
      let campaigns_ids = localStorage.getItem("selected_campaigns");
      try {
        campaigns_ids = JSON.parse(campaigns_ids);
      } catch (e) {
        campaigns_ids = [];
      }
      this.service.reports.campaigns_search(app_ids, this.filter.from, this.filter.to, campaigns_ids).then((result) => {
        this.report = [];
        this.notifications = result.notifications;
        this.total = result.total;
        this.items = [];
        if (result && result.applications && result.applications.length) {
          result.applications.map((a) => {
            let item = {
              id: a.id,
              name: a.name,
            };
            a.campaigns.map((c) => {
              item = {
                ...item,
                ...{
                  campaign_id: c.id,
                  android: c.android,
                  ios: c.ios,
                  campaign_name: c.name,
                  start: c.start,
                  end: c.end,
                },
              };
              this.items.push(item);
            });
          });
        }
      }).finally(() => {
        this.loading = false;
      });
    },
    init() {
      this.$root.$on("filters_updated", () => {
        if (this.$router.currentRoute.name == "report.page") {
          this.updateFilter();
          this.updateData();
        }
      });
    },
  },
  mounted() {
    this.init();
    this.updateFilter();
    this.updateData();
  },
};
</script>
<style lang="scss">
@import "~@/assets/scss/pages/_report";
</style>

<template>
    <div class="list-invites">
        <div v-if="error" class="error error-panel panel">
            {{ error }}
        </div>

        <div class="list-invites__group_container">
            <div v-bind:key="item.role" v-for="item in list" class="list-invites__group">
                <h4 class="group-title">{{ $t(`roles_names[${item.role}]`)}}</h4>

                <div class="list-invites__users">

                    <div class="list-invites__users_item" v-bind:key="u.id" v-for="u in item.data">
                        <span class="name">
                            <strong v-if="u.user && u.user.first_name">{{ u.user.first_name }}</strong>
                            <strong v-if="u.user && u.user.last_name">{{ u.user.last_name }}</strong>
                            {{ u.email }}
                        </span>
                        <div class="list-invites__users_item__remove" @click="toRemove = u; showModal = true">
                            <span class="icon">
                                <svg viewBox="0 0 40 50">
                                    <use xlink:href="#icons--trash" width="100%" height="100%"></use>
                                </svg>
                            </span>
                        </div>
                    </div>

                </div>

            </div>
        </div>
        <confirm-modal v-if="showModal" @close="remove($event)" :title="$t('form.invites.remove.title')" :description="$t('form.invites.remove.description')"></confirm-modal>

    </div>
</template>
<script>
    import ConfirmModal from "./common/modals/ConfirmModal";
    export default {
        name: 'ListInvites',
        components: {ConfirmModal},
        data() {
            return {
                error: '',
                loading: false,
                list: [],
                showModal: false,
                toRemove: null
            };
        },
        mounted() {
            this.init();
            this.$root.$on('update-list-invites', () => {
                this.init();
            })
        },
        methods: {
            init() {
                this.loading = true;
                this.list = [];
                this.service.invites.list()
                    .then((data) => {
                        if(data)
                            this.list = data;
                    })
                    .catch((err) => {
                        this.error = this.$t(`form.invites.errors.${err.message}`);
                    })
                    .finally(() => {
                        this.loading = false;
                    })
            },
            remove(success) {
                // this.loading = true;
                if(success) {
                    this.service.invites.delete(this.toRemove.id)
                        .then(() => {
                            this.init();
                        })
                        .catch((err) => {
                            this.error = this.$t(`form.invites.errors.${err.message}`);
                        })
                        .finally(() => {
                            this.loading = false;
                        })
                }

                this.toRemove = null;
                this.showModal = false;
            }
        }
    }
</script>
<style lang="scss">
@import '~@/assets/scss/components/_list-invites';
</style>

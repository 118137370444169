<script>
export default {
  name: "Info",
};
</script>

<template>
  <svg viewBox="203.2497 170.4102 100 100" xmlns="http://www.w3.org/2000/svg">
    <g transform="matrix(1, 0, 0, 1, 203.24971008300787, 170.41024780273438)">
      <path
          d="m50 23.215c2.957 0 5.3555 2.3984 5.3555 5.3555v0.097657c0 2.957-2.3984 5.3555-5.3555 5.3555s-5.3555-2.3984-5.3555-5.3555v-0.097657c0-2.957 2.3984-5.3555 5.3555-5.3555z"/>
      <path
          d="m50 39.172c2.957 0 5.3555 2.3984 5.3555 5.3594v28.434c0 2.9609-2.3984 5.3594-5.3555 5.3594s-5.3555-2.3984-5.3555-5.3594v-28.434c0-2.9609 2.3984-5.3594 5.3555-5.3594z"/>
      <path
          d="m100 50c0 27.613-22.387 50-50 50s-50-22.387-50-50 22.387-50 50-50 50 22.387 50 50zm-7.1445 0c0 23.668-19.188 42.855-42.855 42.855s-42.855-19.188-42.855-42.855 19.188-42.855 42.855-42.855 42.855 19.188 42.855 42.855z"
          fill-rule="evenodd"/>
    </g>
  </svg>
</template>

<style scoped lang="scss">
svg {
  width: 1.4rem;
  height: 1.4rem;
}
</style>

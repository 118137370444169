<template>
    <main id="login" class="login main-content">
        <login-form></login-form>
    </main>
</template>

<script>
import LoginForm from "../components/login/LoginForm";

export default {
    name: 'LoginPage',
    components: {
        LoginForm
    }
}
</script>

<style lang="scss">
@import '~@/assets/scss/pages/_login';
</style>

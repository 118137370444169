<template>
    <main id="reset-password" class="main-content">
        <router-view></router-view>
    </main>
</template>

<script>
    export default {
        name: 'ResetPasswordContainerPage'
    }
</script>

<style lang="scss">
    @import '~@/assets/scss/pages/_reset-password';
</style>

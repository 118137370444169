<template>
  <main id="pagamenti" class="pagamenti main-content">
    <div class="welcome">
      <div class="welcome--box">
        <div class="payments-header">
          <h1>{{ $t("payments.title") }}</h1>
          <div v-if="quarterPaymentsGenerationDisclaimer" class="payment-generation-disclaimer">
            <info-icon/>
            <span>{{ quarterPaymentsGenerationDisclaimer }}</span>
          </div>
        </div>
        <h2>
          {{ $t("payments.subtitle") }}
        </h2>
      </div>
    </div>

    <div class="row panel__top_margin_big">
      <div class="col panel full">
        <div class="row row__padding_top" v-if="!loading">
          <div v-if="payments.length <= 0">
            {{ $t('payments.table.nodata') }}
          </div>
          <table v-if="payments.length">
            <caption>Payments</caption>
            <thead>
            <tr>
              <th scope="col">{{ $t("payments.table.quarter") }}</th>
              <th scope="col">{{ $t("payments.table.amount") }}</th>
              <th scope="col">{{ $t("payments.table.status") }}</th>
              <th scope="col">{{ $t("payments.table.actions") }}</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(p, index) in payments" v-bind:key="index">
              <td v-if="!p.detail">
                {{ p.date_from | data }} - {{ p.date_to | data }}
              </td>
              <td v-if="!p.detail">
                <strong>{{ p.total | currency }}</strong>
              </td>
              <td v-if="!p.detail">
									<span class="payment_status">
										<span
                        :class="`ball ball__${getStatusClass(p.status)}`"
                    ></span>
										<span class="">
											{{ $t(`payments.status[${p.status}]`) }}
										</span>
									</span>
              </td>
              <td v-if="!p.detail">
                <button
                    v-if="p.status != 2"
                    type="button"
                    class="btn btn_small btn_blue"
                    @click="toggleDetail(p.id)"
                >
                  {{ $t("payments.detail") }}
                </button>
                <button
                    v-if="p.status == 1 || p.status == 2"
                    type="button"
                    class="btn big btn_small"
                    @click="downloadDocument(p)"
                >
                  {{ $t("common.download") }}
                </button>
              </td>
              <td v-if="p.detail && p.open_detail" colspan="5">
                <div class="payment__detail">
                  <div class="col three-quarters report__stat_box_container">
                    <h3>{{ $t("payments.request_payment") }}</h3>
                    <div class="report__stat_box">
                      <span class="number">{{ p.total | currency }}</span>
                      <p>{{ $t("reports.enter") }}</p>
                    </div>
                  </div>
                  <br/>
                  <button
                      v-if="p.status == 1"
                      type="button"
                      class="btn btn_blue"
                      @click="
												() => {
													confirmSelectionId = p.id;
													showConfirmModal = true;
												}
											"
                  >
                    {{ $t("payments.request_confirm") }}
                  </button>
                  <a
                      type="button"
                      class="btn big"
                      target="_new"
                      href="mailto:publisher_nextmedia@next14.com"
                  >
                    {{ $t("payments.request_help") }}
                  </a>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
        <loading-spinner :small="true" v-if="loading"></loading-spinner>
      </div>
    </div>

    <confirm-modal
        v-if="showConfirmModal"
        @close="confirmPayment($event)"
        :title="$t('payments.modal.title')"
        :description="$t('payments.modal.description')"
    ></confirm-modal>
  </main>
</template>

<script>
import LoadingSpinner from "../components/common/Loading";
import ConfirmModal from "../components/common/modals/ConfirmModal";
import InfoIcon from "../components/icons/Info.vue";

export default {
  name: "PagamentiPage",
  components: {ConfirmModal, LoadingSpinner, InfoIcon},
  data() {
    return {
      loading: false,
      err: "",
      payments: [],
      showConfirmModal: false,
      confirmSelectionId: null,
      currentDate: new Date(),
      quarterPaymentsGenerationPeriods: [
        {
          quarter: 1,
          visibilityStart: {
            day: 1,
            month: 4,
          },
          visibilityEnd: {
            day: 10,
            month: 4,
          },
          message: this.$t('payments.quarterPaymentsGeneration.januaryToMarchDisclaimer'),
        },
        {
          quarter: 2,
          visibilityStart: {
            day: 1,
            month: 7,
          },
          visibilityEnd: {
            day: 10,
            month: 7,
          },
          message: this.$t('payments.quarterPaymentsGeneration.aprilToJuneDisclaimer'),
        },
        {
          quarter: 3,
          visibilityStart: {
            day: 1,
            month: 10,
          },
          visibilityEnd: {
            day: 10,
            month: 10,
          },
          message: this.$t('payments.quarterPaymentsGeneration.julyToSeptemberDisclaimer'),
        },
        {
          quarter: 4,
          visibilityStart: {
            day: 1,
            month: 1,
          },
          visibilityEnd: {
            day: 10,
            month: 1,
          },
          message: this.$t('payments.quarterPaymentsGeneration.octoberToDecemberDisclaimer'),
        },
      ],
    };
  },
  computed: {
    quarterPaymentsGenerationDisclaimer() {
      const quarterPeriod = this.quarterPaymentsGenerationPeriods.find(quarterPeriod => {
        const startDate = quarterPeriod.visibilityStart;
        const endDate = quarterPeriod.visibilityEnd;
        const currentDay = this.currentDate.getDate();
        const currentMonth = this.currentDate.getMonth() + 1;

        return currentDay >= startDate.day && currentDay <= endDate.day && currentMonth === startDate.month;
      });

      return quarterPeriod?.message ?? null;
    },
  },
  methods: {
    updateData() {
      this.loading = true;
      this.err = "";
      this.payments = [];
      this.service.payments
          .get_list()
          .then((result) => {
            result.map((r) => {
              let base = {...r};
              let detail = {...r};
              base.detail = false;
              base.open_detail = false;
              detail.detail = true;
              detail.open_detail = false;
              this.payments.push(base);
              this.payments.push(detail);
            });
          })
          .finally(() => {
            this.loading = false;
          });
    },
    downloadDocument(p) {
      this.service.payments
          .download(p.document_id)
          .then(() => {
            this.loading = false;
          });
    },
    toggleDetail(id) {
      this.payments.map((p) => {
        if (p.id == id) {
          p.open_detail = p.open_detail ? p.open_detail : false;
          p.open_detail = !p.open_detail;
        } else {
          p.open_detail = false;
        }
      });
    },
    contactSupport() {
      alert("Contact Support");
    },
    confirmPayment(e) {
      if (e) {
        this.loading = true;
        this.service.payments
            .accept(this.confirmSelectionId)
            .then(() => {
            })
            .finally(() => {
              this.showConfirmModal = false;
              this.confirmSelectionId = null;
              this.loading = false;
              this.updateData();
            });
      } else {
        this.showConfirmModal = false;
        this.confirmSelectionId = null;
        this.loading = false;
        this.updateData();
      }
    },
    getStatusClass(s) {
      return ` ${s}`.trim().toLowerCase().replace("-", "_");
    },
  },
  mounted() {
    this.updateData();
  },
};
</script>

<style lang="scss">
@import "~@/assets/scss/pages/_pagamenti";

.payment__detail {
  margin-left: 20%;
  border-left: 2px solid $orange;
  padding-left: 30px;
  width: 100%;

  h3 {
    width: 100%;
    margin-bottom: 20px;
    line-height: 50px;
  }
}

.payment_status {
  display: flex;
  flex-direction: row;

  .ball {
    display: block;
    background: black;
    border-radius: 50%;
    height: 20px;
    width: 20px;
    margin: 0 10px 0 0;

    &__2 {
      background: $green;
    }

    &__0 {
      background: $red;
    }

    &__1 {
      background: yellow;
    }
  }
}

.welcome--box {
  width: 100%;
}

.payments-header {
  width: 100%;
  display: flex;
  justify-content: space-between;

  h1 {
    width: fit-content !important;
    margin: 0 !important;
  }
}

.payment-generation-disclaimer {
  display: flex;
  align-items: center;
  gap: 0.5rem;

  span {
    color: black;
    font-size: small;
  }
}
</style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"map_width"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col half"},[_c('span',{staticClass:"switch--box",class:{ 'is-location': _vm.check == false }},[_c('span',{staticClass:"switch--label",on:{"click":function($event){_vm.check = false}}},[_vm._v(" "+_vm._s(_vm.$t("realtime.locations.title"))+" ")]),_c('label',{staticClass:"switch"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.check),expression:"check"}],attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(_vm.check)?_vm._i(_vm.check,null)>-1:(_vm.check)},on:{"change":function($event){var $$a=_vm.check,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.check=$$a.concat([$$v]))}else{$$i>-1&&(_vm.check=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.check=$$c}}}}),_c('span',{staticClass:"slider round"})]),_c('span',{staticClass:"switch--label",on:{"click":function($event){_vm.check = true}}},[_vm._v(" "+_vm._s(_vm.$t("realtime.notifications.title"))+" ")])])])]),_c('div',{staticClass:"map_container"},[_c('GmapMap',{ref:"mapRef",staticStyle:{"width":"100%","height":"900px"},attrs:{"clickable":_vm.clickable,"draggable":_vm.draggable,"options":_vm.options,"zoom":_vm.zoom,"center":{ lat: _vm.center.lat, lng: _vm.center.lon }},on:{"dragend":function($event){return _vm.updateData()}}},[(!_vm.check)?_c('div',_vm._l((_vm.data.locations),function(l,index){return _c('div',{key:index},[_c('gmap-circle',{attrs:{"center":{ lat: +l.lat, lng: +l.lon },"radius":l.radius,"options":{
							editable: false,
							strokeColor: '#ed7d31',
							strokeOpacity: 0.3,
							strokeWeight: 20,
							fillColor: '#ed7d31',
							fillOpacity: 0.9,
						}}}),_c('gmap-custom-marker',{staticClass:"bubble",attrs:{"marker":{ lat: +l.lat - 0.07, lng: +l.lon }}},[_c('div',{style:({ color: 'white' })},[_vm._v(_vm._s(_vm._f("number")(l.count)))])])],1)}),0):_vm._e(),(_vm.check)?_c('div',_vm._l((_vm.data.notifications),function(l,index){return _c('div',{key:index},[_c('gmap-circle',{attrs:{"center":{ lat: +l.lat, lng: +l.lon },"radius":l.radius,"options":{
							editable: false,
							strokeColor: '#0098A1',
							strokeOpacity: 0.3,
							strokeWeight: 20,
							fillColor: '#0098A1',
							fillOpacity: 0.9,
						}}}),_c('gmap-custom-marker',{staticClass:"bubble",attrs:{"marker":{ lat: +l.lat, lng: +l.lon }}},[_c('div',{style:({ color: 'white' })},[_vm._v(_vm._s(_vm._f("number")(l.count)))]),_c('img',{attrs:{"alt":"","src":require("@images/marker.png")}})])],1)}),0):_vm._e()]),_c('div',{staticClass:"zoom_buttons"},[_c('div',{staticClass:"zoom_buttons__button",on:{"click":function($event){return _vm.updateZoom(1)}}},[_vm._v("+")]),_c('div',{staticClass:"zoom_buttons__button",on:{"click":function($event){return _vm.updateZoom(-1)}}},[_vm._v("-")])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }
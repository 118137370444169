<script>
import { Bar } from "vue-chartjs";
import moment from "moment";

export default {
	name: "EventsHistogramChart",
	extends: Bar,
	props: {
		inareas: {
			required: true,
		},
		inplaces: {
			required: true,
		},
		nearplaces: {
			required: true,
		},
	},
	data() {
		return {};
	},
	mounted() {
		this.updateData();
	},
	methods: {
		updateData() {
			let inareas = [];
			let labels = [];
			if (this.inareas && Array.isArray(this.inareas)) {
				this.inareas.map((dm) => {
					inareas.push(dm.count);

					let from = moment(+localStorage.getItem("selected_time_period_from"));
					let to = moment(+localStorage.getItem("selected_time_period_to"));
					let duration = moment.duration(to.diff(from)).asHours();

					if (duration > 48) {
						labels.push(moment(dm.date).format("DD/MM/YYYY"));
					} else {
						labels.push(moment(dm.date).format("DD/MM - HH:00"));
					}
				});
			}

			let inplaces = [];
			if (this.inplaces && Array.isArray(this.inplaces)) {
				this.inplaces.map((dm) => {
					inplaces.push(dm.count);
				});
			}

			let nearplaces = [];
			if (this.nearplaces && Array.isArray(this.nearplaces)) {
				this.nearplaces.map((dm) => {
					nearplaces.push(dm.count);
				});
			}

			let c = {
				datasets: [
					{
						data: nearplaces,
						backgroundColor: "#ed7d3155",
						fill: true,
						fontColor: "#ed7d31",
						borderColor: "#ed7d31",
					},
					{
						data: inplaces,
						backgroundColor: "#d8241655",
						fill: true,
						fontColor: "#d82416",
						borderColor: "#d82416",
					},
					{
						data: inareas,
						backgroundColor: "#0098a155",
						fill: true,
						fontColor: "#0098a1",
						borderColor: "#0098a1",
					},
				],
				labels: labels,
			};
			this.renderChart(c, {
				legend: {
					display: false,
				},
				scales: {
					yAxes: [
						{
							ticks: {
								beginAtZero: true,
							},
						},
					],
				},
			});
		},
	},
	watch: {
		inareas: {
			deep: true,
			handler() {
				this.updateData();
			},
		},
	},
};
</script>
<style>
</style>

<template>
	<form class="form" autocomplete="off" @submit.prevent="submitForm">
		<div class="modal-mask">
			<div class="modal-wrapper">
				<div class="modal-container modal-container-big">

					<div class="modal-header">
						<slot name="header">
							<h3>{{$t('development.app.modal.title')}}</h3>
						</slot>
					</div>
					<div class="modal-body">

						<h5>{{$t('development.app.modal.form.name')}}</h5>
						<div class="fieldset icon">
							<input type="text" name="name" :placeholder="$t('development.app.modal.form.name')"
								   class="input" autocomplete="off" v-model.trim="$v.form.name.$model"/>
						</div>
						<div class="error">
								<span v-if="submitted && !$v.form.name.required">
									{{ $t('development.app.modal.form.errors.name_required') }}
								</span>
						</div>

						<h5>{{$t('development.app.modal.form.categories')}}</h5>
						<div class="fieldset icon" style="border:none">
							<div class="form_app_categories">
								<label class="form_app_categories__item" v-for="(c, k) in listCategories" :key="k">
									<input type="checkbox" :value="k" v-model.trim="$v.form.categories.$model">
									{{c}}
								</label>
							</div>
						</div>
						<div class="error">
							<span v-if="submitted && !$v.form.categories.required">
								{{ $t('development.app.modal.form.errors.categories_required') }}
							</span>
							<span v-if="submitted && !$v.form.categories.minLength">
								{{ $t('development.app.modal.form.errors.categories_minlength') }}
							</span>
							<span v-if="submitted && !$v.form.categories.maxLength">
								{{ $t('development.app.modal.form.errors.categories_maxlength') }}
							</span>
						</div>

					</div>
					<div class="modal-footer">
						<slot name="footer">
							<button type="button" class="btn big" @click="$emit('close', null)">
								<span>{{ $t('common.close') }}</span>
							</button>
							<button type="submit" class="btn big">
								<span>{{ $t('common.save') }}</span>
							</button>
						</slot>
					</div>

				</div>
			</div>
		</div>
	</form>
</template>
<script>
    import {required, minLength, maxLength} from "vuelidate/lib/validators";

    export default {
        name: 'FormAppModal',
		props: {
            app: { type: Object }
		},
        data() {
            return {
                submitted: false,
                listCategories: {},
                form: {
                    name: '',
                    categories: []
                }
            }
        },
        validations: {
            form: {
                name: {
                    required
                },
                categories: {
                    required,
                    minLength: minLength(1),
                    maxLength: maxLength(3)
                }
            }
        },
        mounted() {
            this.init();
        },
        methods: {
            init() {
                if(this.app && this.app.name && this.app.categories) {
                    this.form.name = this.app.name;
                    this.form.categories = this.app.categories;
				}
                this.listCategories = this.$t('development.categories');
            },
            submitForm() {
                this.$v.$touch();
                this.submitted = true;
                if (!this.$v.$invalid) {
                    if(confirm(this.$t('development.app.modal.save_message'))) {
						this.$emit('close', {...this.form});
                    }
                }
            }
        }
    }
</script>

<style lang="scss">
	.form_app_categories {
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;

		&__item {
			cursor: pointer;
			width: 33%;
		}
	}
</style>

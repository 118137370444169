<template>
	<div class="select-times">
		<div class="trigger pointer" @click="openSelection = !openSelection" :class="{ 'open': openSelection}">
			<span class="trigger--icon">
				<svg viewBox="0 0 40 40">
					<use xlink:href="#icons--calendar" width="100%" height="100%"></use>
				</svg>
			</span>
			<span class="trigger--label">
				{{ $t('common.from') }} {{ form.start | data }} {{ $t('common.to') }} {{ form.end | data }}
				<span class="arrow"></span>
			</span>
		</div>
		<form class="form" autocomplete="off" @submit.prevent="updateTimePeriod()" :class="{'open': openSelection}">
			<label class="filter pointer" for="oggi" :class="{'current': +currentRange == 0}">
				<span class="label">Oggi</span>
				<input id="oggi" value="0" @click="updateTime(0)" v-model="currentRange" type="radio"
					   name="select_range" class="range"/>
				<span class="checkbox-fake"></span>
			</label>
			<label class="filter pointer" for="last7days" :class="{'current': +currentRange == 7}">
				<span class="label">Ultimi 7 giorni</span>
				<input id="last7days" value="7" @click="updateTime(7)" v-model="currentRange" type="radio"
					   name="select_range" class="range"/>
				<span class="checkbox-fake"></span>
			</label>
			<label class="filter pointer" for="week" :class="{'current': currentRange == 'week'}">
				<span class="label">Questa settimana</span>
				<input id="week" value="week" @click="updateTime('week')" v-model="currentRange" type="radio"
					   name="select_range" class="range"/>
				<span class="checkbox-fake"></span>
			</label>
			<label class="filter pointer" for="last14days" :class="{'current': +currentRange == 14}">
				<span class="label">Ultimi 14 giorni</span>
				<input id="last14days" value="14" @click="updateTime(14)" v-model="currentRange" type="radio"
					   name="select_range" class="range"/>
				<span class="checkbox-fake"></span>
			</label>
			<label class="filter pointer" for="month" :class="{'current': currentRange == 'month'}">
				<span class="label">Questo mese</span>
				<input id="month" value="month" @click="updateTime('month')" v-model="currentRange" type="radio"
					   name="select_range" class="range"/>
				<span class="checkbox-fake"></span>
			</label>
			<label class="filter pointer" for="last30days" :class="{'current': +currentRange == 30}">
				<span class="label">Ultimi 30 giorni</span>
				<input id="last30days" value="30" @click="updateTime(30)" v-model="currentRange" type="radio"
					   name="select_range" class="range"/>
				<span class="checkbox-fake"></span>
			</label>
			<label class="filter pointer" for="year" :class="{'current': currentRange == 'year'}">
				<span class="label">Quest'anno</span>
				<input id="year" value="year" @click="updateTime('year')" v-model="currentRange" type="radio"
					   name="select_range" class="range"/>
				<span class="checkbox-fake"></span>
			</label>
			<label class="filter pointer" for="last90days" :class="{'current': +currentRange == 90}">
				<span class="label">Ultimi 90 giorni</span>
				<input id="last90days" value="90" @click="updateTime(90)" v-model="currentRange" type="radio"
					   name="select_range" class="range"/>
				<span class="checkbox-fake"></span>
			</label>
			<label class="filter pointer" for="custom" :class="{'current': currentRange == 'custom'}">
				<span class="label">Custom</span>
				<input id="custom" value="custom" @click="updateTime(-1)" v-model="currentRange" type="radio"
					   name="select_range" class="range"/>
				<span class="checkbox-fake"></span>
			</label>
			<vc-date-picker class="pick-date" :max-date="minDate" v-if="enable_custom_date" color="orange" mode="range"
							v-model="data"/>
		</form>
	</div>
</template>

<script>
import {required} from 'vuelidate/lib/validators';
import moment from 'moment';

export default {
	name: "SelectTimePeriod",
	data () {
		return {
			loading: false,
			enable_custom_date: false,
			currentRange: '',
			openSelection: false,
			data: {
				start: null,
				end: null
			},
			form: {
				start: null,
				end: null
			},
			minDate: moment().subtract(1, 'days').format()
		}
	},
	mounted () {
		this.init();
	},
	methods: {
		init () {
			this.form.start = +localStorage.getItem('selected_time_period_from', null);
			this.form.end = +localStorage.getItem('selected_time_period_to', null);
			this.currentRange = localStorage.getItem('selected_time_period_keys', null);
		},
		debug () {
			//let s = moment(+this.form.start).format("DD/MM/YYYY HH:mm:ss");
			//let e = moment(+this.form.end).format("DD/MM/YYYY HH:mm:ss");
			//let s = this.form.start;
			//let e = this.form.end;
			//console.log(`From ${s} to ${e}`);
		},
		updateTime (days) {
			this.enable_custom_date = false;
			this.form.start = null;
			this.form.end = null;
			this.data.start = null;
			this.data.end = null;

			if ((days === +days) && isFinite(days) && days < 0) {
				this.enable_custom_date = true;
				localStorage.setItem('selected_time_period_keys', 'custom');
			} else {
				if ((days === +days) && isFinite(days)) {
					this.form.start = moment(new Date()).subtract(days, 'd').startOf('day').valueOf();
					this.form.end = moment(new Date()).endOf("day").endOf('day').valueOf();
					this.debug();
				} else {
					switch (days) {
						case 'week':
							this.form.start = moment().startOf('week').valueOf();
							this.form.end = moment().endOf('week').valueOf();
							console.log(`From 0 ${this.form.start} to ${this.form.end}`)
							this.debug();
							break;
						case 'month':
							this.form.start = moment().startOf('month').valueOf();
							this.form.end = moment().endOf('month').valueOf();
							this.debug();
							break;
						case 'year':
							this.form.start = moment().startOf('year').valueOf();
							this.form.end = moment().endOf('year').valueOf();
							this.debug();
							break;
					}
				}
				setTimeout(() => {
					localStorage.setItem('selected_time_period_keys', days);
					console.log(`From 1 ${this.form.start} to ${this.form.end}`)
					this.updateTimePeriod();
				}, 10)
			}
		},
		updateTimePeriod () {
			// se come range di date l'ultimo giorno è il giorno di oggi, elimino
			// il giorno precedente
			console.log(`From 2 ${this.form.start} to ${this.form.end}`)

			if (this.form.start && this.form.start > 0 && this.form.end && this.form.end > 0) {
				let end = moment(this.form.end).valueOf();
				const spk = localStorage.getItem('selected_time_period_keys');
				if (moment().diff(this.form.end, 'days') == 0 && spk !== "0" && spk !== "custom") {
					end = moment(this.form.end).subtract(1, 'days').valueOf();
					this.form.end = end;
				}
				localStorage.setItem('selected_time_period_from', this.form.start);
				localStorage.setItem('selected_time_period_to', end.toString());
				// console.log(`From: ${moment(this.form.start).format()}, To: ${moment(end).format()} (${moment(this.form.end).format()}) - ${moment().diff(this.form.end, 'days')}`);
				this.$root.$emit('filters_updated');
				localStorage.setItem('selected_campaigns', JSON.stringify([]))
				this.$root.$emit('update_campaign_filter');
			}
			this.openSelection = false;
		}
	},
	validations: {
		form: {
			app: {
				required
			}
		}
	},
	watch: {
		'form.app': {
			handler () {
				// this.updateTimePeriod()
			},
			deep: true
		},
		'data': {
			handler () {
				if(this.data && this.data.start !== null && this.data && this.data.end !== null) {
					this.form.start = moment(this.data.start).startOf('day').valueOf();
					this.form.end = moment(this.data.end).endOf('day').valueOf();
					this.debug();
					this.updateTimePeriod();
				}
			},
			deep: true
		},
		'currentRange': {
			handler () {
				/*let cur = this.$el.querySelector(`#${this.currentRange}`);
				let allRadio = this.$el.querySelectorAll('.range');
				allRadio.forEach((el) => {
					el.parentNode.classList.remove('current');
				})
				cur.parentNode.classList.add('current');*/
			}
		}
	}
}
</script>

<style lang="scss">
@import '~@/assets/scss/components/_select-time';
</style>

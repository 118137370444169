<template>
    <form class="form" autocomplete="off" @submit.prevent="submitForm">
        <div v-if="error" class="error error-panel panel">
            {{ error }}
        </div>
        <div class="fieldset icon">
                <span class="icon">
                    <svg viewBox="0 0 93 70">
                        <use xlink:href="#icons--key" width="100%" height="100%"></use>
                    </svg>
                </span>
            <input type="password" name="password" :placeholder="$t('form.change_password.password')" class="input" autocomplete="off" v-model.trim="$v.form.password.$model"/>
        </div>
        <div class="error">
                <span v-if="submitted && !$v.form.password.required">
                    {{ $t('form.change_password.errors.password_required') }}
                </span> <span v-if="submitted && !$v.form.password.minLength">
                    {{ $t('form.change_password.errors.password_minLength') }}
                </span>
        </div>
        <div class="fieldset icon">
                <span class="icon">
                    <svg viewBox="0 0 93 70">
                        <use xlink:href="#icons--key" width="100%" height="100%"></use>
                    </svg>
                </span>
            <input type="password" name="repeat" :placeholder="$t('form.change_password.repeat')" class="input" autocomplete="off" v-model.trim="$v.form.repeat.$model"/>
        </div>
        <div class="error">
                <span v-if="submitted && !$v.form.repeat.required">
                    {{ $t('form.change_password.errors.repeat_required', {
                        'minLength': $v.form.password.$params.minLength.min
                    }) }}
                </span> <span v-if="submitted && !$v.form.repeat.sameAs">
                    {{ $t('form.change_password.errors.repeat_sameAs') }}
                </span>
        </div>
        <button type="submit" :disabled="loading" class="btn big full">
            <span v-if="loading">{{ $t('common.loading') }}</span> <span v-if="!loading">{{ $t('form.change_password.submit') }}</span>
        </button>
    </form>
    <!--<div v-if="complete">
        <h1>{{ $t('form.change_password.password_changed') }}</h1><br>
        <router-link :to="{ name: 'login.page' }">
            {{ $t('form.common.login') }}
        </router-link>
    </div>-->
</template>
<script>
    import {required, minLength, sameAs} from 'vuelidate/lib/validators'

    export default {
        name: 'ChangePasswordForm',
        data() {
            return {
                submitted: false,
                error: '',
                loading: false,
                form: {
                    password: '',
                    repeat: ''
                }
            };
        },
        validations: {
            form: {
                password: {
                    required,
                    minLength: minLength(8)
                },
                repeat: {
                    required,
                    sameAsPassword: sameAs('password')
                }
            }
        },
        methods: {
            submitForm() {
                this.$v.$touch();
                this.submitted = true;
                this.error = '';
                this.loading = true;
                if (!this.$v.$invalid) {
                    this.$emit('success', this.form);
                } else {
                    this.loading = false;
                }
            }
        }
    }
</script>
<style lang="scss"></style>

<template>
    <transition name="confirm.modal">
        <div class="modal-mask">
            <div class="modal-wrapper">
                <div class="modal-container">
                    <div class="modal-header" v-if="title">
                        <slot name="header">
                            <h3>{{title}}</h3>
                        </slot>
                    </div>
                    <div class="modal-body" v-if="description">
                        <slot name="body">
                            {{description}}
                        </slot>
                    </div>
                    <div class="modal-footer">
                        <slot name="footer">
                            <button class="btn big secondary" @click="$emit('close', true)">
                                <span>{{ $t('common.yes') }}</span>
                            </button>
                            <button class="btn big" @click="$emit('close', false)">
                                <span>{{ $t('common.no') }}</span>
                            </button>
                        </slot>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>
<script>
    export default {
        name: 'ConfirmModal',
        props: {
            title: {
                required: false
            },
            description: {
                required: false
            }
        }
    }
</script>

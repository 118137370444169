<template>
    <div class="request-reset-form panel form">
        <form-logo :subtitle="$t('form.reset_password.title')"></form-logo>
        <form v-if="!complete" class="form" autocomplete="off" @submit.prevent="submitForm">
            <div v-if="error" class="error error-panel panel">
                {{ error }}
            </div>
            <div class="fieldset icon">
                <span class="icon">
                    <svg viewBox="0 0 93 70">
                        <use xlink:href="#icons--mail" width="100%" height="100%"></use>
                    </svg>
                </span>
                <input type="text" name="email" :placeholder="$t('form.reset_password.email')" class="input" autocomplete="off" v-model.trim="$v.form.email.$model"/>
            </div>
            <div class="error">
                <span v-if="submitted && !$v.form.email.required">
                    {{ $t('form.reset_password.errors.email_required') }}
                </span> <span v-if="submitted && !$v.form.email.email">
                    {{ $t('form.reset_password.errors.email') }}
                </span>
            </div>
            <button type="submit" :disabled="loading" class="btn big full">
                <span v-if="loading">{{ $t('common.loading') }}</span> <span v-if="!loading">{{ $t('common.submit') }}</span>
            </button>
        </form>
        <div v-if="complete">
            <h1>{{ $t('form.reset_password.email_sended') }}</h1>
        </div>
    </div>
</template>
<script>
    import {required} from 'vuelidate/lib/validators'
    import {email} from 'vuelidate/lib/validators'
    import FormLogo from "../common/FormLogo";

    export default {
        name: 'RequestResetPasswordForm',
        components: {FormLogo},
        data() {
            return {
                submitted: false,
                error: '',
                loading: false,
                form: {
                    email: ''
                },
                complete: false
            };
        },
        validations: {
            form: {
                email: {
                    required,
                    email
                }
            }
        },
        methods: {
            submitForm() {
                this.$v.$touch();
                this.submitted = true;
                this.error = '';
                this.loading = true;
                if (!this.$v.$invalid) {
                    this.service.send_request_reset_password(this.form.email)
                        .then(() => {
                            this.complete = true;
                        })
                        .catch((err) => {
                            let errorMessage = `form.reset_password.errors.${err.message}`;
                            if (err.message == 'api-general-error') {
                                errorMessage = `errors.api-general-error`;
                            }
                            this.error = this.$t(errorMessage);
                            this.submitted = false;
                        })
                        .finally(() => {
                            this.loading = false;
                        })
                } else {
                    this.loading = false;
                }
            }
        }
    }
</script>
<style lang="scss"></style>

<script>
import { Bar } from "vue-chartjs";
import moment from "moment";

export default {
	name: "NotificationsHistogramChart",
	extends: Bar,
	props: {
		data: {
			required: true,
		},
		coverage: {
			required: true,
		},
	},
	data() {
		return {};
	},
	mounted() {
		this.updateData();
	},
	methods: {
		updateData() {
			let d = [];
			let labels = [];
			if (this.data && Array.isArray(this.data)) {
				this.data.map((dm) => {
					d.push(dm.count);

					let from = moment(+localStorage.getItem("selected_time_period_from"));
					let to = moment(+localStorage.getItem("selected_time_period_to"));
					let duration = moment.duration(to.diff(from)).asHours();

					if (duration > 48) {
						labels.push(moment(dm.date).format("DD/MM/YYYY"));
					} else {
						labels.push(moment(dm.date).format("DD/MM - HH:00"));
					}
				});
			}

			// prendo il massimo valore della
			// linea
			/*let max = 0;
                d.map((da) => {
                    max = (da > max) ? da : max
                });
                // creo il dataset delle campagne
                let t = [];
                let numeroCampagne = 0;
                let maxCampagne = 0;
                this.data.map((dm) => {
                    numeroCampagne = 0;
                    maxCampagne = 0;
                    this.coverage.map(c => {
                        numeroCampagne += (c.start < dm.date < c.end) ? 1 : 0;
                    });
                    maxCampagne = (numeroCampagne > maxCampagne) ? numeroCampagne : maxCampagne;
                    t.push(numeroCampagne);
                });
                for(let i=0; i<t.length; i++){
                    if(t[i] !== 0) {
                        let fi = t[i] / maxCampagne;
                        let newValue = max * fi;
                        t[i] = newValue;
                    }
                }*/

			this.renderChart(
				{
					type: "bar",
					datasets: [
						{
							data: d,
							backgroundColor: "#ed7d3155",
							fill: true,
							fontColor: "#ed7d31",
							borderColor: "#ed7d31",
						} /*, {
						data: t,
						backgroundColor: '#0098a144',
						fill: true,
						borderColor: '#0098a1',
						fontColor: '#0098a1',
					}*/,
					],
					labels: labels,
				},
				{
					legend: false,
					scales: {
						yAxes: [
							{
								ticks: {
									beginAtZero: true,
								},
							},
						],
					},
				}
			);
		},
	},
	watch: {
		data: {
			deep: true,
			handler() {
				this.updateData();
			},
		},
	},
};
</script>
<style>
</style>

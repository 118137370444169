<template>
    <div id="public" class="public-layout">
        <div class="content-left">
            <router-view />
        </div>
        <div class="image-right">
            <img alt="" src="@images/login.jpg" />
        </div>
    </div>
</template>

<script>
    export default {
        name: 'PublicLayout',
    }
</script>

<style lang="scss">
@import '~@/assets/scss/layouts/_public-layout';
</style>

<template>
	<form class="form" autocomplete="off" @submit.prevent="submitForm" v-if="!loading">
		<div v-if="error" class="error error-panel panel">
			{{ $t(error) }}
		</div>

		<div class="fieldset no-border" v-if="!loading">
			<p class="full">
				{{$t('form.notification.news')}}
			</p>
			<label class="switch">
				<input type="checkbox" v-model="notifications.news">
				<span class="slider round"></span>
			</label>

		</div>
		<div class="fieldset no-border" v-if="!loading">
			<p class="full">
				{{$t('form.notification.maintenance')}}
			</p>
			<label class="switch">
				<input type="checkbox" v-model="notifications.maintenance">
				<span class="slider round"></span>
			</label>
		</div>
		<div class="fieldset no-border" v-if="!loading">
			<p class="full">
				{{$t('form.notification.problems')}}
			</p>
			<label class="switch">
				<input type="checkbox" v-model="notifications.problems">
				<span class="slider round"></span>
			</label>
		</div>

		<div class="" v-if="!loading">
			<p class="full">
				<br><br>
				{{$t('form.notification.add_email_title')}}
			</p>
		</div>
		<div class="fieldset icon" v-if="!loading && other_recipients">
			<vue-tags-input
					v-model="other_recipient"
					:tags="other_recipients"
					:validation="validation"
					:placeholder="$t('form.notification.add_email')"
					@tags-changed="other_recipients => updateTags(other_recipients)"
			/>
		</div>
	</form>
</template>
<script>
    import VueTagsInput from '@johmun/vue-tags-input';

    export default {
        name: 'NotificationForm',
        components: {VueTagsInput},
        data() {
            return {
                submitted: false,
                error: '',
                user: {},
                loading: false,
                notifications: {
                    news: false,
                    maintenance: false,
                    problems: false
                },
                other_recipients: [],
                other_recipient: '',
                validation: [
                    {
                        classes: 'red',
                        rule: /^[^\s@]+@[^\s@]+\.[^\s@]+$/
                    }
                ]
            };
        },
        mounted() {
            this.init()
        },
        methods: {
            refresh_data(data) {
                Object.keys(this.form).forEach((k) => {
                    this.form[k] = data[k];
                });
            },
            init() {
                // get old data
                this.loading = true;
                this.service.profile()
                    .then((data) => {
                        this.user = data;
                        this.other_recipients = [];
                        if (this.user && this.user.other_recipients && this.user.other_recipients.length > 0) {
                            let or = this.user.other_recipients.split(",");
                            if(or && or.length) {
                                or.map(o => {
                                    this.other_recipients.push({ text: o })
                                })
							}
                        }
                        if (this.user && this.user.email_preferences && this.user.email_preferences.length > 0) {
                            try {
                                let ep = JSON.parse(this.user.email_preferences);
                                Object.keys(ep).map(key => {
                                    this.notifications[key] = ep[key];
                                });
                            } catch (e) {
                                console.log(e);
                            }
                        }
                    })
                    .catch((err) => {
                        this.error = this.$t(`form.notification.errors.${err.message}`);
                    })
                    .finally(() => {
                        this.loading = false;
                    })

            },
            updateTags(other_recipients, c) {
                c = c || false;
                this.error = '';
                let orecs = [];
                // da  sistemare
                if (!c) {
                    other_recipients.map(orec => {
                        if (orec.tiClasses.includes('ti-invalid')) {
                            this.error = 'form.notification.errors.email';
                        }
                        orecs.push(orec.text);
                    });
                } else {
                    this.other_recipients.map(o => {
                        orecs.push(o.text);
					})
				}
                if (this.error == '') {
                    this.user.other_recipients = orecs.join(",");
                    this.user.email_preferences = JSON.stringify(this.notifications);
                    this.service.save_profile(this.user)
                        .then(() => {
                            this.other_recipients = [];
                            orecs.map(o => { this.other_recipients.push({ text: o }) });
                        })
                        .catch((err) => {
                            this.error = this.$t(`form.notification.errors.${err.message}`);
                        })
                        .finally(() => {
                            this.loading = false
							this.$root.$emit('save-profile')
                        })
                }

            }
        },
        watch: {
            'notifications': {
                deep: true,
                handler() {
                    this.updateTags(null, true);
                }
            }
        }
    }
</script>
<style lang="scss">
	.vue-tags-input {
		width: 100%;
		max-width: none !important;

		.ti-valid {
			background-color: $orange !important;
		}

		.ti-input {
			border: none;
		}
	}

</style>

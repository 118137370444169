<template>
  <transition name="campaign.modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container modal-container-big">
          <div class="modal-header">
            <slot name="header">
              <h3 v-if="campaign.name">
                <!--{{ $t('campaigns.table.accept') }}-->
                {{ campaign.name }}
              </h3>
            </slot>
          </div>
          <div class="modal-body">
            <slot name="body">
              <div v-if="!loading" class="first-row row">
                <div class="col half">
                  <div class="row">
                    <div class="campaign--list" v-if="campaign.optins">
                      <div class="campaign--list--head">
                        <span class="campaign--list--col name"></span>
                        <!--                        <span class="campaign&#45;&#45;list&#45;&#45;col cps">
                                                  <span class="cps&#45;&#45;label">
                                                    <svg viewBox="0 0 65 80">
                                                      <path
                                                          d="M35.863 6.961C41.102.33 48.385 0 48.385 0s1.084 6.513-4.121 12.788c-5.557 6.699-11.873 5.603-11.873 5.603s-1.186-5.269 3.472-11.43ZM33.57 22.954c2.182 0 7.184-3.717 13.695-3.717 11.207 0 15.616 8.001 15.616 8.001s-8.623 4.423-8.623 15.157C54.258 54.503 65 58.676 65 58.676s-7.509 21.206-17.652 21.206c-4.658 0-7.668-3.15-13.188-3.15-5.002 0-9.966 3.267-13.198 3.267C11.7 79.999 0 59.884 0 43.714 0 27.805 9.903 19.46 19.193 19.46c6.038 0 10.725 3.494 14.377 3.494Z"
                                                      />
                                                    </svg>
                                                  </span>
                                                  <small class="cps&#45;&#45;info">({{ campaign.optins[0].type }})</small>
                                                </span>-->
                        <!--                        <span class="campaign&#45;&#45;list&#45;&#45;col cps">
                                                  <span class="cps&#45;&#45;label">
                                                    <svg viewBox="0 0 67 81">
                                                      <path
                                                          d="M9.81 51.313c0 3.211-2.208 5.84-4.904 5.84-2.697 0-4.906-2.626-4.906-5.84V32.197c0-3.211 2.207-5.84 4.906-5.84s4.904 2.629 4.904 5.84v19.116Zm57.19 0c0 3.211-2.208 5.84-4.07 5.84-3.536 0-5.743-2.626-5.743-5.84V32.197c0-3.211 2.207-5.84 5.743-5.84 1.862 0 4.07 2.629 4.07 5.84v19.116ZM44.224 7.881l3.147-5.726c.258-.467.181-1.013-.167-1.209-.348-.196-.847.024-1.102.491l-3.22 5.859c-3.024-1.192-6.243-1.798-9.488-1.786-3.426 0-6.666.653-9.555 1.815L20.604 1.44c-.258-.468-.755-.69-1.103-.491-.347.198.259.741-.167 1.209l3.163 5.754c-6.581 3.548-11.013 9.016-11.013 15.758 0 .684.049 1.63.136 2.026h43.55c.09-.396.137-1.34.137-2.026 0-6.763-4.461-11.77-11.078-15.786l-.005-.003Zm-20.777 9.995c-1.058 0-1.916-.176-1.917-1.942 0-1.073.858-1.943 1.916-1.943h.001c1.058 0 1.916.87 1.916 1.943-.001 1.666-.858 1.942-1.916 1.942Zm19.997 0c-1.059 0-1.917-.176-1.917-1.942-.001-1.073.857-1.943 1.915-1.943h.002c1.058 0 1.916.87 1.915 1.943 0 1.666-.858 1.942-1.915 1.942Zm-31.965 9.749v32.562h.018c.224 2.75 2.719 4.935 5.743 4.935h32.3c3.021 0 6.03-2.185 5.743-4.935h.018V27.625H11.479ZM30.254 64.96v10.38c0 2.905-1.784 5.534-4.906 5.534-2.699 0-4.904-2.629-4.904-5.534V64.968l9.81-.008Zm16.35-.005V75.34c0 2.905-2.207 5.534-4.907 5.534-2.699 0-4.906-2.629-4.906-5.534V64.955h9.813Z"
                                                      />
                                                    </svg>
                                                  </span>
                                                  <small class="cps&#45;&#45;info"
                                                  >({{ campaign.optins[0].type }})</small
                                                  >
                                                </span>-->
                        <span class="campaign--list--col action double">
                          {{ $t("common.status") }}
                        </span>
                      </div>
                      <div class="campaign--list--body">
                        <div v-for="o in campaign.optins" :key="o.application_id" class="campaign--list--row">
                          <span class="campaign--list--col name">
                            {{ o.application_id | application_name(apps) }}
                          </span>
                          <!--                          <span class="campaign&#45;&#45;list&#45;&#45;col cps">
                                                      {{ o.value_ios }} &euro;
                                                    </span>
                                                    <span class="campaign&#45;&#45;list&#45;&#45;col cps">
                                                      {{ o.value_android }} &euro;
                                                    </span>-->
                          <template v-if="
                            !o.status && disableButtons == false
                          ">
                            <span class="campaign--list--col action">
                              <button type="button" class="campaign--btn accept" @click="accept(o)">
                                <span class="campaign--btn--icon"></span>
                              </button>
                            </span>
                            <span class="campaign--list--col action">
                              <button type="button" class="campaign--btn reject" @click="reject(o)">
                                <span class="campaign--btn--icon"></span>
                              </button>
                            </span>
                          </template>
                          <template v-if="o.status">
                            <!--  && disableButtons == false TODO Perché c'era questa condizione nel v-if soprastante?-->
                            <template v-if="
                              o.status ==
                              service.campaigns.status.ACCEPTED
                            ">
                              <span class="campaign--list--col action double">
                                <span class="accepted-label">{{
                                  $t("common.accepted")
                                  }}</span>
                              </span>
                            </template>
                            <template v-if="
                              o.status ==
                              service.campaigns.status.REJECTED
                            ">
                              <span class="campaign--list--col action">
                                <button type="button" class="campaign--btn accept" @click="accept(o)">
                                  <span class="campaign--btn--icon"></span>
                                </button>
                              </span>
                              <span class="campaign--list--col action">
                                <span class="rejected-label">{{
                                  $t("common.rejected")
                                  }}</span>
                              </span>
                            </template>

                            <!-- <div class="col full color_red">
                                {{ $t('common.rejected') }}
                                <div class="col half">
                                  <button type="button" @click="accept(o)"
                                      class="btn btn_green btn_small">
                                    {{ $t('common.accept') }}
                                  </button>
                                </div>
                              </div> -->
                            <!-- </span> -->
                          </template>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col half">
                  <div class="campaign--phones">
                    <div class="phone-box">
                      <div class="phone">
                        <img alt="" src="@images/phone/base_phone.png" />
                        <div class="phone__safari">
                          <img alt="" src="@images/phone/phone_lock.png" />
                        </div>
                        <div class="phone__lock_iframe">
                          <div class="phone__lock_iframe__title">
                            {{ campaign.brand }}
                          </div>
                          <div class="phone__lock_iframe__content">
                            {{ campaign.message }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="phone-box">
                      <div class="phone">
                        <img alt="" src="@images/phone/base_phone.png" />
                        <div class="phone__safari">
                          <img alt="" src="@images/phone/phone_safari.png" />
                        </div>
                        <div class="phone__safari_iframe">
                          <iframe :src="campaign.content" frameborder="0" title=""></iframe>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col full infocol noflex">
                  <!-- Stategia -->
                  <span class="campaign--info">
                    <small class="campaign--info--title">
                      {{ $t("campaigns.modal.strategy") }}
                    </small>
                    <span class="campaign--info--label">
                      {{ $t(`campaigns.strategies[${campaign.strategy}]`) }}
                    </span>
                  </span>

                  <!-- Nummero notifiche -->
                  <!-- span class="campaign--info">
                    <small class="campaign--info--title">
                      {{ $t("campaigns.modal.notifications") }}
                    </small>
                    <span class="campaign--info--label">
                      {{ campaign.notifications }}
                    </span>
                  </span -->

                  <!-- frequenza invio -->
                  <!-- span class="campaign--info">
                    <small class="campaign--info--title">
                      {{ $t("campaigns.modal.frequency") }}
                    </small>
                    <span class="campaign--info--label">
                      {{
                        $tc("common.time.title", campaign.frequency_unit, {
                          count: campaign.frequency_unit,
                        })
                      }}
                      <span v-if="campaign.frequency_type">
                        {{
                          $t(
                            `common.time.type.${getFrequnecyType(
                              campaign.frequency_type
                            )}`
                          )
                        }}
                      </span>
                    </span>
                  </span -->

                  <!-- Note -->
                  <!--                  <span class="campaign&#45;&#45;info note">
                                      <small class="campaign&#45;&#45;info&#45;&#45;title">
                                        {{ $t("campaigns.modal.note") }}
                                      </small>
                                      <span class="campaign&#45;&#45;info&#45;&#45;label"> </span>
                                    </span>-->
                </div>
              </div>
              <div v-if="loading" class="first-row row">
                {{ $t("common.loading") }}
              </div>
            </slot>
          </div>

          <div class="modal-footer">
            <slot name="footer">
              <button v-if="!loading_save" type="button" class="btn big" @click="saveAndClose()">
                <span>{{ $t("common.close") }}</span>
              </button>
              <span v-if="loading_save">{{ $t("common.loading") }}</span>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
export default {
  name: "CampaignConfirmModal",
  props: {
    campaign: {
      required: true,
    },
    disableButtons: {
      required: false,
      default: false,
    },
  },
  data() {
    return {
      apps: [],
      loading: true,
      loading_save: false,
    };
  },
  mounted() {
    this.service.applications
      .list()
      .then((apps) => {
        this.apps = apps;
      })
      .finally(() => {
        this.loading = false;
      });
  },
  methods: {
    getFrequnecyType(f) {
      return f ? f.trim().toLowerCase() : null;
    },
    acceptAll() {
      this.campaign.optins.map((o) => {
        this.accept(o);
      });
    },
    accept(app) {
      app.status = this.service.campaigns.status.ACCEPTED;
      this.$forceUpdate();
    },
    reject(app) {
      app.status = this.service.campaigns.status.REJECTED;
      this.$forceUpdate();
    },
    changeStatus(apps, status) {
      return new Promise((resolve) => {
        if (apps && apps.length > 0) {
          this.service.campaigns
            .update(apps, this.campaign.id, status)
            .then(() => {
              resolve();
            });
        } else {
          resolve();
        }
      });
    },
    async saveAndClose() {
      let acceptedApps = [];
      let rejectedApps = [];

      this.campaign.optins.map((optin) => {
        if (optin.status === this.service.campaigns.status.ACCEPTED) {
          acceptedApps.push(optin.application_id);
        } else if (optin.status === this.service.campaigns.status.REJECTED) {
          rejectedApps.push(optin.application_id);
        }
      });

      // salvo le app accettate
      this.loading_save = true;

      if (acceptedApps && acceptedApps.length > 0) {
        await this.changeStatus(
          acceptedApps,
          this.service.campaigns.status.ACCEPTED,
        );
      }

      if (rejectedApps && rejectedApps.length > 0) {
        await this.changeStatus(
          rejectedApps,
          this.service.campaigns.status.REJECTED,
        );
      }

      this.loading_save = false;
      this.$emit("close", acceptedApps, this.apps);
    },
  },
};
</script>
<style lang="scss">
.revenue_confirm {
  flex-direction: column;
  justify-content: flex-start;
  flex-wrap: initial;
  height: 400px;
  overflow: scroll;
}

.noflex {
  &.col {
    display: block;
  }
}

.infocol {
  display: flex !important;
  @extend %flexStartStart;
  flex-wrap: wrap;
  margin-top: 50px;
  // padding-left: 20px;
  // border-left: 1px solid $grey;
}

.phone {
  position: relative;
  display: block;
  width: 100%;
  height: 0;
  padding-bottom: 205.5%;
  margin: 0 auto;

  img {
    display: block;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
  }

  &__safari {
    position: absolute;
    top: 0;
    left: 0;
    @include size(100%, 100%);
    z-index: 1;

    img {
      display: block;
      width: 100%;
    }
  }

  &__safari_iframe {
    position: absolute;
    top: 13%;
    left: 4%;
    @include size(92%, 76%);
    z-index: 2;

    iframe {
      display: block;
      @include size(100%, 100%);
    }
  }

  &__lock_iframe {
    position: absolute;
    top: 33%;
    left: 7%;
    @include size(86%, auto);
    z-index: 2;

    background: #bfb7b3;
    opacity: 0.9;
    border-radius: 10px;

    padding: 2%;

    &__title {
      font-weight: 700;
      color: $black;
      font-size: 12px;
    }

    &__content {
      margin-top: 10px;
      color: $black;
      font-size: 12px;
      line-height: 14px;
    }
  }
}

.campaign {
  &--phones {
    width: 100%;
  }

  &--info {
    display: block;
    width: auto;
    margin-bottom: 30px;
    margin-right: 30px;

    &:not(:first-of-type) {
      padding-left: 20px;
      border-left: 1px solid $grey;
    }

    &--title,
    &--label {
      display: block;
      width: 100%;
    }

    &--title {
      text-transform: uppercase;
      font-size: 0.8rem;
    }

    &--label {
      font-weight: 700;
      font-size: 1.3rem;
    }

    &.note {
      .campaign--label {
        font-size: 0.9rem;
        font-weight: 400;
      }
    }
  }

  &--list {
    display: block;
    width: 100%;
    padding-right: 20px;

    &--head,
    &--row {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      width: 100%;
      height: 60px;
    }

    &--row {
      padding: 0 10px;

      &:nth-child(odd) {
        background: $isabelline;
      }
    }

    &--col {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: calc(58% - 2px);

      .accepted-label {
        color: $green;
      }

      .rejected-label {
        color: $red;
      }

      &.cps {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        width: calc(15% - 2px);
        height: 100%;
        text-align: center;

        .cps {
          display: flex;
          align-items: center;
          width: 100%;
          height: 100%;

          &--label {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: 25px;
            height: 25px;
            margin: 0 auto;

            svg {
              display: block;
              width: 100%;
              height: 100%;
              fill: $grey;
            }
          }

          &--info {
            display: block;
            width: 100%;
            text-align: center;
          }
        }
      }

      &.action {
        width: calc(10% - 2px);
        font-size: 0.6rem;
        text-transform: uppercase;
        text-align: center;
        justify-content: center;

        &.double {
          width: calc(20% - 2px);
        }

        .campaign--btn {
          display: block;
          width: 30px;
          height: 30px;
          border-radius: 100%;
          transition: 240ms linear;
          margin: 0 auto;
          cursor: pointer;
          border: 0;
          padding: 0;

          &--icon {
            display: block;
            width: 100%;
            height: 100%;
            position: relative;
            pointer-events: none;

            &:before,
            &:after {
              content: "";
              position: absolute;
              top: 50%;
              left: 50%;
              background: $white;
            }

            &:before {
              transform: translate(-50%, -50%) rotate(-45deg);
            }

            &:after {
              transform: translate(-50%, -50%) rotate(45deg);
            }
          }

          &:hover {
            transform: scale(1.1);
          }

          &.reject {
            background: $red;

            .campaign--btn--icon {

              &:before,
              &:after {
                width: 50%;
                height: 2px;
              }
            }
          }

          &.accept {
            background: $green;

            .campaign--btn--icon {
              &:before {
                width: 40%;
                height: 2px;
                margin-left: 3px;
              }

              &:after {
                width: 20%;
                height: 2px;
                margin-left: -3px;
                margin-top: 1px;
              }
            }
          }
        }
      }
    }
  }
}
</style>

<template>
	<form class="form" autocomplete="off" @submit.prevent="submitForm">
		<div class="modal-mask">
			<div class="modal-wrapper">
				<div class="modal-container modal-container-middle">
					<div class="modal-header">
						<slot name="header">
							<h3>{{$t('development.app.modal_platform.title')}}</h3>
						</slot>
					</div>

					<div class="modal-body" v-if="step == 1">
						<h5>{{$t('development.app.modal_platform.form.platform')}}</h5>
						<div class="radios pointer">
							<label class="radios__label">
								<input type="radio" value="ios" name="platform" class="input radios__input"
									   v-model.trim="$v.form.platform.$model"/>
								<span>iOS</span>
							</label>
							<label class="radios__label">
								<input type="radio" value="android" name="platform" class="input radios__input"
									   v-model.trim="$v.form.platform.$model"/>
								<span>Android</span>
							</label>
						</div>
						<div class="error">
							<span v-if="submitted && !$v.form.platform.required">
								{{ $t('development.app.modal_platform.form.errors.platform_required') }}
							</span>
						</div>

						<h5>{{$t('development.app.modal_platform.form.identifier')}}</h5>
						<div class="fieldset icon">
							<input type="text" name="name"
								   :placeholder="$t('development.app.modal_platform.form.identifier')"
								   class="input" autocomplete="off" v-model.trim="$v.form.identifier.$model"/>
						</div>
						<div class="error">
								<span v-if="submitted && !$v.form.identifier.required">
									{{ $t('development.app.modal_platform.form.errors.identifier_required') }}
								</span>
						</div>
					</div>

					<div class="modal-body" v-if="step == 2">
						<h5>{{$t('development.app.modal_platform.title_sdk')}}</h5>
						<p>
							{{$t('development.app.modal_platform.description_sdk')}}
						</p>
						<div class="radios">
							<a v-if="form.platform == 'android'" class="btn big"
							   href="https://github.com/jointag/JTProximitySDK-Android" target="_blank">
								{{$t('development.app.modal_platform.documentation')}}
							</a>
							<a v-if="form.platform == 'ios'" class="btn big"
							   href="https://github.com/jointag/JTProximitySDK-iOS" target="_blank">
								{{$t('development.app.modal_platform.documentation')}}
							</a>
						</div>
					</div>

					<div class="modal-body" v-if="step == 3">
						<h5>{{$t('development.app.modal_platform.title_test')}}</h5>
						<p>
							{{$t('development.app.modal_platform.description_test')}}
						</p>
						<hr>
						<TestApp :start="startTest" :app-id="appId" :platform="this.form"></TestApp>
					</div>

					<div class="modal-footer">
						<slot name="footer">
							<hr>
							<button type="button" class="btn big" @click="$emit('close', null)">
								<span>{{ $t('common.close') }}</span>
							</button>
							<button type="submit" v-if="step < 3" class="btn big">
								<span>{{ $t('common.next') }}</span>
							</button>
							<!--<button type="submit" v-if="step == 3" class="btn big">
								<span>{{ $t('common.save') }}</span>
							</button>-->
						</slot>
					</div>

				</div>
			</div>
		</div>
	</form>
</template>
<script>
    import {required} from "vuelidate/lib/validators";
    import TestApp from "./TestApp";

    export default {
        name: 'FormAppPlatformModal',
        components: {TestApp},
		props: {
            appId: {
                type: String,
				required: true
			}
		},
        data() {
            return {
                submitted: false,
                step: 1,
				startTest: false,
                form: {
                    platform: '',
                    identifier: ''
                }
            }
        },
        validations: {
            form: {
                platform: {
                    required
                },
                identifier: {
                    required
                }
            }
        },
        mounted() {
            this.init();
        },
        methods: {
            init() {
            },
            submitForm() {
                switch (this.step) {
                    case 1:
                        this.$v.$touch();
                        this.submitted = true;
                        if (!this.$v.$invalid) {
                            if (confirm(this.$t('development.app.modal.save_message'))) {
                                this.service.applications.platforms
									.add(this.appId, this.form.platform, this.form.identifier)
									.then((result) => {
                                        console.log(result);
                                        this.step = 2;
									})
									.catch(() => {
									    //TODO: Gestire l'errore
									})
                            }
                        }
                        break;
                    case 2:
                        this.step = 3;
                        this.startTest = true
                        break;
                    case 3:
                        this.$emit('close');
                }
            }
        }
    }
</script>

<style lang="scss">
	.radios {
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		align-items: center;
		justify-content: center;
		margin: 20px 0;

		&__label {
			width: 30%;

			span {
			}
		}

		&__input {
			width: 40px;
		}
	}
</style>
